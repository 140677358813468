import React from "react";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, resetRegisterFlag } from "slices/thunk";
import { createSelector } from 'reselect';
import { Facebook, Github, Mail, Twitter } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik"; // useFormic değil, useFormik olmalı

// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { RootState } from "slices";

const Register = () => {
    document.title = "Register | Macfit Cafe";

    const dispatch = useDispatch<any>();
    const navigate = useNavigate(); // useNavigate hook

    const selectRegister = createSelector(
        (state: RootState) => state.Register,
        (register) => ({
            success: register.success
        })
    )

    const { success } = useSelector(selectRegister);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "admin@themesbrand.com",
            username: "admin",
            password: "123456",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Geçerli bir e-posta adresi girin").required("Lütfen e-posta adresinizi girin"),
            username: Yup.string().required("Lütfen kullanıcı adınızı girin"),
            password: Yup.string().required("Lütfen şifrenizi girin"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    React.useEffect(() => {
        if (success) {
            navigate('/login');
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);
    }, [dispatch, success, navigate]);

    React.useEffect(() => {
        const bodyElement = document.body;
        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        
        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    return (
        <React.Fragment>
            <div className="relative">
                <AuthIcon />

                <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src={logoLight} alt="" className="hidden h-6 mx-auto dark:block" />
                            <img src={logoDark} alt="" className="block h-6 mx-auto dark:hidden" />
                        </Link>

                        <div className="mt-8 text-center">
                            <h4 className="mb-1 text-custom-500 dark:text-custom-500">Create your free account</h4>
                            <p className="text-slate-500 dark:text-zink-200">Get your free Tailwick account now</p>
                        </div>

                        <form className="mt-10" id="registerForm" onSubmit={validation.handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="email-field" className="inline-block mb-2 text-base font-medium">Email</label>
                                <input
                                    type="text"
                                    id="email-field"
                                    name="email"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <div className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username-field" className="inline-block mb-2 text-base font-medium">Username</label>
                                <input
                                    type="text"
                                    id="username-field"
                                    name="username"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter username"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                />
                                {validation.touched.username && validation.errors.username ? (
                                    <div className="mt-1 text-sm text-red-500">{validation.errors.username}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="inline-block mb-2 text-base font-medium">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                />
                                {validation.touched.password && validation.errors.password ? (
                                    <div className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                ) : null}
                            </div>
                            <p className="italic text-15 text-slate-500 dark:text-zink-200">
                                By registering you agree to the Tailwick <a href="#!" className="underline">Terms of Use</a>
                            </p>
                            <div className="mt-10">
                                <button type="submit" className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500">
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Register;
