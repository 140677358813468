import { useMutation } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const api = new APIClient();

const MakeProductCountDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [products, setProducts] = React.useState<any>({});

    const getProductDetailsMutation = useMutation({
        mutationFn: () => {
            return api.get(`/product-count/detail/${id}`, null);
        },
        onSuccess: (data) => {
            setProducts(data);
        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    useEffect(() => {
        getProductDetailsMutation.mutate();
    }, []);

    return (
        <>
            <div className="flex justify-between items-center my-4">
                <button
                    onClick={() => {
                        navigate('/product-count');
                    }}
                    className="text-blue-500">&#8592; Geri</button>
            </div>
            <div className="container mx-auto p-4">
                <div className="flex flex-col space-y-4 bg-white p-6 rounded shadow-lg">
                    <div className='flex items-center justify-center'>
                        {
                            products?.countedProducts?.length > 0 ? (
                                <table className="min-w-full bg-white border mt-4">
                                    <thead>
                                        <tr>
                                            <th className="border px-4 py-2">Ürün Adı</th>
                                            <th className="border px-4 py-2">Stok</th>
                                            <th className="border px-4 py-2">Sayım Adedi</th>
                                            <th className="border px-4 py-2">Birim Alış</th>
                                            <th className="border px-4 py-2">Birim Satış</th>
                                            <th className="border px-4 py-2">Birim Alış Zarar</th>
                                            <th className="border px-4 py-2">Birim Satış Zarar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products.countedProducts.map((item: any, index: number) => (
                                                <tr key={index} className="hover:bg-gray-100">
                                                    <td className="border px-4 py-2">{item.productTitle}</td>
                                                    <td className="border px-4 py-2">{item.existingStock}</td>
                                                    <td className="border px-4 py-2">{item.countedStock}</td>
                                                    <td className="border px-4 py-2">{item.buyingPrice} TL</td>
                                                    <td className="border px-4 py-2">{item.sellingPrice} TL</td>
                                                    <td className="border px-4 py-2">{item.buyingLoss} TL</td>
                                                    <td className="border px-4 py-2">{item.sellingLoss} TL</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-lg font-semibold">Henüz ürün eklenmedi.</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default MakeProductCountDetail;
