import { useMutation, useQueryClient } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React, { useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import UserChips from './UserChips';  // Import the UserChips component

type Props = {};

const api = new APIClient();

const GroupNotificationsList = (props: Props) => {
    const [notifications, setNotifications] = useState<any>([]);
    const [filteredNotifications, setFilteredNotifications] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeNotification, setActiveNotification] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const getNotificationsMutation = useMutation({
        mutationFn: () => api.get('/group-notifications/list', null),
        onSuccess: (data: any) => {
            setNotifications(data);
            setFilteredNotifications(data);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const updateGroupMutation = useMutation({
        mutationFn: (updatedGroup: any) => api.put('/group-notifications/update', updatedGroup),
        onSuccess: () => {
            toast('Grup güncellendi', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const deleteGroupMutation = useMutation({
        mutationFn: (groupId: string) => api.delete(`/group-notifications/delete/${groupId}`, { groupId }),
        onSuccess: (data: any) => {
            setNotifications(notifications.filter((notification: any) => notification._id !== data._id));
            toast('Grup silindi', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        },
        onError: (error) => {
            console.log(error);
        }
    });

    React.useEffect(() => {
        getNotificationsMutation.mutate();
    }, []);

    React.useEffect(() => {
        const results = notifications.filter((notification: any) =>
            notification.groupName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredNotifications(results);
    }, [searchTerm, notifications]);

    if (getNotificationsMutation.isPending) {
        return <div>Loading...</div>;
    }

    const handleSendNotification = (notification: any) => {
        toast('Bildirim gönderildi', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        // Reset title and message fields
        setTitle('');
        setMessage('');
        setActiveNotification(null);
    };

    const handleUpdateGroup = (groupId: string, updatedUsers: any) => {
        updateGroupMutation.mutate({
            groupId,
            users: updatedUsers.map((user: any) => user._id),
        });
    };

    const handleDeleteUserFromGroup = (groupId: string, userId: string) => {
        const updatedNotifications = notifications.map((notification: any) => {
            if (notification._id === groupId) {
                return {
                    ...notification,
                    users: notification.users.filter((user: any) => user._id !== userId)
                };
            }
            return notification;
        });

        setNotifications(updatedNotifications);
        handleUpdateGroup(groupId, updatedNotifications.find((notification: any) => notification._id === groupId).users);
    };

    const handleDeleteGroup = (groupId: string) => {
        deleteGroupMutation.mutate(groupId);

    };

    return (
        <div className="p-5 max-w-3xl mx-auto bg-gray-100 rounded-lg">
            <h1 className="text-center mb-5 text-xl font-semibold text-gray-800">Grup Bildirim Listesi</h1>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Arama"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
                />
                {filteredNotifications.map((notification: any, index: number) => (
                    <div key={index} className="p-4 bg-white rounded-lg shadow-md mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <div>
                                <h2 className="text-lg font-semibold text-gray-800">{notification.groupName}</h2>
                                <p className="text-sm text-gray-600">{new Date(notification.createdAt).toLocaleString()}</p>
                            </div>
                            <div>
                                <button
                                    onClick={() => setActiveNotification(activeNotification === notification._id ? null : notification._id)}
                                    className="bg-blue-500 text-white px-3 py-1 rounded-lg"
                                >
                                    Bildirim Gönder
                                </button>
                                <button
                                    onClick={() => handleDeleteGroup(notification._id)}
                                    className="bg-red-500 text-white ml-5 px-3 py-1 rounded-lg mt-2"
                                >
                                    Sil
                                </button>
                            </div>
                        </div>
                        <UserChips
                            users={notification.users}
                            onDelete={(userId: string) => handleDeleteUserFromGroup(notification._id, userId)}
                        />
                        {activeNotification === notification._id && (
                            <div className="mt-2">
                                <h3 className="text-md font-semibold text-gray-800">Başlık:</h3>
                                <input
                                    type="text"
                                    placeholder="Başlık"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-lg mt-1"
                                />
                                <h3 className="text-md font-semibold text-gray-800 mt-2">Mesaj:</h3>
                                <textarea
                                    placeholder="Mesaj"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-lg mt-1"
                                />
                                <button
                                    onClick={() => handleSendNotification(notification)}
                                    className="bg-green-500 text-white px-3 py-1 rounded-lg mt-2"
                                >
                                    Gönder
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupNotificationsList;
