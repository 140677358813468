import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getUserList as getUserListApi,
  addUserList as addUserListApi,
  updateUserList as updateUserListApi,
  deleteUserList as deleteUserListApi,
  getUserGrid as getUserGridApi,
  addUserGrid as addUserGridApi,
  updateUserGrid as updateUserGridApi,
  deleteUserGrid as deleteUserGridApi
} from '../../helpers/fakebackend_helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Images
import avatar1 from 'assets/images/users/avatar-1.png';
import avatar2 from 'assets/images/users/avatar-2.png';
import avatar3 from 'assets/images/users/avatar-3.png';
import avatar4 from 'assets/images/users/avatar-4.png';
import avatar5 from 'assets/images/users/avatar-5.png';
import avatar6 from 'assets/images/users/avatar-6.png';
import avatar7 from 'assets/images/users/avatar-7.png';
import avatar8 from 'assets/images/users/avatar-8.png';
import avatar9 from 'assets/images/users/avatar-9.png';

export const getUserList = createAsyncThunk('users/getUserList', async () => {
  try {
    const response = getUserListApi();

    console.log('response', response);

    // const response = [
    //   {
    //     id: 1,
    //     userId: '#TW1500001',
    //     name: 'Marie Prohaska',
    //     img: avatar2,
    //     designation: 'Graphic Designer',
    //     location: 'United Kingdom',
    //     email: 'prohaska@tailwick.com',
    //     phoneNumber: '853 565 9802',
    //     joiningDate: '04 Jan, 2023',
    //     status: 'Verified'
    //   },
    //   {
    //     id: 2,
    //     userId: '#TW1500002',
    //     name: 'Jaqueline Hammes',
    //     img: avatar3,
    //     designation: 'ASP.Net Developer',
    //     location: 'Brazil',
    //     email: 'jaqueline@tailwick.com',
    //     phoneNumber: '546 6334 586',
    //     joiningDate: '18 Jan, 2023',
    //     status: 'Waiting'
    //   },
    //   {
    //     id: 3,
    //     userId: '#TW1500003',
    //     name: 'Marlene Hirthe',
    //     img: avatar4,
    //     designation: 'Angular Developer',
    //     location: 'Spain',
    //     email: 'marlene@tailwick.com',
    //     phoneNumber: '141 654 9876',
    //     joiningDate: '04 Feb, 2023',
    //     status: 'Verified'
    //   },
    //   {
    //     id: 4,
    //     userId: '#TW1500004',
    //     name: 'Akeem Paucek',
    //     img: avatar5,
    //     designation: 'Jr. Developer',
    //     location: 'Mexico',
    //     email: 'akeem.p@tailwick.com',
    //     phoneNumber: '783 962 3972',
    //     joiningDate: '12 Feb, 2023',
    //     status: 'Rejected'
    //   },
    //   {
    //     id: 5,
    //     userId: '#TW1500005',
    //     name: 'Stephon Trantow',
    //     img: avatar6,
    //     designation: 'Full Stack Developer',
    //     location: 'Spain',
    //     email: 'akeem.p@tailwick.com',
    //     phoneNumber: '032 126 5833',
    //     joiningDate: '31 Feb, 2023',
    //     status: 'Waiting'
    //   },
    //   {
    //     id: 6,
    //     userId: '#TW1500006',
    //     name: 'Domenic Tromp',
    //     designation: 'Team Leader',
    //     location: 'Germany',
    //     email: 'domenic@tailwick.com',
    //     phoneNumber: '612 6088 735',
    //     joiningDate: '27 Oct, 2023',
    //     status: 'Verified'
    //   },
    //   {
    //     id: 7,
    //     userId: '#TW1500007',
    //     name: 'Ethel Corwin',
    //     img: avatar7,
    //     designation: 'Web Designer',
    //     location: 'Italy',
    //     email: 'ecorwin@tailwick.com',
    //     phoneNumber: '216 897 4978',
    //     joiningDate: '03 Aug, 2023',
    //     status: 'Verified'
    //   },
    //   {
    //     id: 8,
    //     userId: '#TW1500008',
    //     name: 'Rickie Cremin',
    //     img: avatar8,
    //     designation: 'Web Designer',
    //     location: 'France',
    //     email: 'ecorwin@tailwick.com',
    //     phoneNumber: '388 946 3889',
    //     joiningDate: '01 July, 2023',
    //     status: 'Waiting'
    //   },
    //   {
    //     id: 9,
    //     userId: '#TW1500009',
    //     name: 'Reagan Larson',
    //     designation: 'Team Leader',
    //     location: 'Denmark',
    //     email: 'reagan@tailwick.com',
    //     phoneNumber: '612 6088 735',
    //     joiningDate: '27 Oct, 2023',
    //     status: 'Verified'
    //   },
    //   {
    //     id: 10,
    //     userId: '#TW1500010',
    //     name: 'Glennie Langosh',
    //     img: avatar9,
    //     designation: 'Project Manager',
    //     location: 'Germany',
    //     email: 'glennie@tailwick.com',
    //     phoneNumber: '357 716 8847',
    //     joiningDate: '11 Dec, 2023',
    //     status: 'Rejected'
    //   }
    // ];

    return response;
  } catch (error) {
    return error;
  }
});
export const addUserList = createAsyncThunk('users/addUserList', async (event: any) => {
  try {
    const response = addUserListApi(event);
    const data = await response;
    toast.success('User Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('User Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateUserList = createAsyncThunk('users/updateUserList', async (event: any) => {
  try {
    const response = updateUserListApi(event);
    const data = await response;
    toast.success('User updated Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('User updated Failed', { autoClose: 2000 });
    return error;
  }
});
export const deleteUserList = createAsyncThunk('users/deleteUserList', async (event: any) => {
  try {
    const response = deleteUserListApi(event);
    toast.success('User deleted Successfully', { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error('User deleted Failed', { autoClose: 2000 });
    return error;
  }
});

export const getUserGrid = createAsyncThunk('users/getUserGrid', async () => {
  try {
    const response = getUserGridApi();
    return response;
  } catch (error) {
    return error;
  }
});
export const addUserGrid = createAsyncThunk('users/addUserGrid', async (event: any) => {
  try {
    const response = addUserGridApi(event);
    const data = await response;
    toast.success('User Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('User Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateUserGrid = createAsyncThunk('users/updateUserGrid', async (event: any) => {
  try {
    const response = updateUserGridApi(event);
    const data = await response;
    toast.success('User updated Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('User updated Failed', { autoClose: 2000 });
    return error;
  }
});
export const deleteUserGrid = createAsyncThunk('users/deleteUserGrid', async (event: any) => {
  try {
    const response = deleteUserGridApi(event);
    toast.success('User deleted Successfully', { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error('User deleted Failed', { autoClose: 2000 });
    return error;
  }
});
