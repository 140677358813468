import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import * as Yup from "yup";
import { toast } from "react-toastify";



const AddPaymentMethod = () => {
    const [selectfiles, setSelectfiles] = useState([]);

    const location = useLocation()

    const { id } = location.state || {};

    console.log('id', id)


    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const validation = useFormic({
        initialValues: {
            paymentMethod: '',
        },

        onSubmit: async (values) => {

            const body = {
                paymentMethod: values.paymentMethod,
            }


            try {
                const response = await axios.post('payment-methods/add', body, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                console.log('Success:', response.data);
                toast('Ödeme Yöntemi Eklendi');
                navigate(0)
            } catch (error) {
                console.error('Error posting the data', error);
            }
        },

    });

    useEffect(() => {

    }, [])



    return (
        <React.Fragment>
            <BreadCrumb title='Ödeme Yöntemi Ekle' pageTitle='Ödeme Yöntemi Ekle' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                <div className="col-span-12">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Ödeme Yöntemi Ekle</h6>

                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="grid grid-cols-12 gap-5 lg:grid-cols-12 xl:grid-cols-12">
                                    <div className="col-span-12">
                                        <label htmlFor="paymentMethod" className="inline-block mb-2 text-base font-medium">Ödeme Yöntemi</label>
                                        <input type="text" id="paymentMethod" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Ödeme Yöntemi" required
                                            onChange={validation.handleChange}
                                            value={validation.values.paymentMethod}
                                        />

                                    </div>

                                    {/* <div className="xl:col-span-4">
                                        <label htmlFor="productDiscounts" className="inline-block mb-2 text-base font-medium">Discounts</label>
                                        <input type="number" id="productDiscounts" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="0%" required />
                                    </div> */}
                                    {/* <div className="xl:col-span-4">
                                        <label htmlFor="taxApplicable" className="inline-block mb-2 text-base font-medium">TAX Applicable</label>
                                        <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false name="taxApplicable" id="taxApplicable">
                                            <option value="">Select TAX Applicable</option>
                                            <option value="none">none</option>
                                            <option value="Exclusive">Exclusive</option>
                                            <option value="Professional">Professional</option>
                                            <option value="Entertainment">Entertainment</option>
                                        </select>
                                    </div> */}
                                    {/* <div className="xl:col-span-4">
                                        <label htmlFor="publishDateTime" className="inline-block mb-2 text-base font-medium">Publish Date & Time</label>
                                        <Flatpickr
                                            id="publishDateTime"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={{
                                                dateFormat: "d M, Y",
                                                enableTime: true,
                                            }}
                                            placeholder='Select date & time'
                                        />
                                    </div> */}

                                    {/* <div className="xl:col-span-4">
                                        <label htmlFor="productVisibility" className="inline-block mb-2 text-base font-medium">Visibility</label>
                                        <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false name="productVisibility" id="productVisibility">
                                            <option value="Public">Public</option>
                                            <option value="Hidden">Hidden</option>
                                        </select>
                                    </div> */}
                                    {/* <div className="lg:col-span-2 xl:col-span-12">
                                        <label htmlFor="productTag" className="inline-block mb-2 text-base font-medium">Product Tag</label>
                                        <input className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" id="productTag" data-choices data-choices-text-unique-true type="text" value="Fashion, Clothes, Headphones" />
                                    </div> */}
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Sıfırla</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {
                                            id ? 'KDV Güncelle' : 'Ödeme Yöntemi Ekle'
                                        }
                                    </button>
                                    {/* <button type="button" className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10">Draft & Preview</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default AddPaymentMethod;