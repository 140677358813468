import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
// Icons
import {
  Search,
  Eye,
  Trash2,
  Plus,
  MoreHorizontal,
  FileEdit,
  CheckCircle,
  Loader,
  X,
  Download,
  SlidersHorizontal,
  ImagePlus,
} from "lucide-react";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import axios from "axios";
import { APIClient, imageUrl } from "helpers/api_helper";
import { useMutation } from "@tanstack/react-query";

const api = new APIClient();

const KDVListView = () => {
  const [user, setUser] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();

  const [kdvList, setKdvList] = useState<any>([]);

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Get Data
  const getCategories = async () => {
    const response = await axios.get("/kdv/list");
    console.log("reponse ===>", response);
    setKdvList(response);
  };

  useEffect(() => {
    getCategories();
  }, []);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleKdvDeleteMutation = useMutation({
    mutationFn: (id: string) => {
      return api.delete(`/kdv/delete/${id}`, null);
    },
    onSuccess: (data: any) => {
      setKdvList(kdvList.filter((item: any) => item._id !== data._id));
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      kdv: (eventData && eventData.kdv) || "",
    },
    validationSchema: Yup.object({
      kdv: Yup.string().required("Lütfen KDV Değeri Giriniz"),
    }),

    onSubmit: async (values) => {
      const response = await axios.put(`/kdv/update/${eventData._id}`, values);

      if (response) {
        setIsEdit(false);
        setShow(false);
        getCategories();
      }
    },
  });

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["name", "designation", "location", "email", "status"];
    filterDataBySearch(kdvList, search, keysToSearch, setKdvList);
  };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "Verified":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
      case "Waiting":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            <Loader className="size-3 mr-1.5" />
            {item}
          </span>
        );
      case "Rejected":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            <X className="size-3 mr-1.5" />
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "KDV ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "KDV",
        accessorKey: "kdv",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link to="#" className="flex items-center gap-2">
            <h6 className="mb-1">
              <Link to="#!" className="name">
                {cell.getValue()}
              </Link>
            </h6>
          </Link>
        ),
      },
      {
        header: "Oluşturulma Tarihi",
        accessorKey: "createdAt",
        enableColumnFilter: false,
      },
      {
        header: "Aksiyon",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex space-x-2">
            <Tooltip id="edit" />
            <Tooltip id="delete" />
            <button
              data-tooltip-id="edit"
              data-tooltip-content={`Düzenle`}
              className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <FileEdit className="w-4 h-4" />
            </button>
            <button
              data-tooltip-id="delete"
              data-tooltip-content={`Sil`}
              className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
              onClick={() => {
                const data = cell.row.original;
                handleKdvDeleteMutation.mutate(data._id);
              }}
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        ),
      },
    ],
    [handleUpdateDataClick, handleKdvDeleteMutation]
  );

  return (
    <React.Fragment>
      <BreadCrumb title="KDV Listesi" pageTitle="KDV Listesi" />
      {/* <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} /> */}
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                  <div className="lg:col-span-2 ltr:lg:text-right rtl:lg:text-left xl:col-span-2 xl:col-start-11">
                    <Link
                      to="/add-new-kdv"
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <Plus className="inline-block size-4" />{" "}
                      <span className="align-middle">Kdv Ekle</span>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {kdvList && kdvList.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={kdvList || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sonuç Bulunamadı</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "KDV Güncelle" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="kdv"
                className="inline-block mb-2 text-base font-medium"
              >
                KDV
              </label>
              <input
                type="text"
                id="kdv"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="KDV Değeri Giriniz"
                name="kdv"
                onChange={validation.handleChange}
                value={validation.values.kdv || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                İptal
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "KDV Güncelle" : "Add User"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default KDVListView;
