import React, { useState } from "react";
import axios from "axios";
import BasicButtons from "pages/Components/UIElement/UIButton";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

const DefaultSelect = ({ data, giftId }: any) => {
    const [selectedProductId, setSelectedProductId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()

    console.log('Selected Product ID:', selectedProductId);

    const updateGiftProduct = async () => {
        if (!selectedProductId) {
            console.log("No product selected");
            return;
        }

        // Finding the selected product data by its ID
        const selectedData = data.find((item: any) => item._id === selectedProductId);
        if (!selectedData) {
            console.log("Selected product not found in data");
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.put(`/gift-product/update`, {
                id: giftId,
                menu: selectedData._id,
                productName: selectedData.productTitle,
            });
            console.log('Response:', response.data);
            setIsLoading(false);
            navigate(0)
        } catch (error) {
            console.error('Error updating the gift product:', error);
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="card w-full">
                <div className="card-body">
                    <div className="grid items-center grid-cols-1">
                        <div>
                            <select
                                className="py-3 pl-5 pr-8 form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 text-15"
                                value={selectedProductId}
                                onChange={(e) => setSelectedProductId(e.target.value)}
                            >
                                <option value="">Hediye Ürün Seç</option>
                                {data.map((item: any) => (
                                    <option key={item._id} value={item._id}>
                                        {item.productTitle}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-end pt-4">
                        <BasicButtons text={isLoading ? 'Kaydediliyor' : 'Kaydet'} onClick={updateGiftProduct} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DefaultSelect;
