// REGISTER
export const POST_REGISTER = '/auth/signup';
export const POST_FAKE_REGISTER = '/auth/signup';

// LOGIN
export const POST_LOGIN = '/auth/login';
export const POST_FAKE_LOGIN = '/auth/login';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

// PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/user';

// Chat
export const GET_CHAT = '/get-chat';
export const ADD_CHAT = '/add-chat';
export const DELETE_CHAT = '/delete-chat';
export const BOOKMARK_CHAT = '/delete-chat';

// MailBox
export const GET_MAIL = '/get-mail';
export const DELETE_MAIL = '/delete-mail';
export const UNREAD_MAIL = '/unread-mail';
export const STARED_MAIL = '/stared-mail';
export const TRASH_MAIL = '/trash-mail';

// Calendar
export const GET_EVENT = '/get-event';
export const ADD_EVENT = '/add-event';
export const UPDATE_EVENT = '/edit-event';
export const DELETE_EVENT = '/delete-event';

// Category
export const GET_CATEGORY = '/get-category';
export const DELETE_CATEGORY = '/delete-category';

// Ecommerce
// Orders
export const GET_ORDERS = '/orders/all';
export const ADD_ORDERS = '/add-orders';
export const UPDATE_ORDERS = '/edit-orders';
export const DELETE_ORDERS = '/delete-orders';

// Sellers
export const GET_SELLERS = '/get-sellers';
export const ADD_SELLERS = '/add-sellers';
export const UPDATE_SELLERS = '/edit-sellers';
export const DELETE_SELLERS = '/delete-sellers';

// Products
// List View
export const GET_PRODUCT_LIST = '/menu/all';
export const ADD_PRODUCT_LIST = '/menu/create';
export const UPDATE_PRODUCT_LIST = '/edit-product-list';
export const DELETE_PRODUCT_LIST = '/menu/delete';

// Grid View
export const GET_PRODUCT_GRID = '/get-product-grid';
export const ADD_PRODUCT_GRID = '/add-product-grid';
export const UPDATE_PRODUCT_GRID = '/edit-product-grid';
export const DELETE_PRODUCT_GRID = '/delete-product-grid';

// Overview
export const GET_REVIEW = '/get-review';
export const ADD_REVIEW = '/add-review';
export const UPDATE_REVIEW = '/edit-review';
export const DELETE_REVIEW = '/delete-review';

// HR Management
// Employee List
export const GET_EMPLOYEE = '/get-employee';
export const ADD_EMPLOYEE = '/add-employee';
export const UPDATE_EMPLOYEE = '/edit-employee';
export const DELETE_EMPLOYEE = '/delete-employee';

// Holidays
export const GET_HOLIDAYS = '/get-holidays';
export const ADD_HOLIDAYS = '/add-holidays';
export const UPDATE_HOLIDAYS = '/edit-holidays';
export const DELETE_HOLIDAYS = '/delete-holidays';

// Leaves Manage Employee
export const GET_LEAVE_MANAGE_EMPLOYEE = '/get-leave-manage-employee';

// Leave Manage (HR)
export const GET_LEAVE_MANAGE_HR = '/get-leave-manage-hr';
export const ADD_LEAVE_MANAGE_HR = '/add-leave-manage-hr';
export const UPDATE_LEAVE_MANAGE_HR = '/edit-leave-manage-hr';
export const DELETE_LEAVE_MANAGE_HR = '/delete-leave-manage-hr';

// Attendance
// Attendance (HR)
export const GET_ATTENDANCE = '/get-attendance';

// Main Attendance
export const GET_MAIN_ATTENDANCE = '/get-main-attendance';

// Departments
export const GET_DEPARTMENTS = '/get-departments';
export const ADD_DEPARTMENTS = '/add-departments';
export const UPDATE_DEPARTMENTS = '/edit-departments';
export const DELETE_DEPARTMENTS = '/delete-departments';

// Sales
// Estimates
export const GET_ESTIMATES = '/get-estimates';
export const ADD_ESTIMATES = '/add-estimates';
export const UPDATE_ESTIMATES = '/edit-estimates';
export const DELETE_ESTIMATES = '/delete-estimates';

// Payments
export const GET_PAYMENTS = '/get-payments';

// Expenses
export const GET_EXPENSES = '/get-expenses';
export const ADD_EXPENSES = '/add-expenses';
export const UPDATE_EXPENSES = '/edit-expenses';
export const DELETE_EXPENSES = '/delete-expenses';

// Payroll
// Employee Salary
export const GET_EMPLOYEE_SALARY = '/get-employee-salary';
export const ADD_EMPLOYEE_SALARY = '/add-employee-salary';
export const UPDATE_EMPLOYEE_SALARY = '/edit-employee-salary';
export const DELETE_EMPLOYEE_SALARY = '/delete-employee-salary';

// Notes
export const GET_NOTES = '/get-notes';
export const ADD_NOTES = '/add-notes';
export const UPDATE_NOTES = '/edit-notes';
export const DELETE_NOTES = '/delete-notes';

// Social
// Friends
export const GET_SOCIAL_FRIENDS = '/get-social-friends';

// Events
export const GET_SOCIAL_EVENTS = '/get-social-event';
export const ADD_SOCIAL_EVENTS = '/add-social-event';
export const UPDATE_SOCIAL_EVENTS = '/edit-social-event';
export const DELETE_SOCIAL_EVENTS = '/delete-social-event';

// invoice
export const GET_INVOICE_LIST = '/get-invoice-list';

// Users
// List View
export const GET_USER_LIST = '/user/all';
export const ADD_USER_LIST = '/add-userlist';
export const UPDATE_USER_LIST = '/user/update';
export const DELETE_USER_LIST = '/user/delete';

// Grid View
export const GET_USER_GRID = '/get-usergrid';
export const ADD_USER_GRID = '/add-usergrid';
export const UPDATE_USER_GRID = '/edit-usergrid';
export const DELETE_USER_GRID = '/delete-usergrid';
