import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        currentPassword: Yup.string().required("Current Password is required"),
        newPassword: Yup.string()
            .min(8, "Password should be at least 8 characters")
            .required("New Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
            .required("Confirm Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: async (values) => {

            const token = localStorage.getItem("authUser");

            if (!token) {
                return;
            }

            const userId = JSON.parse(token).user._id;

            console.log('userıd', token);


            setLoading(true);
            try {
                const response = await axios.put(`/user/update-admin-password`, {
                    userId: userId, // Replace with actual user ID
                    password: values.newPassword,
                    currentPassword: values.currentPassword,
                });



                if (response) {
                    toast.success("Şifre Başarıyla Güncellendi");
                    navigate('/dashboard'); // Redirect to dashboard or any other page
                }
            } catch (error) {
                toast.error("Şifre Güncellenemedi");
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <ToastContainer />
            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                <h2 className="text-2xl font-bold mb-4">Şifre Değiştir</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="currentPassword"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Güncel Şifre
                        </label>
                        <input
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                            className={`mt-1 block w-full px-3 py-2 border ${formik.touched.currentPassword && formik.errors.currentPassword
                                ? "border-red-500"
                                : "border-gray-300"
                                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {formik.touched.currentPassword && formik.errors.currentPassword ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.currentPassword}
                            </div>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="newPassword"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Yeni Şifre
                        </label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                            className={`mt-1 block w-full px-3 py-2 border ${formik.touched.newPassword && formik.errors.newPassword
                                ? "border-red-500"
                                : "border-gray-300"
                                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.newPassword}
                            </div>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Yeni Şifreyi Onayla
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            className={`mt-1 block w-full px-3 py-2 border ${formik.touched.confirmPassword && formik.errors.confirmPassword
                                ? "border-red-500"
                                : "border-gray-300"
                                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {formik.touched.confirmPassword &&
                            formik.errors.confirmPassword ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.confirmPassword}
                            </div>
                        ) : null}
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${loading ? "bg-gray-400" : "bg-indigo-600 hover:bg-indigo-700"
                                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                            disabled={loading}
                        >
                            {loading ? "Güncelleniyor..." : "Şifreyi Güncelle"}
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate("/dashboard")}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            İptal
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
