import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SketchPicker, TwitterPicker } from 'react-color';
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";

const api = new APIClient()

const AppColor = () => {
    const [selectfiles, setSelectfiles] = useState([]);
    const [colors, setColors] = useState({} as any);
    const location = useLocation();
    const { id } = location.state || {};
    // console.log('id', id);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const validation = useFormic({
        initialValues: {
            primary: '',
            secondary: '',
            third: '',
            fourth: '',
            fifth: '',
            sixth: '',
            seventh: '',
        },
        onSubmit: async (values) => {
            const body = {
                primary: values.primary,
                secondary: values.secondary,
                third: values.third,
                fourth: values.fourth,
                fifth: values.fifth,
                sixth: values.sixth,
                seventh: values.seventh,
            };
            try {
                const response = await axios.post('app-color/create', body, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                // console.log('Success:', response.data);
                navigate(0); // Optionally navigate or perform further actions
            } catch (error) {
                console.error('Error posting the data', error);
            }
        },
    });

    const handleChangeComplete = (color: any, name: any) => {
        validation.setFieldValue(name, color.hex);
    };


    const resetColorsMutation = useMutation(
        {
            mutationFn: () => api.put('/app-color/reset', null),
            onSuccess: (data: any) => {
                console.log('onSuccess', data);
                validation.setValues({
                    primary: '',
                    secondary: '',
                    third: '',
                    fourth: '',
                    fifth: '',
                    sixth: '',
                    seventh: '',
                })
            },
            onError: (error) => {
                console.log('onError', error);

            }
        }
    )

    const getColorsMutation = useMutation(
        {
            mutationFn: () => api.get('/app-color/all', null),
            onSuccess: (data: any) => {
                console.log('onSuccess', data);
                validation.setValues({
                    primary: data[0].primary,
                    secondary: data[0].secondary,
                    third: data[0].third,
                    fourth: data[0].fourth,
                    fifth: data[0].fifth,
                    sixth: data[0].sixth,
                    seventh: data[0].seventh,
                })
            },
            onError: (error) => {
                console.log('onError', error);

            }
        }
    )

    useEffect(() => {
        // Optional useEffect for any future actions
        getColorsMutation.mutate()
    }, []);

    return (
        <React.Fragment>
            <BreadCrumb title='Güncelle' pageTitle='Renk Güncelle' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                <div className="col-span-12">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Renk Değiştir</h6>
                            <form onSubmit={(event) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="grid grid-cols-12 gap-5 lg:grid-cols-12 xl:grid-cols-12">
                                    <div className="col-span-12">
                                        <label htmlFor="primary" className="inline-block mb-2 text-base font-medium">Birincil Renk</label>
                                        <input
                                            type="text"
                                            id="primary"
                                            name="primary"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Birincil Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.primary}
                                        />
                                        <TwitterPicker
                                            color={validation.values.primary}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'primary');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="secondary" className="inline-block mb-2 text-base font-medium">İkincil Renk</label>
                                        <input
                                            type="text"
                                            id="secondary"
                                            name="secondary"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="İkincil Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.secondary}
                                        />
                                        <TwitterPicker
                                            color={validation.values.secondary}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'secondary');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="third" className="inline-block mb-2 text-base font-medium">Üçüncül Renk</label>
                                        <input
                                            type="text"
                                            id="third"
                                            name="third"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Üçüncül Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.third}
                                        />
                                        <TwitterPicker
                                            color={validation.values.third}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'third');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="fourth" className="inline-block mb-2 text-base font-medium">Dördüncül Renk</label>
                                        <input
                                            type="text"
                                            id="fourth"
                                            name="fourth"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Dördüncül Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.fourth}
                                        />
                                        <TwitterPicker
                                            color={validation.values.fourth}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'fourth');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="fifth" className="inline-block mb-2 text-base font-medium">Beşincil Renk</label>
                                        <input
                                            type="text"
                                            id="fifth"
                                            name="fifth"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Beşincil Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.fifth}
                                        />
                                        <TwitterPicker
                                            color={validation.values.fifth}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'fifth');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="sixth" className="inline-block mb-2 text-base font-medium">Altıncıl Renk</label>
                                        <input
                                            type="text"
                                            id="sixth"
                                            name="sixth"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Altıncıl Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.sixth}
                                        />
                                        <TwitterPicker
                                            color={validation.values.sixth}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'sixth');
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="seventh" className="inline-block mb-2 text-base font-medium">Yedincil Renk</label>
                                        <input
                                            type="text"
                                            id="seventh"
                                            name="seventh"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Yedincil Renk"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.seventh}
                                        />
                                        <TwitterPicker
                                            color={validation.values.seventh}
                                            onChangeComplete={(color) => {
                                                handleChangeComplete(color, 'seventh');
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                                        onClick={() => resetColorsMutation.mutate()}
                                    >Sıfırla</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        Renk Güncelle
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AppColor;
