import React, { useEffect } from 'react';
import TerminalHeader from './TerminalHeader';
import LeftMenuItem from './components/LeftMenuItem';
import { toast } from "react-toastify";
import Move from 'assets/images/move.png';
import Cancel from 'assets/images/cancel.png';
import Edit from 'assets/images/edit.png';
import AdisyonHeader from './components/Adisyon/AdisyonHeader';
import AdisyonList from './components/Adisyon/AdisyonList';
import AdisyonListItem from './components/Adisyon/AdisyonList';
import SalonListItem from './components/Salons/SalonListItem';
import RightMenuItem from './components/Salons/RightMenuItem';
import { useMutation } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import { Modal } from 'flowbite-react';
import axios, { AxiosResponse } from 'axios';
import Swal from 'sweetalert2'


type Props = {};

const api = new APIClient();

const TerminalHome = (props: Props) => {
    const [adisyon, setAdisyon] = React.useState<any>([]);
    const [showCancelModal, setShowCancelModal] = React.useState<any>(false);
    const [isdepositBoxOpen, setIsDepositBoxOpen] = React.useState<any>(true);


    const LeftMenuData = [
        {
            img: Cancel,
            text: 'İptal',
            onClick: () => {
                setShowCancelModal(true);
            },
        },
    ];
    const deleteTableMutation = useMutation({
        mutationFn: (id: string) => {
            return api.delete(`/tables/delete/${id}`, null);
        },
        onSuccess: (data) => {
            getTablesMutation.mutate();
            toast.success("Masa başarı ile silindi.");
        },
        onError: (error) => {
            console.log(error);
            toast.error("Masa silerken hata oluştu.");
        },
    })
    const getTablesMutation = useMutation({
        mutationFn: async () => {
            return api.get('/tables/all', null);
        },
        onSuccess: (data) => {
            console.log(data);
            setAdisyon(data);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const createTableMutation = useMutation({
        mutationFn: (data: any) => {
            return api.create('/tables/create', data);
        },
        onSuccess: (data) => {
            getTablesMutation.mutate();
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const cancelTableMutation = useMutation({
        mutationFn: (id: string) => {
            return api.put(`/tables/table-clear/${id}`, null);
        },
        onSuccess: (data) => {
            getTablesMutation.mutate();
            toast.success("Adisyon başarı ile silindi.");
        },
        onError: (error) => {
            console.log(error);
            toast.error("Adisyon silerken hata oluştu.");
        },
    });

    const getDailyCashRegisterMutation = useMutation({
        mutationFn: async () =>
            await axios.get(
                `/cash-register/daily-cash-register-report?startDate=${new Date().toISOString().slice(0, 10)}&endDate=${new Date().toISOString().slice(0, 10)}`
            ),
        onSuccess: (response: any) => {
            const isOpen = response.some((item: any) => item.isOpen);
            setIsDepositBoxOpen(isOpen);
        },
        onError: (error) => {
            console.log(error);
        },
    });


    useEffect(() => {
        getTablesMutation.mutate();
        getDailyCashRegisterMutation.mutate();
    }, []);

    const handleCancelTable = (id: string) => {
        cancelTableMutation.mutate(id);
    };

    const handleAddTable = () => {
        console.log('Add Table clicked');
        createTableMutation.mutate({});
    };
    const deleteTable = (id: string) => {
        deleteTableMutation.mutate(id);
    }
    
    if (!isdepositBoxOpen) {
        console.log(isdepositBoxOpen)
        let timerInterval: NodeJS.Timeout;
        Swal.fire({
            title: "Açık Kasa Bulunamadı",
            html: "<b></b> milisaniye sonra kasa açma sayfasına yönlendirileceksiniz.",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                const timer = Swal.getPopup()?.querySelector("b");
                if (timer) {
                    timerInterval = setInterval(() => {
                        timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                }
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
            
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                window.location.href = '/cash-register?open=true';
            }
        })
    }

    return (
        <>
            <Modal show={showCancelModal} onClose={() => setShowCancelModal(false)}>
                <Modal.Header>
                    <h2 className="text-2xl font-bold text-black">Masalar</h2>
                </Modal.Header>
                <Modal.Body>
                    {adisyon.map((item: any, index: any) => {
                        if (item.tableItems.length > 0) {
                            return (
                                <div
                                    key={index}
                                    className='flex flex-row justify-between items-center px-4 py-4 border-t-2 border-t-gray-200'
                                >
                                    <span className='text-xl font-bold'>Masa {item.tableName}</span>
                                    <div
                                        onClick={() => {
                                            handleCancelTable(item._id);
                                            setShowCancelModal(false);
                                        }}
                                        className='btn btn-primary bg-blue-500 cursor-pointer hover:bg-blue-300 text-white'>
                                        Masayı İptal Et
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                    {adisyon.every((item: any) => item.tableItems.length === 0) && (
                        <div className="text-center">Adisyon Yok</div>
                    )}
                </Modal.Body>
            </Modal>
            <TerminalHeader />
            <div className='h-full p-6 bg-[#f1f2f9]'>
                <div className='grid h-full rounded-xl grid-cols-12 gap-4'>
                    <div className='col-span-7 rounded-bl-xl bg-white h-[95vh]'>
                        <div className='grid grid-cols-12 gap-4 p-4 h-auto'>
                            {adisyon.map((item: any, index: any) => (
                                <SalonListItem key={index} adisyon={item} deleteTable={deleteTable} onCancel={handleCancelTable} />
                            ))}
                            <div
                                className='flex flex-col items-center justify-center bg-gray-100 border border-gray-200 rounded-xl cursor-pointer h-36 col-span-3'
                                onClick={handleAddTable}
                            >
                                <span className='text-lgtext-gray-500'>Masa Ekle</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-5 flex flex-col justify-between h-[95vh] bg-white rounded-br-xl border-r border-gray-200'>
                        <div>
                            <AdisyonHeader totalSalon={
                                adisyon.reduce((count: number, item: any) => {
                                    return item.tableItems.length > 0 ? count + 1 : count;
                                }, 0)
                            } />
                            {adisyon.length > 0 ? (
                                adisyon.every((item: any) => item.tableItems.length === 0) ? (
                                    <div className="text-center">Adisyon Yok</div>
                                ) : (
                                    adisyon.map((item: any, index: any) => (
                                        item.tableItems.length > 0 && <AdisyonListItem key={index} adisyon={item} />
                                    ))
                                )
                            ) : (
                                <div className="text-center">Adisyon Yok</div>
                            )}
                        </div>
                        <div className='flex flex-row justify-between items-center px-4 py-4 border-t-2 border-t-gray-200'>
                            <span className='text-2xl font-bold'>Toplam</span>
                            <span className='text-2xl font-bold text-black hover:text-gray-800'>
                                ₺ {adisyon.reduce((totalSum: any, item: any) => {
                                    return totalSum + item.tableItems.reduce((acc: any, item: any) => acc + (Number(item.sellingPrice) * (item.quantity)), 0);
                                }, 0)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TerminalHome;
