import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import { toast } from "react-toastify";
import CustomDropdown from "./CustomDropdown";
import "react-toastify/dist/ReactToastify.css";

const api = new APIClient();

const CreateGroupNotifications = (props: any) => {
  const navigate = useNavigate();

  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupColor, setNewGroupColor] = useState("blue");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [notificationModal, setNotificationModal] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");

  const getAllUsersMutation = useMutation({
    mutationFn: () => api.get("/user/all", null),
    onSuccess: (data: any) => {
      setUsers(data);
      setOriginalUsers(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const createGroupNotificationMutation = useMutation({
    mutationFn: (data: any) => api.create("/group-notifications/create", data),
    onSuccess: (data: any) => {
      console.log(data);
      navigate("/group-notification-list");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const sendNotificationToGroupMutation = useMutation({
    mutationFn: ({
      groupId,
      title,
      message,
    }: {
      groupId: string;
      title: string;
      message: string;
    }) =>
      api.create(`/notifications/send-to-group/${groupId}`, { title, message }),
    onSuccess: () => {
      toast.success("Bildirim Başarıyla Gönderildi");
      setNotificationModal(false);
      setSelectedLabel("");
      setTitle("");
      setMessage("");
    },
    onError: () => {
      toast.error("Error sending notification to group");
    },
  });

  const createNotificationLabelsMutation = useMutation({
    mutationFn: (data: any) =>
      api.create("/group-notifications/create-label", data),
    onSuccess: () => {
      toast.success("Label created successfully");
      getLabelsQuery.mutate();
    },
    onError: () => {
      toast.error("Error creating label");
    },
  });

  const getLabelsQuery = useMutation({
    mutationFn: () => api.get("/group-notifications/list-labels", null),
    onSuccess: (data: any) => {
      setLabels(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const assignLabelMutation = useMutation({
    mutationFn: ({ userId, labelId }: { userId: string; labelId: string }) =>
      api.put(`/group-notifications/assign-label/${userId}`, { labelId }),
    onSuccess: (data: any, variables: { userId: string; labelId: string }) => {
      toast.success("Etiket Atandı");
      setUsers((prevUsers: any) =>
        prevUsers.map((user: any) =>
          user._id === variables.userId
            ? { ...user, labels: [...user.labels, variables.labelId] }
            : user
        )
      );
      setLabels((prevLabels: any) =>
        prevLabels.map((label: any) =>
          label._id === variables.labelId
            ? { ...label, users: [...label.users, variables.userId] }
            : label
        )
      );
    },
    onError: () => {
      toast.error("Error assigning label");
    },
  });

  const deleteLabelMutation = useMutation({
    mutationFn: ({ userId, labelId }: { userId: string; labelId: string }) =>
      api.put(`/group-notifications/remove-label/${userId}`, { labelId }),
    onSuccess: (data: any, variables: { userId: string; labelId: string }) => {
      toast.success("Kullanıcıdan etiket kaldırıldı");
      setUsers((prevUsers: any) =>
        prevUsers.map((user: any) =>
          user._id === variables.userId
            ? {
                ...user,
                labels: user.labels.filter(
                  (label: any) => label !== variables.labelId
                ),
              }
            : user
        )
      );
      setLabels((prevLabels: any) =>
        prevLabels.map((label: any) =>
          label._id === variables.labelId
            ? {
                ...label,
                users: label.users.filter((id: any) => id !== variables.userId),
              }
            : label
        )
      );
    },
    onError: () => {
      toast.error("Error removing label");
    },
  });

  const sendNotificationMutation = useMutation({
    mutationFn: ({
      title,
      message,
      selectedUser,
      type,
    }: {
      title: string;
      message: string;
      selectedUser: string;
      type: number;
    }) =>
      api.create(`/notifications/create/${selectedUser}`, {
        title,
        message,
        type,
      }),
    onSuccess: () => {
      toast.success("Bildirim Başarıyla Gönderildi.");
      setSelectedUser("");
    },
    onError: () => {
      toast.error("Bildirim Gönderilemedi.");
    },
  });

  useEffect(() => {
    getAllUsersMutation.mutate();
  }, []);

  useEffect(() => {
    getLabelsQuery.mutate();
  }, []);

  const handleUserClick = (userId: any) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id: any) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value.toLowerCase();
    const filteredUsers = originalUsers.filter(
      (user: any) =>
        user.name.toLowerCase().includes(search) ||
        user.lastname.toLowerCase().includes(search) ||
        user.email.toLowerCase().includes(search) ||
        user?.labels?.some((label: any) =>
          label?.name?.toLowerCase().includes(search)
        )
    );
    setUsers(filteredUsers);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCreateGroupTag = () => {
    closeModal();
    createNotificationLabelsMutation.mutate({
      name: newGroupName,
      color: newGroupColor,
    });
  };  

  const handleAssignLabel = (userId: string, labelId: string) => {
    assignLabelMutation.mutate({ userId, labelId });
  };

  const handleDeleteLabel = (labelId: string, userId: string) => {
    deleteLabelMutation.mutate({ userId, labelId });
  };

  const handleSendNotification = () => {
    const data: any = { title, message, selectedUser };
    sendNotificationMutation.mutate({
      ...data,
      type: 3,
    });
    setNotificationModal(false);
    setSelectedUser("");
  };
  const handleLabelFilter = (labelId: string) => {
    setSelectedLabel(labelId);
  
    if (labelId === "") {
      setUsers(originalUsers);
    } else {
      const filteredUsers = originalUsers.filter((user: any) =>
        user.labels.some((label: any) => label._id === labelId)
      );
      setUsers(filteredUsers);
    }
  };
  
  
  return (
    <div className="p-5 max-w-5xl mx-auto bg-gray-100 rounded-lg">
      <h1 className="text-start mb-5 text-xl font-semibold text-gray-800">
        Üye ve Grup Listesi
      </h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Kullanıcı / Grup Etiketi Ara..."
          onChange={handleSearch}
          className="w-56 p-4 border border-gray-300 rounded-lg mr-2"
        />
        <select
  value={selectedLabel}
  onChange={(e) => handleLabelFilter(e.target.value)}
  className="p-4 border border-gray-300 rounded-lg mr-2"
>
  <option value="">Grup Etiketi Seç</option>
  {labels.map((label: any) => (
    <option key={label._id} value={label._id}>
      {label.name}
    </option>
  ))}
</select>

        <button
          onClick={() => {
            if (!selectedLabel) {
              toast.error("Lütfen bir grup etiketi seçiniz");
              return;
            } else {
              setNotificationModal(true);
            }
          }}
          className="p-4 bg-gray-500 text-white rounded-lg"
        >
          Seçili Gruba Bildirim Gönder
        </button>
        <button
          onClick={openModal}
          className="p-4 bg-blue-500 text-white rounded-lg"
        >
          Grup Etiketi Oluştur
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Ad
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Soyad
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bakiye
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Grup Etiketleri
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                İşlemler
              </th>
            </tr>
          </thead>
          {getAllUsersMutation.isPending || getLabelsQuery.isPending ? (
            <tbody>
              <tr>
                <td colSpan={6} className="text-center">
                  <div className="flex items-center h-full justify-center w-full">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user: any) => {
                  const isSelected = selectedUsers.includes(user._id);
                  return (
                    <tr
                      key={user._id}
                      className={`transition-transform transform ${
                        isSelected
                          ? "bg-blue-100 border-blue-500 scale-105"
                          : "bg-white"
                      }`}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {user.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {user.lastname}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {user.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {user.wallet.balance} TL
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <CustomDropdown
                          labels={labels}
                          selectedLabels={labels.filter((label: any) =>
                            label.users.includes(user._id)
                          )}
                          onSelect={(labelId: any) =>
                            handleAssignLabel(user._id, labelId)
                          }
                          onDelete={(labelId: any) =>
                            handleDeleteLabel(labelId, user._id)
                          }
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex items-center">
                        <button
                          onClick={() => {
                            setNotificationModal(true);
                            setSelectedUser(user._id);
                          }}
                          className="text-gray-600 bg-[#e5e7eb] p-2 rounded-md hover:text-gray-900 mr-2"
                        >
                          <i className="fas fa-bell"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      </div>

      <Modal show={isModalOpen} onClose={closeModal}>
        <Modal.Header>
          <h2 className="text-xl font-bold text-gray-800">
            Grup Etiketi Oluştur
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-between gap-10 items-center mb-4">
            <div className="w-1/3 pr-2">
              <label className="block text-sm font-medium text-gray-700">
                Grup Adı
              </label>
              <input
                type="text"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                className="w-full p-2 border border-gray-100 bg-[#f6f6f6] rounded-lg"
                placeholder="Grup Adı"
              />
            </div>
            <div className="w-1/3 pl-2 flex flex-col items-center">
              <label className="block text-sm font-medium text-gray-700">
                Grup Rengi
              </label>
              <div className="flex space-x-2 mb-2">
                {["blue", "yellow", "red", "gray", "green"].map((color) => (
                  <button
                    key={color}
                    onClick={() => setNewGroupColor(color)}
                    className={`p-4 rounded-full ${
                      newGroupColor !== color
                        ? `bg-${color}-500`
                        : `bg-${color}-300`
                    }`}
                  />
                ))}
              </div>
            </div>
            <div className="w-1/3 flex h-auto items-center justify-center">
              <div
                className={`p-1 min-h-6 min-w-24 text-center rounded bg-${newGroupColor}-500 text-white`}
              >
                {newGroupName ? newGroupName : "Grup Adı"}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={handleCreateGroupTag}
              className="p-4 bg-blue-500 text-white rounded-lg"
            >
              Grup Etiketi Oluştur
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={notificationModal}
        onClose={() => setNotificationModal(false)}
      >
        <Modal.Header>
          <h2 className="text-xl font-bold text-gray-800">
            Grup Bildirimi Gönder
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Başlık
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                placeholder="Başlık"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Mesaj
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                placeholder="Mesajınız..."
              />
            </div>
            <div className="text-center">
              <button
                onClick={() => {
                  if (!selectedLabel) {
                    handleSendNotification();
                  } else {
                    sendNotificationToGroupMutation.mutate({
                      groupId: selectedLabel,
                      title,
                      message,
                    });
                  }
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Bildirim Gönder
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateGroupNotifications;
