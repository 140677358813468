import { Autocomplete, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const api = new APIClient();

const MakeProductCount = () => {
    const navigate = useNavigate();

    const [products, setProducts] = React.useState<any>([]);
    const [productCount, setProductCount] = React.useState<any>([]);
    const [productCountData, setProductCountData] = React.useState<any>({
        name: '',
        marketName: '',
        countDate: '',
        description: '',
    });

    const startProductCountMutation = useMutation({
        mutationFn: () => {
            return api.create('/product-count/create', {
                name: productCountData.name,
                marketName: productCountData.marketName,
                countDate: productCountData.countDate,
                countedProducts: productCount,
                completed: true
            });
        },
        onSuccess: (data) => {
            console.log('data', data);
            navigate('/product-count');
        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    const getProductsMutations = useMutation({
        mutationFn: () => {
            return api.get('/menu/all', null);
        },
        onSuccess: (data) => {
            setProducts(data);
        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    const handleAddProduct = () => {
        setProductCount([...productCount, {
            barcodeNumber: '',
            productTitle: '',
            existingStock: 0,
            countedStock: 0,
            buyingPrice: 0,
            sellingPrice: 0,
            buyingLoss: 0,
            sellingLoss: 0,
        }]);
    };

    const handleProductChange = (index: number, field: string, value: any) => {
        const newProductCount = [...productCount];
        if (field === 'productTitle') {
            const selectedProduct = products.find((product: any) => product._id === value);
            if (selectedProduct) {
                newProductCount[index] = {
                    ...newProductCount[index],
                    barcodeNumber: selectedProduct.barcodeNumber,
                    productTitle: selectedProduct.productTitle,
                    existingStock: selectedProduct.stock,
                    buyingPrice: selectedProduct.buyingPrice,
                    sellingPrice: selectedProduct.sellingPrice
                };
            }
        } else {
            newProductCount[index][field] = field === 'countedStock' ? parseInt(value, 10) : value;
            if (field === 'countedStock') {
                const buyingLoss = newProductCount[index].existingStock > newProductCount[index].countedStock
                    ? (newProductCount[index].existingStock - newProductCount[index].countedStock) * newProductCount[index].buyingPrice
                    : 0;
                const sellingLoss = newProductCount[index].existingStock > newProductCount[index].countedStock
                    ? (newProductCount[index].existingStock - newProductCount[index].countedStock) * newProductCount[index].sellingPrice
                    : 0;
                newProductCount[index].buyingLoss = buyingLoss;
                newProductCount[index].sellingLoss = sellingLoss;
            }
        }
        setProductCount(newProductCount);
    };

    useEffect(() => {
        getProductsMutations.mutate();
    }, []);

    return (
        <>
            <div className="container mx-auto p-4">
                <div className="flex flex-col space-y-4 bg-white p-6 rounded shadow-lg">
                    <div className="flex items-center justify-between">
                        <button
                            onClick={() => {
                                navigate('/product-count');
                            }}
                            className="text-blue-500">&#8592; Geri</button>
                        <h2 className="text-xl font-semibold">Sayımı Başlat</h2>
                        <div></div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Sayım İsmi</label>
                            <input
                                type="text"
                                value={productCountData.name}
                                onChange={(e) => {
                                    setProductCountData((prev: any) => ({ ...prev, name: e.target.value }));
                                }}
                                id="name"
                                placeholder="Merkez-2024-05-17"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                            />
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="marketName" className="block text-sm font-medium text-gray-700">Depo</label>
                            <input
                                type="text"
                                id="marketName"
                                placeholder="Depo"
                                value={productCountData.marketName}
                                onChange={(e) => {
                                    setProductCountData((prev: any) => ({ ...prev, marketName: e.target.value }));
                                }}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                            />
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="countDate" className="block text-sm font-medium text-gray-700">Sayım Tarihi</label>
                            <input
                                type="date"
                                id="countDate"
                                value={productCountData.countDate}
                                onChange={(e) => {
                                    setProductCountData((prev: any) => ({ ...prev, countDate: e.target.value }));
                                }}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                            />
                        </div>
                    </div>


                    <div className='flex items-center justify-end'>
                        <button
                            onClick={handleAddProduct}
                            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white w-8 h-8 flex items-center justify-center  px-4 py-2 rounded-full shadow-lg"
                        >
                            <span className='text-xl'>
                                +
                            </span>
                        </button>
                    </div>
                    <div className="flex items-center justify-center">

                        {productCount.length > 0 ? (
                            <table className="min-w-full bg-white border mt-4">
                                <thead>
                                    <tr>
                                        <th className="border px-4 py-2">Ürün Adı</th>
                                        <th className="border px-4 py-2">Stok</th>
                                        <th className="border px-4 py-2">Sayım Adedi</th>
                                        <th className="border px-4 py-2">Birim Alış</th>
                                        <th className="border px-4 py-2">Birim Satış</th>
                                        <th className="border px-4 py-2">Birim Alış Zarar</th>
                                        <th className="border px-4 py-2">Birim Satış Zarar</th>
                                        <th className="border px-4 py-2">İşlem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productCount.map((item: any, index: number) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                            <td className="border w-56 py-2">
                                                <Autocomplete
                                                    disablePortal
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        padding: '10px'
                                                    }}
                                                    filterOptions={(options, state) =>
                                                        options.filter((option: any) =>
                                                            option.label.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                                                            products.find((product: any) => product._id === option.value)?.barcodeNumber.includes(state.inputValue)
                                                        )
                                                    }
                                                    onChange={(event, newValue: any) => {
                                                        if (newValue) {
                                                            handleProductChange(index, 'productTitle', newValue.value);
                                                        }
                                                    }}
                                                    options={
                                                        products.map((product: any) => ({
                                                            label: product.productTitle,
                                                            value: product._id
                                                        }))
                                                    }
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Ürünler" />}
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.existingStock}
                                                    onChange={(e) => handleProductChange(index, 'existingStock', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                    readOnly
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.countedStock}
                                                    onChange={(e) => handleProductChange(index, 'countedStock', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.buyingPrice}
                                                    onChange={(e) => handleProductChange(index, 'buyingPrice', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                    readOnly
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.sellingPrice}
                                                    onChange={(e) => handleProductChange(index, 'sellingPrice', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                    readOnly
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.buyingLoss}
                                                    onChange={(e) => handleProductChange(index, 'buyingLoss', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                    readOnly
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={item.sellingLoss}
                                                    onChange={(e) => handleProductChange(index, 'sellingLoss', e.target.value)}
                                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                                                    readOnly
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <button
                                                    onClick={() => {
                                                        setProductCount(productCount.filter((item: any, i: any) => i !== index));
                                                    }}
                                                    className="text-red-500"
                                                >
                                                    Sil
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="text-lg font-semibold">Henüz ürün eklenmedi.</p>
                        )}
                    </div>
                    <button
                        onClick={() => {
                            startProductCountMutation.mutate();
                        }}
                        disabled={productCount.length === 0}
                        className={`mt-4  ${productCount.length > 0 && 'hover:bg-blue-600'} ${productCount.length === 0 ? 'bg-gray-200' : 'bg-blue-500'}  text-white px-4 py-2 rounded shadow-lg`}
                    >
                        Sayımı Kaydet
                    </button>
                </div>
            </div>
        </>
    );
};

export default MakeProductCount;
