import React, { useState } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

import Modal from "Common/Components/Modal";

interface CropperProps {
    image: any;
    open: boolean;
    setOpen: (open: boolean) => void;
    onCropDone: (croppedArea: any) => void;
    cropperRef: React.RefObject<ReactCropperElement>;
}


const CropperC = ({ image, open, setOpen, onCropDone , cropperRef}: CropperProps) => {
    const [croppedArea, setCroppedArea] = useState<any>(null);

    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setCroppedArea(cropper.getData());
    }

    return (
        <Modal
            show={open}
            id="defaultModal"
            modal-center="true"
            className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
            dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
        >
            <Modal.Body className="min-h-[400px] rounded-md">
                <Cropper
                    src={image}
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={16 / 9}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="flex items-center justify-end gap-4 m-2">
                    <button
                        type='button'
                        className="btn btn-primary bg-red-500 cursor-pointer hover:bg-red-300 text-white"
                        onClick={() => setOpen(false)}
                    >
                        İptal
                    </button>
                    <button
                        type='button'
                        className="btn btn-primary bg-blue-500 cursor-pointer hover:bg-blue-300 text-white"
                        onClick={() => onCropDone(croppedArea)}
                    >
                        Kaydet
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CropperC;