import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

interface UserChipsProps {
    users: {
        _id: string;
        name: string;
        lastname: string;
    }[];
    onDelete: (userId: string) => void;
}

interface ChipData {
    key: string;
    label: string;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const UserChips: React.FC<UserChipsProps> = ({ users, onDelete }) => {
    const chipData: readonly ChipData[] = users.map(user => ({
        key: user._id,
        label: `${user.name} ${user.lastname}`,
    }));

    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {chipData.map((data) => (
                <ListItem key={data.key}>
                    <Chip
                        label={data.label}
                        onDelete={() => onDelete(data.key)}
                    />
                </ListItem>
            ))}
        </Paper>
    );
};

export default UserChips;
