import React from 'react';
import logo from "assets/images/logo-sm-2.png";
import ArrowLeft from "assets/images/arrow-left.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // Importing a user icon for the profile

import { ChevronsLeft, ChevronsRight, Gem, LogOut, Mail, MessagesSquare, Search, Settings, ShoppingCart, User2, User2Icon } from 'lucide-react';
import userProfile from "assets/images/users/user-profile.png";
import { Dropdown } from 'Common/Components/Dropdown';
import { toast } from 'react-toastify'; // Toastify import

type Props = {
    edit?: boolean;
};

const url = 'https://api.macfitcafe.com';
const local = 'http://localhost:3003';

const TerminalHeader = (props: Props) => {
    const navigate = useNavigate();
    const getUser = localStorage.getItem('authUser');
    const user = getUser ? JSON.parse(getUser) : null;

    const goBack = () => {
        if (props.edit) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    };

    // Add notification when directing to cash register
    const handleCashRegisterClick = (message:string) => {
        toast.info(message);
    };

    return (
        <div className='flex items-center justify-between px-4 bg-white border-b border-gray-200'>
            <div className='flex items-center'>
                <Link to="/">
                    <img
                        src={logo}
                        alt="logo"
                        className='w-16 h-16 object-contain'
                    />
                </Link>
                <div className='border-l border-gray-300 h-12 mx-4'></div>
                <img
                    src={ArrowLeft}
                    alt="Back"
                    className='w-8 h-8 object-contain cursor-pointer'
                    onClick={goBack}
                />
            </div>
            <div className='flex row  justify-end'>
                <div className="h-auto items-center my-10 mr-5 flex ">
                    <Link
                        className="btn btn-primary bg-blue-500"
                        to="/cash-register"
                        onClick={()=>handleCashRegisterClick('Kasayı açmaya yönlendiriliyorsunuz.')} 
                    >
                        <span className="text-white">Kasa İşlemleri </span>
                    </Link>
                </div>
                <div className="h-auto items-center my-10 mr-5 flex ">
                    <Link
                        className="btn btn-primary bg-blue-500"
                        to="/cash-register-list"
                        onClick={()=>handleCashRegisterClick('Kasalara yönlendiriliyorsunuz.')}
                    >
                        <span className="text-white">Kasaları Görüntüle</span>
                    </Link>
                </div>
            </div>
            <Dropdown className="relative flex items-center h-header">
                <Dropdown.Trigger type="button" className="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200" id="dropdownMenuButton" data-bs-toggle="dropdown">

                    <div className="flex items-center bg-pink-100 h-[37.5px] px-2 rounded-full">
                        <img src={`${url}/upload-file/profilePictures/${user?.user?.profilePicture}`} alt="" className="size-[37.5px] rounded-full" />
                        {user && (
                            <span className="ml-2 text-slate-600 dark:text-zink-200">{`${user?.user?.name.charAt(0).toUpperCase() + user?.user?.name.slice(1)
                                } ${user?.user?.lastname.charAt(0).toUpperCase() + user?.user?.lastname.slice(1)
                                }`}</span>
                        )}
                    </div>
                </Dropdown.Trigger>
                <Dropdown.Content placement="right-end" className="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] dark:bg-zink-600" aria-labelledby="dropdownMenuButton">
                    <ul>
                        <li className="pt-2 mt-2 border-t border-slate-200 dark:border-zink-500">
                            <a className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500" href={process.env.PUBLIC_URL + "/update-profile"}><User2Icon className="inline-block size-4 ltr:mr-2 rtl:ml-2"></User2Icon>Profil Düzenle</a>
                        </li>

                        <li className="pt-2 mt-2 border-t border-slate-200 dark:border-zink-500">
                            <a className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500" href={process.env.PUBLIC_URL + "/logout"}><LogOut className="inline-block size-4 ltr:mr-2 rtl:ml-2"></LogOut> Çıkış Yap</a>
                        </li>
                    </ul>
                </Dropdown.Content>
            </Dropdown>
        </div>
    );
};

export default TerminalHeader;
