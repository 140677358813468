import { createSelector } from '@reduxjs/toolkit'
import BreadCrumb from 'Common/BreadCrumb'
import axios from 'axios'
import { imageUrl } from 'helpers/api_helper'
import DefaultSelect from 'pages/Components/Forms/Select/DefaultSelect'
import BasicButtons from 'pages/Components/UIElement/UIButton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    getProductList as onGetProductList,
} from 'slices/thunk';

type Props = {}

const GiftProduct = (props: Props) => {
    const [data, setData] = useState<any>([]);
    const [giftProduct, setGiftProduct] = useState<any>([]);

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Ecommerce,
        (state) => ({
            dataList: state.productList
        })
    );

    const { dataList } = useSelector(selectDataList);

    console.log('datalist', dataList)


    const getGiftProduct = async () => {
        const response = await axios.get('/gift-product')
        console.log('response', response)
        setGiftProduct(response)
    }




    // Get Data
    useEffect(() => {
        dispatch(onGetProductList());
    }, [dispatch]);

    useEffect(() => {
        getGiftProduct();
    }, []);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);

    return (
        <React.Fragment>
            <BreadCrumb title='Hediye Ürün' pageTitle='Hediye Ürün' />
            <div className="card h-56flex p-12" id="giftProductPage">
                <DefaultSelect data={data} giftId={
                    giftProduct.map((item: any) => item._id)
                } />


                <div className='
                    w-full rounded-lg h-40 bg-white mt-4 shadow-lg
                '>
                    {
                        giftProduct.map((item: any, index: number) => {
                            return (
                                <div key={index} className='
                                    flex justify-between items-center
                                    p-4 border-b border-gray-200
                                '>
                                    <div className='flex items-center'>
                                        <img src={imageUrl + `/upload-file/product/${item?.menu?.productImage}`} alt={item?.name} className='w-20 h-20' />
                                        <div className='ml-4'>
                                            <h1 className='text-lg font-semibold'>{item?.menu?.productTitle}</h1>
                                            <p className='text-sm'>{item?.menu?.productDescription}</p>
                                            <p className='text-sm'>{item?.menu?.productPrice}</p>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default GiftProduct