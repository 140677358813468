import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import * as Yup from "yup";

// Icon
import { Pencil, UploadCloud } from 'lucide-react';

// Image
import productImg03 from "assets/images/product/img-03.png";
import Dropzone from "react-dropzone";
import { addProductList } from "slices/thunk";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import { ReactCropperElement } from "react-cropper";
import CropperC from "../Components/CropImage/CropImage";
import { onDrop, onCropDone } from "../Components/CropImage/CropImageHelper";


const api = new APIClient()

const BannerManage = () => {
    const [selectfiles, setSelectfiles] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState("");
    const cropperRef = useRef<ReactCropperElement>(null);

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const validation = useFormic({
        initialValues: {
        },

        onSubmit: async (values) => {

            const formData = new FormData();
            selectfiles.forEach((file: any) => {
                formData.append('files', file);
            });
            console.log('values', values)
            Object.keys(values).forEach(key => {
                //remove _id createdAt updatedAt __v and productPrice should be
                if (key !== '_id' && key !== 'createdAt' && key !== 'updatedAt' && key !== '__v') {
                    formData.append(key, (values as any)[key]);
                }
            });

            try {
                const response = await axios.post(`/banner/add`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Success:', response);
                navigate(0);
            } catch (error) {
                console.error('Error updating the data', error);
            }



        },

    });
    // const imageUrl = 'http://localhost:3003/upload-file/banner/';
    const imageUrl = 'https://api.macfitcafe.com/upload-file/banner/';

    const deleteBannerMutation = useMutation(
        {
            mutationFn: async (index: any) => {
                return api.put(`/banner/delete/${index}`, null);
            },
            onMutate: async (id: any) => {
                console.log('onMutate', id);
            },
            onError: (error) => {
                console.log('onError', error);
            }
        }
    )

    const getBannersMutation = useMutation(
        {
            mutationFn: async () => {
                return api.get('/banner/all', null);
            },
            onSuccess: (data: any) => {
                setSelectfiles(data.bannerImage.map((item: any) => ({
                    ...item,
                    priview: imageUrl + item,
                    formattedSize: formatBytes(item.size || 0),
                })));

                console.log(
                    selectfiles
                );

                console.log('data', selectfiles)
            },
        },
    )

    const handleAcceptfiles = (files: File[]) => {
        files.map((file) => {
            return Object.assign(file, {
                priview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
        });
        setSelectfiles([...selectfiles, ...files]);
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const wrapperSetCroppedImage = useCallback((image: string) => {
        setCroppedImage(image);
    }, [setCroppedImage]);

    const wrapperSetOpen = useCallback((open: boolean) => {
        setOpen(open);
    }, [setOpen]);

    useEffect(() => {
        getBannersMutation.mutate()
    }, [])

    return (
        <React.Fragment>
            <BreadCrumb title='Banner Güncelle' pageTitle='Banner' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                <div className="xl:col-span-9">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Banner Güncelle</h6>

                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="lg:col-span-2 xl:col-span-12">
                                    <label htmlFor="bannerImages" className="inline-block mb-2 text-base font-medium">Banner Resimleri</label>
                                    <Dropzone
                                        onDrop={
                                            (files: any) => {
                                                onDrop({ files, wrapperSetCroppedImage, wrapperSetOpen });
                                            }}
                                        multiple={false}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dark:bg-zinc-700 dark:border-zinc-500 dropzone2">
                                                <input {...getInputProps()} />
                                                <div className="w-full py-5 text-lg text-center dz-message needsclick" {...getRootProps()}>
                                                    <div className="mb-3">
                                                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zinc-200 dark:fill-zinc-500" />
                                                    </div>
                                                    <h5 className="mb-0 font-normal text-slate-500 dark:text-zinc-200 text-15">
                                                        Resimleri buraya sürükleyip bırakın veya <Link to="#!">seçin</Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <CropperC
                                        image={croppedImage}
                                        open={open}
                                        setOpen={setOpen}
                                        onCropDone={() => onCropDone({ cropperRef, handleAcceptfiles, wrapperSetOpen })}
                                        cropperRef={cropperRef}
                                    />
                                    <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                        {selectfiles.map((file: any, index: any) => (
                                            <li className="mt-5" key={index} id="dropzone-preview-list2">
                                                <div className="border rounded border-slate-200 dark:border-zinc-500">
                                                    <div className="p-2 text-center">
                                                        <div>
                                                            <div className="p-2 mx-auto rounded-md size-24 bg-slate-100 dark:bg-zinc-600">
                                                                <img className="block w-full h-full rounded-md" src={file.priview} alt={file.name} />
                                                            </div>
                                                        </div>
                                                        <div className="pt-3">
                                                            <h5 className="mb-1 text-15" data-dz-name>{file.path}</h5>
                                                            <p className="mb-0 text-slate-500 dark:text-zinc-200" data-dz-size>{file.formattedSize}</p>
                                                            <strong className="error text-danger" data-dz-errormessage></strong>
                                                        </div>
                                                        <div className="mt-2">
                                                            <button
                                                                className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                                onClick={() => {
                                                                    const newImages = [...selectfiles];
                                                                    newImages.splice(index, 1);
                                                                    setSelectfiles(newImages);
                                                                    deleteBannerMutation.mutate(
                                                                        file.priview.split('/').pop()
                                                                    )
                                                                }}
                                                            >
                                                                Sil
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Sıfırla</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {
                                            'Banner Güncelle'
                                        }
                                    </button>
                                    {/* <button type="button" className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10">Draft & Preview</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div >
        </React.Fragment >
    );
};

export default BannerManage;