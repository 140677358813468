import { useMutation } from "@tanstack/react-query";
import { Modal } from "flowbite-react";
import { APIClient } from "helpers/api_helper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SortAsc, SortDesc } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = new APIClient();

const UserControl = (props: any) => {
  const navigate = useNavigate();
  const [showTransactions, setShowTransactions] = useState<any>(false);
  const [search, setSearch] = useState<any>("");
  const [user, setUser] = useState<any>({});
  const [users, setUsers] = useState<any>([]);
  const [originalUsers, setOriginalUsers] = useState<any>([]);
  const [userTransactions, setUserTransactions] = useState<any>([]);
  const [sortKey, setSortKey] = useState<string>("createdAt"); // State for sorting key
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc"); // State for sorting direction

  const addWalletTotMoneyMutation = useMutation({
    mutationFn: (data: any) => {
      return api.put("/wallet/add-money", {
        owner: data.owner,
        amount: data.amount,
      });
    },
    onSuccess: (data) => {
      console.log("Para başarıyla yüklendi");
      getUsersMutation.mutate();
      setTimeout(() => {
        toast.success("Para başarıyla yüklendi");
      }, 100);
    },
    onError: (error) => {
      console.log("Para yükleme başarısız", error);
      setTimeout(() => {
        toast.error("Para yükleme başarısız");
      }, 100);
    },
  });

  const substractMoneyMutation = useMutation({
    mutationFn: (data: any) => {
      console.log("Para çıkarma işlemi", data);
      return api.put("/wallet/subtract-money", {
        owner: data.owner,
        amount: data.amount,
      });
    },
    onSuccess: (data) => {
      console.log("Para başarıyla çıkarıldı"); 
      getUsersMutation.mutate();
      setTimeout(() => {
        toast.success("Para başarıyla çıkarıldı");
      }, 100);
    },
    onError: (error) => {
      console.log("Para çıkarma başarısız", error);
      setTimeout(() => {
        toast.error("Para çıkarma başarısız"); 
      }, 100); 
    },
  });

  const getUsersMutation = useMutation({
    mutationFn: () => {
      return api.get("/user/all", null);
    },
    onSuccess: (data) => {
      console.log("Kullanıcılar başarıyla yüklendi", data);
      setUsers(data);
      setOriginalUsers(data);
    },
    onError: (error) => {
      console.log("Kullanıcılar yüklenemedi", error);
    },
  });

  const createWalletTransactionMutation = useMutation({
    mutationFn: (data: any) =>
      api.create("/wallet-transactions/create", {
        owner: data.id,
        amount: data.balance,
        type: data.type,
      }),
    onSuccess: (data) => {
      console.log("İşlem başarıyla oluşturuldu", data);
      setTimeout(() => {
        toast.success("İşlem başarıyla oluşturuldu"); // Success message
      }, 100); // Show the message with a slight delay
    },
    onError: (error) => {
      console.log("İşlem oluşturulamadı", error);
      setTimeout(() => {
        toast.error("İşlem oluşturulamadı"); // Error message
      }, 100); // Show the message with a slight delay
    },
  });

  const getUserTransactionsMutation = useMutation({
    mutationFn: (id) => {
      return api.get(`/wallet/get-user-transactions/${id}`, null);
    },
    onSuccess: (data) => {
      console.log("Kullanıcı işlemleri başarıyla yüklendi", data);
      setUserTransactions(data);
    },
    onError: (error) => {
      console.log("Kullanıcı işlemleri yüklenemedi", error);
    },
  });

  useEffect(() => {
    getUsersMutation.mutate();
  }, []);

  if (getUsersMutation.isPending) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const handleSort = (key: string) => {
    setSortKey(key);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
    const sortedUsers = [...users].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setUsers(sortedUsers);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={showTransactions}
        onClose={() => {
          setShowTransactions(false);
          setUserTransactions([]);
        }}
      >
        <Modal.Header>
          <h2 className="text-2xl font-bold text-black">Kullanıcı İşlemleri</h2>
        </Modal.Header>
        <Modal.Body>
          {userTransactions.length === 0 && (
            <p className="text-lg font-semibold">
              Kullanıcının henüz işlemi bulunmamaktadır.
            </p>
          )}
          {userTransactions.map((transaction: any) => (
            <div
              key={transaction._id}
              className="mb-6 p-4 border rounded-lg bg-gray-100 shadow-sm"
            >
              <h3 className="text-xl font-semibold mb-2">
                İşlem ID: {transaction._id}
              </h3>
              <p>
                <span className="font-medium">Kullanıcı E-posta:</span>{" "}
                {transaction.user.email}
              </p>
              <p>
                <span className="font-medium">Kullanıcı Adı:</span>{" "}
                {transaction.user.name} {transaction.user.lastname}
              </p>
              <p>
                <span className="font-medium">Telefon Numarası:</span>{" "}
                {transaction.user.phoneNumber}
              </p>
              <p>
                <span className="font-medium">İşlem Oluşturma Tarihi:</span>{" "}
                {new Date(transaction.createdAt).toLocaleString()}
              </p>
              <p>
                <span className="font-medium">İşlem Güncelleme Tarihi:</span>{" "}
                {new Date(transaction.updatedAt).toLocaleString()}
              </p>
              <h4 className="text-lg font-medium mt-4">
                Satın Alınan Ürünler:
              </h4>
              {transaction.boughtItems.map((item: any) => (
                <div
                  key={item._id}
                  className="ml-6 mt-2 p-2 border-l-4 border-blue-500 bg-white rounded shadow-sm"
                >
                  <p>
                    <span className="font-medium">Ürün Başlığı:</span>{" "}
                    {item.productTitle}
                  </p>
                  <p>
                    <span className="font-medium">Ürün Açıklaması:</span>{" "}
                    {item.productDescription}
                  </p>
                  <p>
                    <span className="font-medium">Kategori:</span>{" "}
                    {item.category}
                  </p>
                  <p>
                    <span className="font-medium">Barkod Numarası:</span>{" "}
                    {item.barcodeNumber}
                  </p>
                  <p>
                    <span className="font-medium">Alış Fiyatı:</span>{" "}
                    {item.buyingPrice} ₺
                  </p>
                  <p>
                    <span className="font-medium">Satış Fiyatı:</span>{" "}
                    {item.sellingPrice} ₺
                  </p>
                  <p>
                    <span className="font-medium">Miktar:</span> {item.quantity}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </Modal.Body>
      </Modal>
      <div className="min-h-screen p-8 bg-[#f1f2f9] w-full">
        <div className="mb-8">
          <h1 className="text-2xl font-bold mb-4">Üye Kontrol</h1>
          <input
            type="text"
            placeholder="Kullanıcı Ara..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value.trim() === "") {
                setUsers(originalUsers);
              } else {
                setUsers(
                  originalUsers.filter(
                    (user: any) =>
                      user.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                      user?.phoneNumber?.includes(e.target.value)
                  )
                );
              }
            }}
            className="w-full h-12 rounded-lg border-2 border-gray-300 p-4"
          />
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Ad
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Soyad
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Email
                </th>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                  onClick={() => handleSort("createdAt")}
                >
                  Katılma Tarihi
                  {sortKey === "createdAt" &&
                    (sortDirection === "asc" ? <SortAsc /> : <SortDesc />)}
                </th>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                  onClick={() => handleSort("wallet.balance")}
                >
                  Bakiye
                  {sortKey === "wallet.balance" &&
                    (sortDirection === "asc" ? <SortAsc /> : <SortDesc />)}
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Bakiye İşlemi
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user: any, index: number) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-3 text-sm">
                    {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {user.lastname.charAt(0).toUpperCase() +
                      user.lastname.slice(1)}
                  </td>
                  <td className="px-4 py-3 text-sm">{user.email}</td>
                  <td className="px-4 py-3 text-sm">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-3 text-sm text-blue-500 cursor-pointer">
                    {user.wallet.balance} ₺
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <div className="flex items-center">
                      <input
                        type="number"
                        placeholder="Tutar Gir"
                        value={user.balance || ""}
                        onChange={(e) => {
                          const newBalance = Number(e.target.value);
                          setUsers((prevUsers: any) =>
                            prevUsers.map((u: any) =>
                              u._id === user._id
                                ? { ...u, balance: newBalance }
                                : u
                            )
                          );
                        }}
                        className="w-24 h-10 rounded-lg border-2 border-gray-300 p-2 mr-2"
                      />
                      <button
                        onClick={() => {
                          addWalletTotMoneyMutation.mutate({
                            owner: user._id,
                            amount: Number(user.balance),
                          });

                          createWalletTransactionMutation.mutate({
                            id: user._id,
                            balance: Number(user.balance),
                            type: "add",
                          });
                        }}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                      >
                        Yükle
                      </button>
                      <button
                        onClick={() => {
                          substractMoneyMutation.mutate({
                            owner: user._id,
                            amount: Number(user.balance),
                          });
                        }}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                      >
                        Çıkar
                      </button>
                      <button
                        onClick={() => {
                          navigate(`/user-control/detail/${user._id}`);
                        }}
                        className="bg-[#F1F5FA] text-[#5E5E5E] ml-2 px-4 py-2 rounded-lg"
                      >
                        İşlemler
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UserControl;
