import React from "react";
import { useFormik as useFormic } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withRouter from "Common/withRouter";
import { createSelector } from 'reselect';
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import logoSm2 from "assets/images/logo-sm-2.png";
import { toast } from "react-toastify";

const ForgotPassword = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const selectLogin = createSelector(
        (state: any) => state.Register,
        (state: any) => state.Login,
        (register, login) => ({
            user: register.user,
            success: login.success,
            error: login.error
        })
    );

    const location = useLocation();
    const getTokenFromLink = location.search.split('token=')[1];

    const { user, success, error } = useSelector(selectLogin);

    const validation = useFormic({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },
        // validationSchema: Yup.object({
        //     email: Yup.string().email('Geçerli bir e-posta girin').when('getTokenFromLink', {
        //         is: false,
        //         then: Yup.string().required('Lütfen mail giriniz.'),
        //     }),
        //     password: Yup.string().when('getTokenFromLink', {
        //         is: true,
        //         then: Yup.string().min(6, 'Şifre en az 6 karakter olmalıdır').required('Lütfen şifrenizi giriniz.'),
        //     }),
        //     confirmPassword: Yup.string().when('getTokenFromLink', {
        //         is: true,
        //         then: Yup.string().oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor').required('Lütfen şifrenizi onaylayınız.'),
        //     }),
        // }),
        onSubmit: async (values) => {
            if (getTokenFromLink) {
                try {
                    await axios.post('/auth/reset-password-by-token', { token: getTokenFromLink, password: values.password, confirmPassword: values.confirmPassword });
                    validation.resetForm();
                    toast.success(
                        'Şifreniz başarıyla sıfırlandı. Lütfen giriş yapınız.'
                    );
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
                } catch (error) {
                    console.error('Error resetting password', error);
                }
            } else {
                try {
                    await axios.post('/auth/forgot-password-admin', { email: values.email });
                    toast.success('Mail başarıyla gönderildi');
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } catch (error) {
                    console.error('Error sending email', error);
                }
            }
        },
    });

    React.useEffect(() => {
        const bodyElement = document.body;
        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        };
    }, []);

    return (
        <React.Fragment>
            <div className="relative">
                <AuthIcon />
                <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            <img src={logoSm2} alt="" className="hidden h-24 mx-auto dark:block" />
                            <img src={logoSm2} alt="" className="block h-24 mx-auto dark:hidden" />
                        </Link>
                        <form className="mt-10" id="signInForm"
                            onSubmit={(event) => {
                                event.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            {success && (
                                <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50" id="successAlert">
                                    Mail Başarıyla Gönderildi
                                </div>
                            )}
                            {error && (
                                <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50" id="errorAlert">
                                    Mail Gönderilemedi. Lütfen Tekrar Deneyiniz
                                </div>
                            )}
                            {!getTokenFromLink ? (
                                <div className="mb-3">
                                    <label htmlFor="email" className="inline-block mb-2 text-base font-medium">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        placeholder="Lütfen mail giriniz."
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email}
                                    />
                                    {validation.touched.email && validation.errors.email && (
                                        <div id="email-error" className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="inline-block mb-2 text-base font-medium">
                                            Yeni Şifre
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Yeni şifrenizi giriniz."
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.password}
                                        />
                                        {validation.touched.password && validation.errors.password && (
                                            <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="inline-block mb-2 text-base font-medium">
                                            Şifreyi Onaylayın
                                        </label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Şifrenizi onaylayınız."
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.confirmPassword}
                                        />
                                        {validation.touched.confirmPassword && validation.errors.confirmPassword && (
                                            <div id="confirmPassword-error" className="mt-1 text-sm text-red-500">{validation.errors.confirmPassword}</div>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className="mt-10">
                                <button
                                    type="submit"
                                    className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                    disabled={
                                        (!getTokenFromLink && !validation.values.email) ||
                                        (getTokenFromLink && (!validation.values.password || !validation.values.confirmPassword)) || undefined
                                    }
                                >
                                    {getTokenFromLink ? 'Şifreyi Sıfırla' : 'Email Gönder'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgotPassword);
