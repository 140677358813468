import React, { useEffect, useState } from "react";
import {
  Archive,
  Minus,
  Plus,
  XCircle,
  Box,
  ArrowRight,
  ArrowLeft,
} from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import CashRegisterModal from "./Components/CashRegisterModal";
import axios from "axios";
import { toast } from "react-toastify";

const api = new APIClient();

const CashRegisterPage: React.FC = () => {
  const pathSearch = window.location.search;
  const [cashRegister, setCashRegister] = useState<any>({});
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [checkTill, setCheckTill] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [formState, setFormState] = useState<any[]>([]);
  const [description, setDescription] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");
  const [amount, setAmount] = useState<number | null>(null); // Default değeri null yaptık
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("tr-TR", options).replace(",", "");
  };

  const resetFormState = () => {
    setFormState((prev) =>
      prev.map((item) => ({
        ...item,
        amount: null,
      }))
    );
  };

  const getCashRegisterMutation = useMutation({
    mutationFn: () => api.get("/cash-register/open", null),
    onSuccess: (data) => {
      setCashRegister(data);
      setCheckTill(false);
    },
    onError: (error) => {
      setCheckTill(true);
    },
  });

  const addAmountMutation = useMutation({
    mutationFn: (data: any) =>
      api.update(`/cash-register/${cashRegister._id}/add-funds`, data),
    onSuccess: (data) => {
      setCashRegister(data);
      setAmount(null);
      setDescription("");
      setSelectedPaymentMethod("");
      setIsModalOpen3(false);
      resetFormState();
      toast.success(
        `Para ${!isWithdraw ? "Ekleme" : "Çıkartma"} işlemi başarılı.`
      );
    },
    onError: (error) => {
      toast.error("İşlem Başarısız Gerekli Alanları Doldurun");
    },
  });

  const getPaymentMethodsMutation = useMutation({
    mutationFn: () => axios.get("/payment-methods"),
    onSuccess: (data: any) => {
      setPaymentMethods(data);

      const initialState = data.map((method: any) => ({
        paymentMethod: method.paymentMethod,
        amount: null, // Default değeri null yaptık
      }));
      setFormState(initialState);
    },
    onError: (error) => {},
  });

  const submitFormMutation = useMutation({
    mutationFn: (formData: any) => api.create("/cash-register", formData),
    onSuccess: (data) => {
      setCheckTill(false);
      setCashRegister(data);
      setIsModalOpen1(false);
      resetFormState();
      toast.success("Kasa Açma İşlemi Başarılı");
    },
    onError: (error) => {
      toast.error("İşlem Başarısız Gerekli Alanları Doldurun");
    },
  });

  const submitFormMutation2 = useMutation({
    mutationFn: (formData: any) =>
      api.create(`/cash-register/${cashRegister._id}/close`, formData),
    onSuccess: (data) => {
      setCheckTill(true);
      setCashRegister({});
      setIsModalOpen4(false);
      resetFormState();
      setStep(0);
      setDescription("");
      toast.success("Kasa Kapatma İşlemi Başarılı");
    },
    onError: (error) => {
      toast.error("Kasa Kapatılırken Bir Hata Oluştu");
    },
  });

  useEffect(() => {
    getCashRegisterMutation.mutate();
    getPaymentMethodsMutation.mutate();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newFormState = [...formState];
    newFormState[index].amount = value === "" ? null : Number(value); // Boş olduğunda null yap
    setFormState(newFormState);
  };

  const handleAddAmountSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      paymentMethod: selectedPaymentMethod,
      amount: isWithdraw ? -amount! : amount!, // Null check ekledik
      description: description,
    };
    addAmountMutation.mutate(data);
  };

  const handleCreatedByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreatedBy(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      createdBy,
      openingValues: formState,
    };
    submitFormMutation.mutate(formData);
  };

  const handleAmountChange = (index: number, amount: number | null) => {
    setFormState((prev) => {
      const newState = [...prev];
      newState[index].amount = amount;
      return newState;
    });
    setError("");
  };
  const handleNextStep = () => {
    if (!formState[step]?.amount) {
      setError("Lütfen geçerli bir tutar girin"); // Hata mesajını ayarlıyoruz
    } else {
      setStep(step + 1);
      setError(""); // Adım geçilirse hata sıfırlanır
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
    setError(""); // Adım değiştirildiğinde hata sıfırlanır
  };

  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
    const closeCashRegisterDto = {
      closingValues: formState,
      description,
    };
    submitFormMutation2.mutate(closeCashRegisterDto);
  };

  useEffect(() => {
    console.log(pathSearch);
    if (pathSearch === "?open=true") setIsModalOpen1(true);
  }, [pathSearch]);

  return (
    <React.Fragment>
      <div className="relative bg-white p-6 rounded-lg shadow-lg text-center mt-5">
        {checkTill ? (
          <button
            onClick={() => setIsModalOpen1(true)}
            className="absolute top-0 right-0 m-4 bg-blue-500 text-white p-2 rounded-lg shadow-md hover:shadow-lg flex items-center"
          >
            <Box className="mr-2" />
            Kasa Aç
          </button>
        ) : null}
        <h1 className="text-2xl font-bold mb-8">Kasa İşlemleri</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <button
            onClick={() => setIsModalOpen2(true)}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg text-black font-bold flex flex-col items-center"
          >
            <Archive className="mb-2" />
            Kasa Durumu
          </button>
          <button
            onClick={() => {
              setIsWithdraw(false);
              setIsModalOpen3(true);
            }}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg text-black font-bold flex flex-col items-center"
          >
            <Plus className="mb-2" />
            Para Ekle
          </button>
          <button
            onClick={() => {
              setIsWithdraw(true);
              setIsModalOpen3(true);
            }}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg text-black font-bold flex flex-col items-center"
          >
            <Minus className="mb-2" />
            Para Çıkar
          </button>
        </div>
        <div className="mt-4">
          <button
            onClick={() => setIsModalOpen4(true)}
            className="bg-white w-1/3 p-4 rounded-lg shadow-md hover:shadow-lg text-black font-bold flex flex-col items-center mx-auto"
          >
            <XCircle className="mb-2" />
            Kasayı Kapat
          </button>
        </div>
      </div>

      <CashRegisterModal
        isVisible={isModalOpen1}
        onClose={() => setIsModalOpen1(false)}
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Kasayı Açan Kişi
            </label>
            <input
              type="text"
              value={createdBy}
              onChange={handleCreatedByChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Kasayı Açan Kişi"
              required
            />
          </div>
          {paymentMethods.length > 0 ? (
            paymentMethods.map((method: any, index: number) => (
              <div key={index} className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {method.paymentMethod}
                </label>
                <input
                  type="number"
                  value={formState[index]?.amount ?? ""} // Boş olduğunda "" yap
                  onChange={(e) => handleChange(index, e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={`${method.paymentMethod} Tutarı Girin`}
                  required
                />
              </div>
            ))
          ) : (
            <div>Miktarlar yükleniyor... </div>
          )}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Kasa Aç
            </button>
          </div>
        </form>
      </CashRegisterModal>

      <CashRegisterModal
        isVisible={isModalOpen2}
        onClose={() => setIsModalOpen2(false)}
      >
        {!checkTill && cashRegister ? (
          <div className="flex flex-col items-center mt-5 space-y-6">
            <div className="mt-4 text-center">
              <h1 className="text-2xl font-bold">Kasa Durumu</h1>
              <div className="mt-4 space-y-2">
                <p>
                  <strong>Kasayı Açan:</strong> {cashRegister.createdBy}
                </p>
                <p>
                  <strong>Tarih:</strong> {formatDate(cashRegister.createdAt)}
                </p>
              </div>
            </div>
            <div className="overflow-x-auto mt-6 w-full">
              <table className="min-w-full divide-y divide-gray-200 bg-white rounded-lg shadow-md">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ödeme Türü
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Kasa Açılış Tutarı
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Olması Gereken
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {cashRegister.openingValues?.map(
                    (openingValue: any, index: number) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {openingValue.paymentMethod}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {openingValue.amount} TL
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {
                            cashRegister.expectedValues?.find(
                              (expectedValue: any) =>
                                expectedValue.paymentMethod ===
                                openingValue.paymentMethod
                            )?.amount
                          }{" "}
                          TL
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="text-center text-2xl">Kasa Kapalı</p>
        )}
      </CashRegisterModal>

      <CashRegisterModal
        isVisible={isModalOpen3}
        onClose={() => setIsModalOpen3(false)}
      >
        {!checkTill ? (
          <div className="flex flex-col w-full">
            <div className="mb-4">
              <h1 className="text-2xl font-bold">{`Para ${
                !isWithdraw ? "Ekleme" : "Çıkartma"
              } İşlemi`}</h1>
            </div>
            <form onSubmit={handleAddAmountSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Ödeme Yöntemi
                </label>
                <select
                  required
                  value={selectedPaymentMethod}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="" disabled>
                    Ödeme Yöntemi Seçin
                  </option>
                  {paymentMethods.map((method: any, index: number) => (
                    <option key={index} value={method.paymentMethod}>
                      {method.paymentMethod}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Tutar
                </label>
                <input
                  type="number"
                  value={amount ?? ""} // Boş olduğunda "" yap
                  onChange={(e) =>
                    setAmount(
                      e.target.value === "" ? null : Number(e.target.value)
                    )
                  } // Boş olduğunda null yap
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Eklenecek Tutar"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Açıklama
                </label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Açıklama"
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className={`${
                    !isWithdraw
                      ? "bg-green-500 hover:bg-green-700"
                      : "bg-red-500 hover:bg-red-700"
                  }  w-1/3 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                >
                  {`Para ${!isWithdraw ? "Ekle" : "Çıkart"}`}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <p className="text-center text-2xl">Kasa Kapalı</p>
        )}
      </CashRegisterModal>
      <CashRegisterModal
        isVisible={isModalOpen4}
        onClose={() => setIsModalOpen4(false)}
      >
        {!checkTill ? (
          <>
            <h2 className="text-center text-2xl font-bold text-gray-700 mb-4">
              Kasa Kapat
            </h2>
            <div className="text-center text-lg text-gray-500 mb-4">
              Sayılan Tutar
            </div>
            <div className="flex justify-center items-center mb-4">
              {formState.map((item: any, index: number) => (
                <button
                  key={index}
                  onClick={() => setStep(index)}
                  className="flex items-center text-lg"
                >
                  <p
                    className={`inline-block mx-2 w-6 h-6 ${
                      step >= index ? "bg-[#689F38]" : "bg-[#707070]"
                    } rounded-full text-md text-white flex items-center justify-center`}
                  >
                    {index + 1}
                  </p>
                  {item.paymentMethod}
                </button>
              ))}
            </div>
            <hr className="border-t border-gray-300 w-full mb-4" />

            <form className=" w-full mx-auto">
              <div className="mb-4">
                <label
                  htmlFor={`amount-${step}`}
                  className="block text-gray-600 text-lg text-center mb-2"
                >
                  Tutar
                </label>
                {formState[step] && (
                  <input
                    type="number"
                    id={`amount-${step}`}
                    name={`amount-${step}`}
                    value={formState[step]?.amount ?? ""}
                    onChange={
                      (e) =>
                        handleAmountChange(
                          step,
                          e.target.value === "" ? null : Number(e.target.value)
                        ) // Boş olduğunda null yap
                    }
                    className="w-full text-center text-2xl font-bold text-gray-800 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="00.0"
                    required
                  />
                )}
                {error && (
                  <p className="text-red-500 text-sm text-center">{error}</p>
                )}{" "}
                {/* Hata mesajı */}
              </div>
              <div
                className={`${
                  step === formState.length - 1 ? "" : "hidden"
                } mb-4`}
              >
                <label
                  htmlFor="description"
                  className="block text-gray-600 mb-2"
                >
                  Açıklama
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Açıklama"
                />
              </div>
            </form>
            <hr className="border-gray-300 w-full my-5" />
            <div className="flex justify-center">
              <div className="w-full flex flex-col items-center">
                {formState[step] && (
                  <>
                    <div className="w-full flex justify-between text-lg text-gray-900 mb-2">
                      <span>
                        Kasada Olması Gereken{" "}
                        <b>{formState[step].paymentMethod}</b> :
                      </span>
                      <span className="ml-8">
                        {cashRegister.expectedValues?.[step]?.amount ?? 0}₺
                      </span>
                    </div>
                    <div className="w-full flex justify-between text-lg text-gray-900 mb-2">
                      <span>Aradaki Fark :</span>
                      <span className="ml-8">
                        {formState[step].amount -
                          (cashRegister.expectedValues?.[step]?.amount ?? 0)}
                        ₺
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr className="border-t border-gray-300 w-full mb-5" />
            <div className="flex items-center justify-center gap-5">
              <button
                type="button"
                className={`${
                  step === 0 ? "hidden" : ""
                } flex items-center justify-center space-x-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md w-32 h-12 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800`}
                onClick={handlePrevStep}
              >
                <ArrowLeft />
                <span>Önceki</span>
              </button>
              <button
                onClick={handleNextStep}
                type="button"
                className={`${
                  step === formState.length - 1 ? "hidden" : ""
                } flex items-center justify-center space-x-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md w-32 h-12 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800`}
              >
                <span>Sonraki</span>
                <ArrowRight />
              </button>
              <button
                onClick={handleSubmit2}
                type="button"
                className={`${
                  step === formState.length - 1 ? "" : "hidden"
                } focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-md w-32 h-12 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900`}
              >
                Kasayı Kapat
              </button>
            </div>
          </>
        ) : (
          <p className="text-center text-2xl">Kasa Kapalı</p>
        )}
      </CashRegisterModal>
    </React.Fragment>
  );
};

export default CashRegisterPage;
