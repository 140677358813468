import { useMutation, useQueryClient } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React from 'react';
import { Bounce, toast } from 'react-toastify';
import { debounce } from 'lodash';

type Props = {
    item: {
        _id: string;
        productTitle: string;
        productDescription: string;
        category: string;
        barcodeNumber: string;
        kdv: string;
        buyingPrice: string;
        sellingPrice: string;
        productImage: string;
        productNutritions: string;
        stock:number
    },
    tableId: any;
    setSingleTable: any;
    singleTable: any;
}

const url = 'https://api.macfitcafe.com';
const api = new APIClient();

const MenuListItem = (props: Props) => {
    const queryClient = useQueryClient();

    const addItemToTableMutation = useMutation({
        mutationFn: () => {
            const existingItemIndex = props.singleTable.tableItems.findIndex((item: any) => item._id === props.item._id);
            const stockQuantity = props.item.stock; // Ürünün stok bilgisini kontrol et
            if (stockQuantity === 0) {
                toast('Stok yetersiz', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return Promise.reject(new Error('Stok yetersiz'));
            }
            
            if (existingItemIndex !== -1) {
                const currentQuantity = props.singleTable.tableItems[existingItemIndex].quantity;
                const stockQuantity = props.singleTable.tableItems[existingItemIndex].stock;
                
                if (currentQuantity >= stockQuantity) {
                    toast('Stok yetersiz', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    return Promise.reject(new Error('Stok yetersiz')); 
                }
                return api.put('tables/update-table-increase', {
                    tableId: props.tableId,
                    itemId: props.item._id,
                    quantity: currentQuantity + 1
                });
            } else {
                props.setSingleTable({ ...props.singleTable, tableItems: [...props.singleTable.tableItems, { ...props.item, quantity: 1 }] });
                return api.put('tables/add-item', {
                    tableId: props.tableId,
                    itemId: props.item._id
                });
            }
        },
        onMutate: async () => {
            await queryClient.cancelQueries(['table', props.tableId] as any);
    
            const previousTable = queryClient.getQueryData(['table', props.tableId] as any);
    
            queryClient.setQueryData(['table', props.tableId] as any, (old: any) => {
                if (!old) return { tableItems: [] };
    
                const existingItemIndex = old.tableItems.findIndex((item: any) => item._id === props.item._id);
    
                if (existingItemIndex !== -1) {
                    const updatedItems = [...old.tableItems];
                    const currentQuantity = updatedItems[existingItemIndex].quantity;
    
                    updatedItems[existingItemIndex].quantity += 1;
                    return { ...old, tableItems: updatedItems };
                } else {
                    return { ...old, tableItems: [...old.tableItems, { ...props.item, quantity: 1 }] };
                }
            });
    
            return { previousTable };
        },
        onError: (err, newTodo, context: any) => {
            toast(err.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
    
            if (context?.previousTable) {
                queryClient.setQueryData(['table', props.tableId] as any, context.previousTable);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['table', props.tableId] as any);
        },
        onSuccess: (data: any) => {
            props.setSingleTable(data);
        }
    });
    
    

    const debouncedMutation = debounce(() => {
        addItemToTableMutation.mutate();
    }, 100);

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.style.display = 'none';
        const placeholder: any = event.currentTarget.nextElementSibling;
        if (placeholder) {
            placeholder.style.display = 'flex';
        }
    };
    const handleOnLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.style.display = 'block';
        const placeholder: any = event.currentTarget.nextElementSibling;
        if (placeholder) {
            placeholder.style.display = 'none';
        }
    }

    return (
        <div
            onClick={props.item.stock > 0 ? debouncedMutation : undefined}
            className={
                `relative flex flex-col rounded-lg shadow-lg bg-[#F6F6F6] w-50 h-64 items-center col-span-3 xl:col-span-3 sm:col-span-4 m-2 p-2 ${props.item.stock <= 0 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:bg-gray-200'}`
            }
        >
            <img
                src={`${url}/upload-file/product/${props?.item?.productImage}`}
                alt={props.item.productTitle}
                className='h-40 rounded-lg mt-2 object-contain'
                onError={handleImageError}
                onLoad={handleOnLoad}
                loading='lazy'
            />
    
            <div className="w-full h-40 flex items-center justify-center bg-gray-200 rounded-lg mt-2" style={{ display: 'none' }}>
                <span className='font-bold text-md text-center'>
                    {props.item.productTitle}
                </span>
            </div>
    
            <span className='font-bold text-md text-center mt-2'>
                {props.item.productTitle}
            </span>
    
            <span className='text-lg font-bold text-black mt-1'>
                {props.item.sellingPrice} ₺
            </span>
    
            {props.item.stock <= 0 && ( // Stok 0 veya negatifse "Stok yok" yazısı gösteriliyor
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                    <span className="text-white font-bold text-lg">
                        Stok yok
                    </span>
                </div>
            )}
        </div>
    );
    
}

export default MenuListItem;
