import React from "react";
import { Link } from "react-router-dom";

type Props = {
  adisyon: {
    tableName: string;
    tableItems: {
      name: string;
      price: number;
    }[];
    lastUpdated: string;
  };
};

const AdisyonListItem = (props: any) => {
    const {adisyon}=props;
    const calculateTotal = () => {
        return adisyon.tableItems.reduce((acc:any, item:any) => {
            return acc + (parseFloat(item.sellingPrice) * item.quantity);
        }, 0).toFixed(2);
    };
  return (
    props?.adisyon?.tableItems?.length !== 0 && (
      <>
      <Link to={`/terminal-table-edit/${props.adisyon._id}`} >
                        
        <div
          className="flex flex-row px-4 py-4 hover:bg-gray-200 cursor-pointer
             transition duration-300 ease-in-out
            items-center justify-between border-b border-gray-200"
        >
          <div>
            <span className="text-xl text-black font-bold mt-1 r hover:text-gray-800">
              {props.adisyon.tableName}. Masa
            </span>
          </div>

          <div>
            <span className="text-xl text-black font-medium mt-1 r hover:text-gray-800">
              {props.adisyon.lastUpdated}
            </span>
          </div>

          <div>
            <span className="text-xl text-black  mt-1 r hover:text-gray-800">
              {calculateTotal()} TL
            </span>
          </div>
        </div>
        </Link>
      </>
    )
  );
};

export default AdisyonListItem;
