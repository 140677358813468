import React, { useEffect } from "react";
import { Modal, Select } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import { LogOut } from "lucide-react";
import userProfile from "assets/images/users/user-profile.png";
import { Bounce, toast } from "react-toastify";
import { Trash } from "lucide-react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { Dropdown } from "Common/Components/Dropdown";
import Move from "assets/images/move.png";
import Divide from "assets/images/divide.png";
import Discount from "assets/images/discount.png";
import MenuListItem from "./SaloonEdit/MenuListItem";
import LeftMenuItem from "./SaloonEdit/LeftMenuItem";
import ArrowLeft from "assets/images/arrow-left.png";
import logo from "assets/images/logo-sm-2.png";
import Card from "assets/images/card.png";

import Swal from "sweetalert2";

const api = new APIClient();



const url = 'https://api.macfitcafe.com';
const local = 'http://localhost:3003';

const TerminalTableEdit = (props: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tables, setTables] = React.useState<any>([]);
  const [pageIsLoading, setPageIsLoading] = React.useState(false);
  const [discountMethod, setDiscountMethod] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [divideUser, setDivideUser] = React.useState(0);
  const [discountPercentage, setDiscountPercentage] = React.useState(0);
  const [showDividedTotal, setShowDividedTotal] = React.useState(false);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [checkNote, setCheckNote] = React.useState("");
  const [showDivideModal, setShowDivideModal] = React.useState(false);
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [showCheckNoteModal, setShowCheckNoteModal] = React.useState(false);
  const [showMoveTableModal, setMoveTableModal] = React.useState(false);
  const [originalUsers, setOriginalUsers] = React.useState([]);
  const [originalMenuItems, setOriginalMenuItems] = React.useState([]);
  const [checkUser, setCheckUser] = React.useState({} as any);
  const [singleTable, setSingleTable] = React.useState<any>({});
  const [users, setUsers] = React.useState<any>([]);
  const [menuCategories, setMenuCategories] = React.useState([] as any);
  const [selectedCategory, setSelectedCategory] = React.useState<any>("Hepsi"); // New state for selected category
  const [menuItems, setMenuItems] = React.useState([] as any);
  const [fetchLoading, setFetchLoading] = React.useState<boolean>(false);

  const getUser = localStorage.getItem('authUser');

  const user = getUser ? JSON.parse(getUser) : null;

  const goBack = () => {
    navigate(-1);
  };

  const LeftMenuData = [
    {
      img: Move,
      text: "Taşı",
      onClick: () => {
        setMoveTableModal(true);
      },
    },
    {
      img: Divide,
      text: "Böl",
      onClick: () => {
        setShowDivideModal(true);
      },
    },
    {
      img: Discount,
      text: "İskonto",
      onClick: () => {
        setShowDiscountModal(true);
      },
    },
  ];

  const fetchUsers = () => {
    return api.get("/user/terminal-users", null);
  };

  const getUsersMutation = useMutation({
    mutationFn: fetchUsers,
    onSuccess: (data: any) => {
      setUsers(data);
      setOriginalUsers(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const getSingleTableMutation = useMutation({
    mutationFn: () => {
      return api.get(`tables/get-table-id/${id}`, null);
    },
    onSuccess: (data) => {
      setSingleTable(data);
      console.log('data', data);

      getAllTablesMutation.mutate();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getPaymentMethodsMutation = useMutation({
    mutationFn: () => api.get("/payment-methods/list", null),
    onSuccess: (data: any) => {
      setPaymentMethods(data);
      setPaymentMethod(data[0]?.paymentMethod || "");
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const assignCheckToUserMutation = useMutation({
    mutationFn: async (userId) => {
      setFetchLoading(true);
      return await api.create(`wallet/user-transactions`, {
        user: userId,
        boughtItems: singleTable.tableItems,
        tableId: id,
        paymentMethod: paymentMethod,
        checkNote: checkNote,
        discounted: hasDiscount(),
        tableTotal: calculateTotal(
          singleTable.tableItems,
          divideUser,
          discountPercentage,
          discountAmount,
          discountMethod
        ),
      });
    },

    onSuccess: async (data) => {
      Swal.fire({
        title: 'Ödeme Alındı',
        showDenyButton: false,
      });


      setCheckUser({} as any)

      setCheckNote('')

      setPaymentMethod('')
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setDiscountMethod('');
      await getSingleTableMutation.mutate();


      await getUsersMutation.mutate()

      // setTimeout(() => {
      //   navigate(0);
      // }, 1000);


    },
    onError: (error) => {
      toast.error("Bakiye yetersiz!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });
  const getAllMenuMutation = useMutation({
    mutationFn: () => {
      return api.get("/menu/all", null);
    },
    onSuccess: (data: any) => {
      setMenuItems(data);
      setOriginalMenuItems(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const getAllCategoriesMutation = useMutation({
    mutationFn: async () => {
      return api.get("/categories/all", null);
    },
    onSuccess: (data: any) => {
      setMenuCategories((prev: any) => {
        return [
          { categoryName: "Hepsi" },
          ...data.filter((prev: any) => prev.categoryName !== "Hepsi"),
        ];
      });
    },
  });

  const moveTableMutation = useMutation({
    mutationFn: async (tableId) => {
      return api.put(`/tables/move-table/${id}/${tableId}`, null);
    },
    onSuccess: (data) => {
      toast("Masa Taşındı !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setPageIsLoading(false);
          navigate("/terminal", { replace: true });
        },
        onOpen: () => {
          setPageIsLoading(true);
        },
      });
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const increaseItemQuantityMutation = useMutation({
    mutationFn: (itemId) => {
      return api.put(`tables/update-table-increase`, {
        tableId: id,
        itemId: itemId,
      });
    },
    onSuccess: (data) => {
      setSingleTable(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const decreaseItemQuantityMutation = useMutation({
    mutationFn: (itemId) => {
      return api.put(`tables/update-table-decrease`, {
        tableId: id,
        itemId: itemId,
      });
    },
    onSuccess: (data) => {
      setSingleTable(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const increaseQuantity = (index: any) => {
    const currentItem = singleTable.tableItems[index]; // Mevcut öğeyi al

    // Eğer quantity, stock'dan büyük veya eşitse hata fırlat
    if (currentItem.quantity >= currentItem.stock) {
      toast('Stok yetersiz', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    setSingleTable((prev: any) => {
      const updatedItems = [...prev.tableItems];
      updatedItems[index] = {
        ...updatedItems[index],
        quantity: (updatedItems[index].quantity || 1) + 1,
      };
      return { ...prev, tableItems: updatedItems };
    });

    increaseItemQuantityMutation.mutate(singleTable.tableItems[index]._id);
  };


  const decreaseQuantity = (index: any) => {
    setSingleTable((prev: any) => {
      const updatedItems = [...prev.tableItems];
      if (updatedItems[index].quantity > 1) {
        updatedItems[index] = {
          ...updatedItems[index],
          quantity: updatedItems[index].quantity - 1,
        };
      } else {
        removeTableItemMutation.mutate(singleTable.tableItems[index]._id);
      }
      return { ...prev, tableItems: updatedItems };
    });

    decreaseItemQuantityMutation.mutate(singleTable.tableItems[index]._id);
  };

  const removeTableItemMutation = useMutation({
    mutationFn: (itemId) => {
      setSingleTable((prev: any) => {
        const updatedItems = prev.tableItems.filter(
          (item: any) => item._id !== itemId
        );
        return { ...prev, tableItems: updatedItems };
      });
      return api.put(`/tables/delete-item-table/${id}/${itemId}`, {});
    },
    onSuccess: (data) => {
      setSingleTable(data);
      toast("Ürün Silindi", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const getAllTablesMutation = useMutation({
    mutationFn: () => {
      return api.get("/tables/all", null);
    },
    onSuccess: (data) => {
      setTables(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const createWalletTransactionMutation = useMutation({
    mutationFn: async (data: any) => {
      setFetchLoading(true)
      return await api.create("/wallet-transactions/create", {
        owner: data.id,
        amount: data.amount,
        type: data.type
      })
    },
    onSuccess: (data) => {
      console.log("data", data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const handleImageError = (event: any) => {
    event.currentTarget.style.display = "none";
    const placeholder: any = event.currentTarget.nextElementSibling;
    if (placeholder) {
      placeholder.style.display = "flex";
      placeholder.style.fontSize = "6px";
    }
  };

  const checkQrDataMutation = useMutation({
    mutationFn: (code) => {
      return api.get(`/qr-data?code=${code}`, null);
    },
    onSuccess: (data) => {
      console.log("userId", data);

      const user: any = originalUsers.find(
        (user: any) => user._id.toString() === data.toString()
      );
      console.log("user 1", user);

      if (user) {
        console.log("user", user);
        setCheckUser({
          value: user._id,
          label: `${user?.name.charAt(0).toUpperCase() +
            user?.name?.slice(1).toLowerCase()
            } ${user?.lastname?.charAt(0).toUpperCase() + user?.lastname?.slice(1)
            } (${user?.wallet?.balance?.toFixed(2)} ₺)`,
        });
        // Add the user to the users list if not already present
        if (!users.find((u: any) => u._id === user._id)) {
          setUsers([...users, user]);
        }
      }
    },
    onError: (error) => {
      toast("Kullanıcı Bulunamadı !", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    },
  });

  const checkPaymentMutation = useMutation({
    mutationFn: () => {
      if (!paymentMethod) {
        throw new Error('Ödeme yöntemi seçin.');
      }

      setFetchLoading(true);

      return api.create(`/transactions/create`, {
        tableId: id,
        tableItems: singleTable.tableItems,
        tableTotal: calculateTotal(
          singleTable.tableItems,
          divideUser,
          discountPercentage,
          discountAmount,
          discountMethod
        ),
        checkNote: checkNote,
        paymentMethod: paymentMethod,
        discounted: hasDiscount(),
      });
    },
    onSuccess: async (data) => {
      Swal.fire({
        title: 'Ödeme Alındı',
        showDenyButton: false,
      });

      setCheckUser({} as any);
      setCheckNote('');
      setPaymentMethod('');
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setDiscountMethod('');
      await getSingleTableMutation.mutate();
      await getUsersMutation.mutate();
    },
    onError: (error) => {
      setFetchLoading(false);
      console.warn(error);

      const errorMessage = error.message || "Kasa açık değil, lütfen önce kasa açın.";

      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });


  useEffect(() => {
    getUsersMutation.mutate();
  }, []);

  useEffect(() => {
    getAllMenuMutation.mutate();
  }, []);

  useEffect(() => {
    getSingleTableMutation.mutate();
  }, []);

  useEffect(() => {
    getAllCategoriesMutation.mutate();
  }, []);

  useEffect(() => {
    getAllTablesMutation.mutate();
  }, []);

  useEffect(() => {
    getPaymentMethodsMutation.mutate();
  }, []);

  const filterCategories = (category: any) => {
    setSelectedCategory(category); // Set the selected category
    if (category === "Hepsi") {
      setMenuItems(originalMenuItems);
    } else {
      setMenuItems(
        originalMenuItems.filter((item: any) => item.category === category)
      );
    }
  };

  if (
    // getAllTablesMutation.isPending ||
    getAllCategoriesMutation.isPending ||
    getAllMenuMutation.isPending
  )
    return (
      <div className="min-h-screen bg-white flex items-center justify-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  const calculateTotal = (
    items: any,
    divideUser: any,
    discountPercentage: any,
    discountAmount: any,
    discountMethod: any
  ) => {
    let total = 0;

    const validDiscountPercentage = isNaN(discountPercentage)
      ? 0
      : discountPercentage;
    const validDiscountAmount = isNaN(discountAmount) ? 0 : discountAmount;

    if (discountMethod === "cash") {
      const totalBeforeDiscount = items.reduce((acc: any, item: any) => {
        return acc + parseFloat(item?.sellingPrice) * (item?.quantity || 1);
      }, 0);

      const remainingTotal = totalBeforeDiscount - validDiscountAmount;
      total = remainingTotal / (divideUser > 0 ? divideUser : 1);
    } else {
      total = items?.reduce((acc: any, item: any) => {
        let totalPrice = parseFloat(item?.sellingPrice) * (item?.quantity || 1);
        totalPrice -= (totalPrice * validDiscountPercentage) / 100;
        return acc + totalPrice;
      }, 0);

      total = total / (divideUser > 0 ? divideUser : 1);
    }

    return total.toFixed(2);
  };

  const calculateVAT = (items: any) => {
    let vatTotal = 0;
    vatTotal = items.reduce((acc: any, item: any) => {
      const itemTotal = parseFloat(item?.sellingPrice) * (item?.quantity || 1);
      const itemVAT = itemTotal * (Number(item?.sellingKDV) / 10);
      return acc + itemVAT;
    }, 0);
    return vatTotal.toFixed(2);
  };

  const hasDiscount = () => {
    return discountMethod && (discountPercentage > 0 || discountAmount > 0)
      ? true
      : false;
  };

  const onClickNewUser = (newValue: any) => {
    if (newValue === null) {
      newValue = {};
    }

    console.log('checkUser', newValue);
    setCheckUser(newValue);
  }

  return (
    <>
      <Modal show={showDivideModal} onClose={() => setShowDivideModal(false)}>
        <Modal.Header>
          <h2 className="text-2xl font-bold text-black">Hesabı Böl</h2>
        </Modal.Header>
        <input
          type="number"
          placeholder="Hesap Bölünecek Kişi Sayısı"
          value={divideUser}
          onChange={(e) => {
            const value = e.target.value;
            setDivideUser(parseInt(value));
          }}
          className="w-[90%] mx-auto p-4 border-2 border-gray-200 rounded-lg mt-4"
        />
        <Modal.Body>
          <div
            onClick={() => {
              setShowDivideModal(false);
              setShowDividedTotal(true);
            }}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg bg-blue-500 text-white border-0"
          >
            Böl
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showMoveTableModal} onClose={() => setMoveTableModal(false)}>
        <Modal.Header>
          <h2 className="text-2xl font-bold text-black">Masayı Taşı</h2>
        </Modal.Header>
        <Modal.Body>
          {tables.map((table: any, index: any) => {
            if (table.tableItems.length === 0) {
              return (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center p-4 border-t-2 border-t-gray-200"
                >
                  <span className="text-lg font-bold text-black">
                    {table.tableName}
                  </span>
                  <button
                    className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg bg-blue-500 text-white border-0"
                    onClick={() => {
                      moveTableMutation.mutate(table._id);
                      setMoveTableModal(false);
                      console.log("move table");
                    }}
                  >
                    Taşı
                  </button>
                </div>
              );
            }
          })}
        </Modal.Body>
      </Modal>
      <Modal
        show={showCheckNoteModal}
        onClose={() => setShowCheckNoteModal(false)}
      >
        <Modal.Header>
          <h2 className="text-2xl font-bold text-black">Fiş Notu</h2>
        </Modal.Header>
        <input
          type="text"
          placeholder="Fiş Notu"
          value={checkNote}
          onChange={(e) => {
            const value = e.target.value;
            setCheckNote(value);
          }}
          className="w-[90%] mx-auto p-4 border-2 h-full border-gray-200 rounded-lg mt-4"
        />
        <Modal.Body>
          <div className="flex flex-row w-full py-5">
            {paymentMethods.map((method: any, index: any) => (
              <div
                key={index}
                onClick={() => {
                  setPaymentMethod(method.paymentMethod);
                }}
                className={`h-12 flex items-center justify-center rounded-lg border-2 cursor-pointer w-[30%] mx-1 ${paymentMethod === method.paymentMethod
                  ? "bg-blue-500 text-white"
                  : "bg-white text-black"
                  }`}
              >
                <span>{method.paymentMethod}</span>
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              setShowCheckNoteModal(false);
              checkPaymentMutation.mutate();
            }}
            className="btn btn-xs cursor-pointer sm:btn-sm md:btn-md lg:btn-lg bg-blue-500 text-white border-0"
          >
            Ödemeyi Al
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDiscountModal}
        onClose={() => setShowDiscountModal(false)}
      >
        <Modal.Header>
          <h2 className="text-2xl font-bold text-black">İskonto Yap</h2>
        </Modal.Header>
        <div className="flex flex-row justify-between items-center px-4 py-4">
          <div
            onClick={() => {
              setDiscountMethod("percentage");
            }}
            className={`flex flex-row justify-center items-center border-2 w-full mr-2 border-gray-200 rounded-xl p-4 hover:bg-gray-200 cursor-pointer ${discountMethod === "percentage" ? "bg-blue-500" : null
              }`}
          >
            <span
              className={`${discountMethod === "percentage" ? "text-white" : "text-black"
                }`}
            >
              Yüzde
            </span>
          </div>
          <div
            onClick={() => {
              setDiscountMethod("cash");
            }}
            className={`flex flex-row justify-center items-center border-2 w-full mr-2 border-gray-200 rounded-xl p-4 hover:bg-gray-200 cursor-pointer ${discountMethod === "cash" ? "bg-blue-500" : null
              }`}
          >
            <span
              className={`${discountMethod === "cash" ? "text-white" : "text-black"
                }`}
            >
              Nakit
            </span>
          </div>
        </div>
        <input
          type="number"
          placeholder={
            discountMethod === "cash" ? "İskonto Tutarı" : "İskonto Yüzdesi"
          }
          value={
            discountMethod === "cash" ? discountAmount : discountPercentage
          }
          onChange={(e) => {
            const value = e.target.value;
            if (discountMethod === "cash") {
              setDiscountAmount(parseFloat(value));
            } else {
              setDiscountPercentage(parseInt(value));
            }
          }}
          className="w-[90%] mx-auto p-4 border-2 border-gray-200 rounded-lg mt-4"
        />
        <Modal.Body>
          <div
            onClick={() => {
              setShowDiscountModal(false);
              setShowDividedTotal(true);
            }}
            className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg bg-blue-500 text-white border-0"
          >
            İskonto Yap
          </div>
        </Modal.Body>
      </Modal>
      <div className="min-h-screen bg-[#f1f2f9] ">
        <div className="grid min-h-screen grid-cols-12">
          <div className="col-span-8 bg-[#f8f7fd]  border-gray-200">
            <div className="flex flex-row items-center mb-4 mt-5 px-4">
              <div className="flex w-full justify-between items-center">
                <div className="flex w-full">
                  <img
                    src={ArrowLeft}
                    alt="Back"
                    className="w-8 h-8 object-contain cursor-pointer"
                    onClick={goBack}
                  />
                  <button
                    className="text-xl text-left flex items-center space-x-2"
                    onClick={() => navigate(-1)}
                  >
                    <span className="ml-2">{singleTable?.tableName}</span>
                  </button>
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Ürün/Barkod Ara..."
                    className="w-full p-2 border-2 border-gray-100 h-[51px] rounded-md bg-[#edeff2]"
                    value={searchProduct}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchProduct(value);
                      if (value.trim() === "") {
                        setMenuItems(originalMenuItems);
                      } else {
                        setMenuItems(
                          originalMenuItems.filter(
                            (item: any) =>
                              item.productTitle
                                .toLowerCase()
                                .includes(value.toLowerCase()) ||
                              item?.barcodeNumber?.includes(value)
                          )
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center px-4 space-x-2 border-b-2">
              {menuCategories.map((category: any, index: any) => (
                <button
                  key={index}
                  onClick={() => filterCategories(category.categoryName)}
                  className={`px-3 py-1 border-b-2 ${selectedCategory === category.categoryName
                    ? "border-blue-500 text-blue-500"
                    : "border-transparent text-gray-500"
                    }`}
                >
                  {category.categoryName}
                </button>
              ))}
            </div>
            <div className="grid grid-cols-12 p-4 overflow-y-auto h-[70vh]">
              {menuItems.map((item: any, index: any) => (
                <MenuListItem
                  key={index}
                  item={item}
                  tableId={id}
                  setSingleTable={setSingleTable}
                  singleTable={singleTable}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col h-full col-span-4 justify-between bg-[#f8f7fc] pb-10">
            <div className="p-5">
              <div className="flex items-center justify-between mb-5">
                <img src={logo} alt="Logo" className="w-24" />
                <Dropdown className="relative flex items-center h-header">
                  <Dropdown.Trigger
                    type="button"
                    className="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    <div className="bg-pink-100 rounded-full">
                      <img src={`${url}/upload-file/profilePictures/${user?.user?.profilePicture}`} alt="" className="size-[37.5px] rounded-full" />
                    </div>
                  </Dropdown.Trigger>
                  {/* <Dropdown.Content
                    placement="right-end"
                    className="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] dark:bg-zink-600"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <ul>
                      <li className="pt-2 mt-2 border-t border-slate-200 dark:border-zink-500">
                        <a
                          className="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                          href={process.env.PUBLIC_URL + "/logout"}
                        >
                          <LogOut className="inline-block size-4 ltr:mr-2 rtl:ml-2"></LogOut>{" "}
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Content> */}
                </Dropdown>
              </div>
              <div className="mb-5">
                <div className="flex items-center justify-between mb-5">
                  <h2 className="text-lg font-bold">Müşteri Bilgileri</h2>
                  <div className="flex items-center space-x-3">
                    {LeftMenuData.map((item, index) => (
                      <LeftMenuItem key={index} item={item} />
                    ))}
                  </div>
                </div>
                <Autocomplete
                  disablePortal
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  filterOptions={createFilterOptions({
                    stringify: (option) =>
                      option?.label.slice(0, option?.label.indexOf("(")),
                  })}
                  value={checkUser.value ? checkUser : null} // Ensure value reflects the state
                  onInputChange={(event, newInputValue: any, reason) => {
                    console.log(newInputValue);

                    if (reason === 'clear') {
                      setCheckUser({})
                    }

                    if (newInputValue.length >= 4) {
                      checkQrDataMutation.mutate(newInputValue);
                    }
                  }}
                  renderOption={(props, options) => {
                    return (
                      <button
                        key={options?.value}
                        className="w-full p-2 text-left hover:bg-gray-200"
                        onClick={() => onClickNewUser(options)}
                      >
                        <span className="ml-2">{options?.label}</span>
                      </button>
                    );
                  }}
                  options={
                    [...users, checkUser]
                      .filter((option: any) => option?.isOnlyQr === false || option?.isOnlyQr === undefined)
                      .map((option: any) => ({
                        label: `${option?.name?.charAt(0).toUpperCase() +
                          option?.name?.slice(1).toLowerCase()} ${option?.lastname?.charAt(0).toUpperCase() +
                          option?.lastname?.slice(1)} (${option?.wallet?.balance?.toFixed(2)} ₺)`,
                        value: option?._id,
                      }))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Kullanıcılar" />
                  )}
                />

              </div>
              <div>
                <h2 className="text-lg font-bold mb-4">Adisyon</h2>
                <div className="max-h-64 overflow-y-auto">
                  {singleTable?.tableItems?.length > 0 ? (
                    singleTable?.tableItems?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-4"
                      >
                        <div className="flex w-full items-center">
                          <img
                            src={`${url}/upload-file/product/${item.productImage}`}
                            alt={item.productTitle}
                            className="w-10 h-full rounded object-contain"
                            onError={handleImageError}
                          />
                          <div
                            className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded"
                            style={{ display: "none" }}
                          >
                            <span className="text-xs font-bold">
                              {item.productTitle}
                            </span>
                          </div>
                          <div className="flex flex-col flex-1 ml-4">
                            <span className="font-semibold text-sm">
                              {item.productTitle.length > 10
                                ? item.productTitle.substring(0, 15) + "..."
                                : item.productTitle}
                            </span>
                            <span className="text-gray-500 text-sm">
                              {Number(item.sellingPrice).toFixed(2)} ₺
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center   justify-center w-full">
                          <button
                            onClick={() => decreaseQuantity(index)}
                            className="h-[29px] w-[29px] bg-red-100 text-red-600 rounded-md text-center mr-2"
                          >
                            -
                          </button>
                          <div className="bg-[#edeff2] w-[48px] h-[48px] rounded-md flex items-center justify-center">
                            <span className="p-2 text-sm ">
                              {item.quantity}
                            </span>
                          </div>
                          <button
                            onClick={() => increaseQuantity(index)}
                            className=" h-[29px] w-[29px]  bg-green-100 text-green-600 rounded-md ml-2"
                          >
                            +
                          </button>
                        </div>
                        <div className="w-full flex items-center justify-center">
                          <div className="flex items-center justify-center border-[1px] border-red-500 h-[29px] w-[29px]  rounded-md">
                            <button
                              onClick={() =>
                                removeTableItemMutation.mutate(item._id)
                              }
                              className=" text-red-600"
                            >
                              <Trash size={16} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full w-full items-center justify-center">
                      <span className="text-md font-bold text-black">
                        Adisyon Boş
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="">Fiş Notu</label>
                <input
                  type="text"
                  placeholder="Fiş Notu"
                  value={checkNote}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCheckNote(value);
                  }}
                  className="w-full p-4 border-[1px] bg-[#edeff2] border-gray-200 rounded-lg mt-4"
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-end p-5">

              {Object.keys(checkUser).length === 0 && (
                <div className="flex items-center justify-between w-full mb-4 space-x-2">
                  {paymentMethods.map((method: any, index: any) => (
                    <button
                      key={index}
                      disabled={singleTable?.tableItems?.length === 0}
                      onClick={() => {
                        setPaymentMethod(method.paymentMethod);
                        console.log("clicked button");
                      }}
                      className={`flex-1 p-2 h-[64px] items-center justify-center w-[101px] rounded-md ${paymentMethod === method.paymentMethod
                        ? "bg-blue-500 text-white"
                        : "bg-[#edeff2] text-[#aebbc1]"
                        }`}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <img
                          src={Card}
                          alt="Card"
                          className="w-8 h-8 fill-black text-black object-contain"
                        />
                        <span className="text-gray-800">
                          {method.paymentMethod}
                        </span>
                      </div>
                    </button>
                  ))}
                </div>
              )}



              <div className="w-full py-3 border-gray-200 rounded-md">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-lg font-semibold">Toplam</span>
                  <span className="text-lg font-semibold text-black">
                    ₺
                    {calculateTotal(
                      singleTable?.tableItems,
                      divideUser,
                      discountPercentage,
                      discountAmount,
                      discountMethod
                    )}
                  </span>
                </div>

                <button
                  onClick={() => {


                    if (checkUser?.value) {
                      setPaymentMethod("Bakiye");

                      const user: any = originalUsers.find(
                        (user: any) =>
                          user._id.toString() === checkUser?.value.toString()
                      );

                      const totalAmount = Number(
                        calculateTotal(
                          singleTable?.tableItems,
                          divideUser,
                          discountPercentage,
                          discountAmount,
                          discountMethod
                        )
                      );

                      console.log('user wallet', user?.wallet?.balance);


                      if (
                        user?.wallet?.balance < totalAmount
                      ) {
                        toast.error("Bakiye yetersiz!");
                        return;
                      }


                      assignCheckToUserMutation.mutate(checkUser?.value);
                      createWalletTransactionMutation.mutate({
                        id: checkUser?.value,
                        amount: totalAmount,
                        type: "spent",
                      });
                    } else {
                      checkPaymentMutation.mutate();
                    }
                  }}
                  className={`w-full py-3 rounded-md  bg-blue-500 text-white`}
                  disabled={
                    singleTable?.tableItems?.length === 0 || user?.wallet?.balance === 0 || assignCheckToUserMutation.isPending
                  }
                >
                  {checkUser?.value ? checkUser?.label : "Ödeme Al"}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TerminalTableEdit;
