import React from 'react';
import { useFormik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

interface FormValues {
    title: string;
    message: string;
}

const Contact = () => {
    const formik = useFormik<FormValues>({
        initialValues: {
            title: '',
            message: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Başlık gerekli"),
            message: Yup.string().required("Mesaj gerekli"),
        }),
        onSubmit: async (values) => {
            try {
                // Replace the URL with your API endpoint
                await axios.post('https://api.example.com/contact', values);
                toast.success("Mesaj başarıyla gönderildi");
            } catch (error) {
                toast.error("Mesaj gönderilemedi");
            }
        },
    });

    const renderError = (field: keyof FormValues) => {
        return formik.touched[field] && formik.errors[field] ? (
            <div style={{ color: "#ff4d4f", fontSize: "12px", marginTop: "4px" }}>{formik.errors[field]}</div>
        ) : null;
    };

    return (
        <div style={{ padding: "24px", backgroundColor: "#f1f2f9", width: '100%', minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ToastContainer />

            <div style={{ backgroundColor: "white", padding: "32px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", width: "100%" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px", textAlign: 'center', color: '#333' }}>İletişim</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="title" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Başlık</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.title && formik.errors.title ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('title')}
                    </div>

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="message" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Mesaj</label>
                        <textarea
                            id="message"
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.message && formik.errors.message ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                                minHeight: "100px",
                                resize: "vertical"
                            }}
                        />
                        {renderError('message')}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "#007bff",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                fontSize: "16px",
                                transition: "background-color 0.3s",
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
                        >
                            Gönder
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
