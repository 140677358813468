import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { UploadCloud } from "lucide-react";
import SelectableChips from "./WorkingDays";



const AddMarket = () => {
    const [selectfiles, setSelectfiles] = useState([]);

    const [selectedDays, setSelectedDays] = useState([]);

    const location = useLocation()

    const { id } = location.state || {};

    console.log('id', id)


    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const validation = useFormic({
        initialValues: {
            marketName: '',
            marketPhoneNumber: '',
            marketAddress: '',
            location: '',
            workingHours: '',
        },

        onSubmit: async (values) => {



            const formData = new FormData();
            selectfiles.forEach(file => {
                formData.append('files', file);
            });
            console.log('values', values)
            Object.keys(values).forEach(key => {
                //remove _id createdAt updatedAt __v and productPrice should be
                if (key !== '_id' && key !== 'createdAt' && key !== 'updatedAt' && key !== '__v') {
                    formData.append(key, (values as any)[key]);
                }
            });

            formData.append('workingDays', selectedDays.join(','));  // Add selected days to formData



            try {
                const response = await axios.post('markets/add', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Success:', response.data);

                navigate(0);
                // Optionally navigate or perform further actions
            } catch (error) {
                console.error('Error posting the data', error);
            }
        },

    });

    const handleAcceptfiles = (files: any) => {
        files?.map((file: any) => {
            return Object.assign(file, {
                priview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
        });
        setSelectfiles(files);


    };

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    useEffect(() => {

    }, [])



    return (
        <React.Fragment>
            <BreadCrumb title='Şube Ekle' pageTitle='Şube' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                <div className="col-span-12">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Şube Ekle</h6>

                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="grid grid-cols-12 gap-5 lg:grid-cols-12 xl:grid-cols-12">
                                    <div className="col-span-12">
                                        <label htmlFor="marketName" className="inline-block mb-2 text-base font-medium">Şube Adı</label>
                                        <input type="text" id="marketName" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Şube Adı" required
                                            onChange={validation.handleChange}
                                            value={validation.values.marketName}
                                        />

                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="marketPhoneNumber" className="inline-block mb-2 text-base font-medium">Şube Telefon Numarası</label>
                                        <input type="text" id="marketPhoneNumber" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Telefon" required
                                            onChange={validation.handleChange}
                                            value={validation.values.marketPhoneNumber}
                                        />

                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="marketAddress" className="inline-block mb-2 text-base font-medium">Şube Adres</label>
                                        <input type="text" id="marketAddress" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Adres" required
                                            onChange={validation.handleChange}
                                            value={validation.values.marketAddress}
                                        />

                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="location" className="inline-block mb-2 text-base font-medium">Konum</label>
                                        <input type="text" id="location" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Konum" required
                                            onChange={validation.handleChange}
                                            value={validation.values.location}
                                        />
                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="workingHours" className="inline-block mb-2 text-base font-medium">Çalışma Saatleri</label>
                                        <input type="text" id="workingHours" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Çalışma Saatleri" required
                                            onChange={validation.handleChange}
                                            value={validation.values.workingHours}
                                        />

                                    </div>

                                    <div className="col-span-12">
                                        <label className="inline-block mb-2 text-base font-medium">Çalışma Günleri</label>
                                        <SelectableChips selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
                                    </div>

                                    <div className="lg:col-span-12 col-span-12 xl:col-span-12">
                                        <label htmlFor="genderSelect" className="inline-block mb-2 text-base font-medium">Şube Resmi</label>

                                        <Dropzone
                                            onDrop={(acceptfiles: any) => {
                                                handleAcceptfiles(acceptfiles);
                                            }}>
                                            {({ getRootProps }: any) => (
                                                <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dark:bg-zink-700 dark:border-zink-500 dropzone2">
                                                    <div className="w-full py-5 text-lg text-center dz-message needsclick" {...getRootProps()} >
                                                        <div className="mb-3">
                                                            <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                                                        </div>
                                                        <h5 className="mb-0 font-normal text-slate-500 dark:text-zink-200 text-15">
                                                            Resimleri buraya sürükleyip bırakın veya
                                                            <Link to="#!">  seçin</Link> </h5>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                            {
                                                (selectfiles || [])?.map((file: any, index: number) => {
                                                    return (
                                                        <li className="mt-5" id="dropzone-preview-list2">
                                                            <div className="border rounded border-slate-200 dark:border-zink-500">
                                                                <div className="p-2 text-center">
                                                                    <div>
                                                                        <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                                                                            <img className="block w-full h-full rounded-md" src={file.priview} alt={file.name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="pt-3">
                                                                        <h5 className="mb-1 text-15" data-dz-name>{file.path}</h5>
                                                                        <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{file.formattedSize}</p>
                                                                        <strong className="error text-danger" data-dz-errormessage></strong>
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <button data-dz-remove className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20" onClick={() => {
                                                                            const newImages = [...selectfiles];
                                                                            newImages.splice(index, 1);
                                                                            setSelectfiles(newImages);
                                                                        }}>Delete</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Sıfırla</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {
                                            id ? 'KDV Güncelle' : 'Şube Ekle'
                                        }
                                    </button>
                                    {/* <button type="button" className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10">Draft & Preview</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default AddMarket;