import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
// Icons
import { Search, Trash2, Plus, FileEdit, UploadCloud } from "lucide-react";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import axios from "axios";
import { APIClient, imageUrl } from "helpers/api_helper";
import Dropzone from "react-dropzone";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { Tooltip } from 'react-tooltip'


const api = new APIClient();

const CategoryListView = () => {
  const [eventData, setEventData] = useState<any>();
  const [selectedColor, setSelectedColor] = useState<string>("#ffffff");

  const handleColorChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
  }, []);

  const navigate = useNavigate();

  const [selectfiles, setSelectfiles] = useState([]);
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Get Data
  const getCategories = async () => {
    const response = await axios.get("/categories/all");
    setCategoriesList(response);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const deleteCategoryMutation = useMutation({
    mutationFn: async (id: any) => {
      return api.delete(`/categories/delete/${id}`, null);
    },
    onMutate: async (id: any) => {
      const result = await Swal.fire({
        title: 'Emin misiniz?',
        text: 'Bu kategoriyi silmek üzeresiniz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, sil!',
        cancelButtonText: 'Hayır, iptal et'
      });
      if (result.isConfirmed) {
        return id;
      } else {
        throw new Error("Silme işlemi iptal edildi.");
      }
    },
    onSuccess: (data, variables) => {
      toast.success(
        "Kategori başarıyla silindi. Lütfen sildiğiniz kategoriye ait ürünleri kontrol ediniz ve başka bir kategoriye taşıyınız."
      );
      setCategoriesList((prevList: any) =>
        prevList.filter((item: any) => item._id !== variables)
      );
    },
    onError: (error) => {
      if (error.message !== "Silme işlemi iptal edildi.") {
        toast.error("Kategori silinirken bir hata oluştu.");
      } else {
        toast.info("Silme işlemi iptal edildi.");
      }
    },
  });

  // Delete Data
  const onClickDelete = useCallback((cell: any) => {
    if (cell._id) {
      deleteCategoryMutation.mutate(cell._id);
    }
  }, [deleteCategoryMutation]);

  // Update Data
  const handleUpdateDataClick = useCallback((ele: any) => {
    setEventData({ ...ele });
    setSelectedColor(ele.categoryColor ? ele.categoryColor : "#ffffff");
    setIsEdit(true);
    setShow(true);
  }, []);

  const handleAcceptfiles = (files: any) => {
    files.map((file: any) => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
    });
    setSelectfiles(files);
  };

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryName: eventData && eventData.categoryName,
      categoryColor: eventData && eventData.categoryColor,
      showOnMenu: eventData && eventData.showOnMenu,
    },
    onSubmit: async (values) => {
      if (eventData) {
        const formData = new FormData();
        formData.append("categoryName", values.categoryName);
        formData.append("showOnMenu", String(values.showOnMenu));
        formData.append("categoryColor", values.categoryColor);
        formData.append("categoryImage", selectfiles[0]);

        const response = await axios.put(
          `/categories/update/${eventData._id}`,
          formData
        );
        navigate(0);
      }
    },
  });

  // Image
  const [selectedImage, setSelectedImage] = useState<any>();
  const handleImageChange = (event: any) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        validation.setFieldValue("img", e.target.result);
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = useCallback((e: any) => {
    const search = e.target.value;
    const keysToSearch = ["categoryName"];
    filterDataBySearch(categoriesList, search, keysToSearch, setCategoriesList);
  }, [categoriesList]);

  // Handle drag and drop reorder
  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;

    const items = Array.from(categoriesList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    try {
      await axios.post("/categories/update-order", {
        categories: items,
      });
      setCategoriesList(items);
    } catch (error) {
      console.error("Error updating payment method order", error);
      Swal.fire("Error", "Could not update order", "error");
    }

  };

  return (
    <React.Fragment>
      <BreadCrumb title="Kategoriler" pageTitle="Kategoriler" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="categoriesTable">
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-12">
                  <div className="xl:col-span-3">
                    <div className="relative">
                      <input
                        type="text"
                        className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Search for ..."
                        autoComplete="off"
                        onChange={(e) => filterSearchData(e)}
                      />
                      <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                    </div>
                  </div>

                  <div className="lg:col-span-2 ltr:lg:text-right rtl:lg:text-left xl:col-span-2 xl:col-start-11">
                    <Link
                      to="/add-new-category"
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <Plus className="inline-block size-4" />{" "}
                      <span className="align-middle">Kategori Ekle</span>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {categoriesList && categoriesList.length > 0 ? (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="categories">
                    {(provided) => (
                      <table className="min-w-full table-auto border-collapse border border-slate-300">
                        <thead>
                          <tr className="bg-slate-100">
                            <th className="border border-slate-300 px-4 py-2 text-left">
                              Kategori İsmi
                            </th>
                            <th className="border border-slate-300 px-4 py-2 text-left">
                              Oluşturulma Tarihi
                            </th>
                            <th className="border border-slate-300 px-4 py-2 text-left">
                              Menüde Gözüksün
                            </th>
                            <th className="border border-slate-300 px-4 py-2 text-left">
                              Aksiyon
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {categoriesList.map((category: any, index: number) => (
                            <Draggable
                              key={category._id}
                              draggableId={category._id}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="bg-white hover:bg-gray-100"
                                >
                                  <td className="border border-slate-300 px-4 py-2">
                                    <div className="flex items-center gap-2">
                                      <img
                                        src={
                                          imageUrl +
                                          `/upload-file/categories/${category.categoryImage}`
                                        }
                                        alt="Category image"
                                        className="h-6"
                                      />
                                      {category.categoryName}
                                    </div>
                                  </td>
                                  <td className="border border-slate-300 px-4 py-2">
                                    {category.createdAt}
                                  </td>
                                  <td className="border border-slate-300 px-4 py-2">
                                    <span
                                      className={`px-2.5 py-0.5 text-xs font-medium rounded border ${category.showOnMenu
                                          ? "bg-green-100"
                                          : "bg-red-100"
                                        } p-2 border-transparent ${category.showOnMenu
                                          ? "text-green-500"
                                          : "text-redca-500"
                                        } dark:bg-green-500/20 dark:border-transparent inline-flex items-center status`}
                                    >
                                      {category.showOnMenu ? "Evet" : "Hayır"}
                                    </span>
                                  </td>
                                  <td className="border border-slate-300 px-4 py-2 flex space-x-2">
                                    <Tooltip id="edit" />
                                    <Tooltip id="delete" />
                                    <button
                                      data-tooltip-id="edit"
                                      data-tooltip-content={`Düzenle`}
                                      className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                      onClick={() =>
                                        handleUpdateDataClick(category)
                                      }
                                    >
                                      <FileEdit className="w-4 h-4" />
                                    </button>
                                    <button
                                      data-tooltip-id="delete"
                                      data-tooltip-content={`Sil`}
                                      className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
                                      onClick={() => onClickDelete(category)}
                                    >
                                      <Trash2 className="w-4 h-4" />
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sonuç Bulunamadı</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Category Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Kategori Güncelle" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="categoryName"
                className="inline-block mb-2 text-base font-medium"
              >
                Kategori Adı
              </label>
              <input
                type="text"
                id="categoryNameInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Kategori Adı"
                name="categoryName"
                onChange={validation.handleChange}
                value={validation.values.categoryName}
              />
              {validation.touched.categoryName &&
                validation.errors.categoryName ? (
                <p className="text-red-400">{validation.errors.categoryName}</p>
              ) : null}
            </div>

            <div className="col-span-12">
              <label
                htmlFor="categoryColor"
                className="inline-block mb-2 text-base font-medium"
              >
                Kategori Rengi
              </label>
              <div className="flex items-center">
                <input
                  type="color"
                  id="categoryColor"
                  className="h-10 w-10 rounded-full border border-gray-300 mr-2"
                  value={validation.values.categoryColor}
                  onChange={(event) => {
                    validation.handleChange(event);
                    handleColorChange(event);
                  }}
                />

                <p className="text-gray-700">Seçilen Renk: {selectedColor}</p>
              </div>
            </div>

            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="categoryImage"
                className="inline-block mb-2 text-base font-medium"
              >
                Kategori Resmi
              </label>

              <Dropzone
                onDrop={(acceptfiles: any) => {
                  handleAcceptfiles(acceptfiles);
                }}
              >
                {({ getRootProps }: any) => (
                  <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dark:bg-zink-700 dark:border-zink-500 dropzone2">
                    <div
                      className="w-full py-5 text-lg text-center dz-message needsclick"
                      {...getRootProps()}
                    >
                      <div className="mb-3">
                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                      </div>
                      <h5 className="mb-0 font-normal text-slate-500 dark:text-zinc-200 text-15">
                        Resimleri buraya sürükleyip bırakın veya{" "}
                        <Link to="#!">seçin</Link>
                      </h5>
                    </div>
                  </div>
                )}
              </Dropzone>
              <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                {(selectfiles || [])?.map((file: any, index: number) => {
                  return (
                    <li className="mt-5" id="dropzone-preview-list2">
                      <div className="border rounded border-slate-200 dark:border-zink-500">
                        <div className="p-2 text-center">
                          <div>
                            <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                              <img
                                className="block w-full h-full rounded-md"
                                src={file.priview}
                                alt={file.name}
                              />
                            </div>
                          </div>
                          <div className="pt-3">
                            <h5 className="mb-1 text-15" data-dz-name>
                              {file.path}
                            </h5>
                            <p
                              className="mb-0 text-slate-500 dark:text-zink-200"
                              data-dz-size
                            >
                              {file.formattedSize}
                            </p>
                            <strong
                              className="error text-danger"
                              data-dz-errormessage
                            ></strong>
                          </div>
                          <div className="mt-2">
                            <button
                              data-dz-remove
                              className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                              onClick={() => {
                                const newImages = [...selectfiles];
                                newImages.splice(index, 1);
                                setSelectfiles(newImages);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="xl:col-span-12 flex items-center">
              <input
                type="checkbox"
                id="showOnMenu"
                className=" mr-2 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Menüde Gözüksün"
                checked={validation.values.showOnMenu}
                value={validation.values.showOnMenu}
                onChange={validation.handleChange}
              />
              <label
                htmlFor="showOnMenu"
                className="inline-block  text-base font-medium"
              >
                Menüde Gözüksün
              </label>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                İptal
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Güncelle" : "Add User"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CategoryListView;
