import { useMutation } from "@tanstack/react-query";
import BreadCrumb from "Common/BreadCrumb";
import { APIClient } from "helpers/api_helper";
import { NotebookTabs, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import axios from "axios";
import CashRegisterModal from "./Components/CashRegisterModal";

const api = new APIClient();

const CashRegisterList = () => {
  const [dailyStatistics, setDailyStatistics] = useState({} as any);
  const [cashRegister, setCashRegister] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("tr-TR", options).replace(",", "");
  };

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
  };

  const getDailyCashRegisterMutation = useMutation({
    mutationFn: async () =>
      await axios.get(
        `/cash-register/daily-cash-register-report?startDate=${
          typeof value?.startDate === "string"
            ? value?.startDate
            : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${
          typeof value?.endDate === "string"
            ? value?.endDate
            : value?.endDate?.toISOString().slice(0, 10)
        }`
      ),
    onSuccess: (response: any) => {
      setDailyStatistics(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  
  useEffect(() => {
    getDailyCashRegisterMutation.mutate();
  }, [value?.startDate, value?.endDate]);

  return (
    <React.Fragment>
      <div className="min-h-screen">
        <BreadCrumb title="Kasaları Görüntüle" pageTitle="Kasalar" />
        <div className="my-5 flex space-x-4">
          <Datepicker displayFormat={"DD/MM/YYYY"} value={value} onChange={handleValueChange} />
        </div>
        <div className="bg-white p-8 rounded-md w-full">
          <div className="flex items-center justify-between pb-6">
            <div>
              <h2 className="text-gray-600 font-semibold">Kasalar</h2>
            </div>
            <div className="flex items-center justify-between">
              <div className="lg:ml-40 ml-10">
                <Link
                  to="/cash-register"
                  type="button"
                  className="text-white btn bg-custom-500 w-[180px] border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  <Plus className="inline-block size-4" />{" "}
                  <span className="align-middle">Kasa İşlemleri</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Kasayı Açan Kişinin Adı
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Kasa Açılış Tarihi
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Kasa Detayı
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Kasa Durumu
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailyStatistics && dailyStatistics.length > 0 ? (
                      dailyStatistics.map((item: any) => (
                        <tr key={item._id}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item.createdBy}
                              </p>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <button
                              onClick={() => {
                                setCashRegister(item);
                                setShowModal(true);
                              }}
                              className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                            >
                              <NotebookTabs className="w-4 h-4" />
                            </button>
                          </td>
                          {item.isOpen ? (
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden="true"
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                />
                                <span className="relative">Kasa Açık</span>
                              </span>
                            </td>
                          ) : (
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                <span
                                  aria-hidden="true"
                                  className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative">Kasa Kapalı</span>
                              </span>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          className="px-5 py-5 border-b  border-gray-200 bg-white text-sm"
                          colSpan={4}
                        >
                          <p className="text-gray-900 whitespace-no-wrap">
                            Kasa Bulunamadı...
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CashRegisterModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-col items-center mt-5 space-y-6">
          <div className="mt-4 space-y-2">
            <h1 className="text-2xl font-bold text-center">
              {cashRegister.createdBy}
            </h1>
            <p>
              <strong>Kasa Açılış Tarih:</strong>{" "}
              {formatDate(cashRegister.createdAt)}
            </p>
            <p className={cashRegister.isOpen?"hidden":""}>
              <strong>Kasa Kapanış Tarih:</strong>{" "}
              {formatDate(cashRegister.updatedAt)}
            </p>
          </div>
          <div className="mt-4 w-full flex flex-wrap justify-between space-y-6 md:space-y-0">
            <div className="w-full md:w-1/3 p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold cursor-pointer text-center">
                Kasa Açılış Değerleri
              </h2>
              <ul className="list-disc list-inside mt-2 space-y-2">
                {cashRegister.openingValues &&
                  cashRegister.openingValues.map(
                    (value: any, index: number) => (
                      <li key={index} className="text-gray-700">
                        {value.paymentMethod}: {value.amount} TL
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="w-full md:w-1/3 p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold cursor-pointer text-center">
                Kasada Olması Gereken Değerler
              </h2>
              <ul className="list-disc list-inside mt-2 space-y-2">
                {cashRegister.expectedValues &&
                  cashRegister.expectedValues.map(
                    (value: any, index: number) => (
                      <li key={index} className="text-gray-700">
                        {value.paymentMethod}: {value.amount} TL
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="w-full md:w-1/3 p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold cursor-pointer text-center">
                Kasa Kapanış Değerleri
              </h2>
              <ul className="list-disc list-inside mt-2 space-y-2">
                {cashRegister.closingValues &&
                  cashRegister.closingValues.map(
                    (value: any, index: number) => (
                      <li key={index} className="text-gray-700">
                        {value.paymentMethod}: {value.amount} TL
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>
          <div className="mt-4 w-full bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold mb-2">Descriptions</h2>
            <details className="bg-gray-100 p-4 rounded-lg">
              <summary className="cursor-pointer text-gray-700">
                Açıklamaları Gör
              </summary>
              <ul className="list-disc list-inside mt-2 space-y-2">
                {cashRegister.descriptions &&
                  cashRegister.descriptions.map((desc: any, index: number) => (
                    <li key={index} className="text-gray-700">
                      {desc.description} (
                      {new Date(desc.dateAdded).toLocaleString()})
                    </li>
                  ))}
              </ul>
            </details>
          </div>
        </div>
      </CashRegisterModal>
    </React.Fragment>
  );
};

export default CashRegisterList;