import React from 'react';

type Props = {};

const AccountDeletionPolicy = (props: Props) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Hesap Silme Politikası</h1>
            <p style={styles.paragraph}>
                Kullanıcılar, hesaplarını silmek için aşağıdaki adımları izleyebilirler:
            </p>
            <ol style={styles.list}>
                <li style={styles.listItem}>Profil sayfanıza gidin.</li>
                <li style={styles.listItem}>"Çıkış Yap" butonun altındaki "Hesabı Sil" seçeneğine tıklayın.</li>
                <li style={styles.listItem}>Hesabınızı silmek istediğinizi onaylamak için istenen bilgileri girin.</li>
            </ol>
            <p style={styles.paragraph}>
                Hesabınızı sildikten sonra, tüm kişisel verileriniz ve hesap bilgileri geri alınamaz şekilde silinecektir. Bu işlem geri döndürülemez.
            </p>
        </div>
    );
};

const styles = {
    container: {
        padding: '40px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '800px',
        margin: 'auto',
        borderRadius: '8px',
        boxShadow: '0 0 15px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
    },
    heading: {
        color: '#2c3e50',
        fontSize: '28px',
        marginBottom: '30px',
        borderBottom: '2px solid #e0e0e0',
        paddingBottom: '10px',
    },
    paragraph: {
        color: '#34495e',
        fontSize: '18px',
        lineHeight: '1.8',
        marginBottom: '25px',
    },
    list: {
        color: '#34495e',
        fontSize: '18px',
        lineHeight: '1.8',
        paddingLeft: '40px',
    },
    listItem: {
        marginBottom: '15px',
    },
};

export default AccountDeletionPolicy;
