import React from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { PiNotePencilBold } from "react-icons/pi";
import Swal from "sweetalert2";

type Props = {
  adisyon: {
    _id: number;
    tableName: string;
    tableItems: { sellingPrice: number; quantity: number }[];
    lastUpdated: string;
    person: number;
  };
  onCancel: (id: any) => void;
  deleteTable:(id:any)=>void;
};

const SalonListItem = ({ adisyon, onCancel,deleteTable }: Props) => {
  const navigate = useNavigate();

  const handleDeleteTable = (e: React.MouseEvent) => {
    e.stopPropagation();

    Swal.fire({
      title: "Masayı silmek istediğinize eminmisiniz?",
      showDenyButton: true,
      confirmButtonText: "Evet",
      denyButtonText: `Hayır`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTable(adisyon._id);
      } else if (result.isDenied) {
        Swal.fire("İşlem İptal Edildi", "", "info");
      }
    });
  };
  const handleTrashClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (adisyon.tableItems.length === 0) {
      Swal.fire("Uyarı", "Adisyon yok", "warning");
      return;
    }

    Swal.fire({
      title: "Adisyonu silmek istediğinize eminmisiniz?",
      showDenyButton: true,
      confirmButtonText: "Evet",
      denyButtonText: `Hayır`,
    }).then((result) => {
      if (result.isConfirmed) {
        onCancel(adisyon._id);
      } else if (result.isDenied) {
        Swal.fire("İşlem İptal Edildi", "", "info");
      }
    });
  };

  return (
    <div
      className={`flex flex-col relative ${
        adisyon.tableItems.length === 0 ? "bg-gray-300" : "bg-[#2083FF]"
      } rounded-xl hover:bg-gray-200 ${
        adisyon.tableItems.length === 0
          ? "hover:bg-gray-200"
          : "hover:bg-gradient-to-b hover:from-blue-200 hover:to-blue-400 "
      } cursor-pointer h-36 items-center justify-center col-span-3`}
      onClick={() => {
        navigate(`/terminal-table-edit/${adisyon._id}`);
      }}
    >
      <div
        className="absolute -top-2 -right-2 border-[1px] border-red-500 h-6 w-6 bg-white rounded-full text-red-600 cursor-pointer flex items-center justify-center"
        onClick={handleDeleteTable}
      >
        <FaTrash className="h-3 w-3 text-red-600" />
      </div>
      <div
        className="absolute top-6 -right-2 border-[1px] border-red-500 h-6 w-6 bg-white rounded-full text-red-600 cursor-pointer flex items-center justify-center"
        onClick={handleTrashClick}
      >
        <PiNotePencilBold className="h-4 w-4 text-red-600" />
      </div>
      <div className="flex flex-col items-center">
        <span
          className={`text-lg font-bold ${
            adisyon.tableItems.length === 0 ? "text-gray-500" : "text-white"
          }`}
        >
          {adisyon.tableName}
        </span>
        <span
          className={`${
            adisyon.tableItems.length === 0 ? "text-gray-500" : "text-white"
          } text-sm`}
        >
          {adisyon.tableItems.length === 0
            ? "Boş"
            : adisyon.tableItems
                .reduce(
                  (acc: any, item: any) =>
                    acc + Number(item.sellingPrice) * item.quantity,
                  0
                )
                .toFixed(2) + "₺"}
        </span>

        {adisyon.tableItems.length > 0 && (
          <div className="flex flex-row absolute bottom-0 items-center justify-between w-full px-4 py-2 mt-2">
            <div>
              <span
                className={`${
                  adisyon.tableItems.length === 0
                    ? "text-gray-500"
                    : "text-white"
                } text-sm`}
              >
                Admin
              </span>
            </div>
            <div>
              <span
                className={`${
                  adisyon.tableItems.length === 0
                    ? "text-gray-500"
                    : "text-white"
                } text-sm`}
              >
                {adisyon.lastUpdated}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalonListItem;
