import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Column {
  accessor: string;
  Header: string;
}

interface MultipleSelectCheckmarksProps {
  columns: Column[];
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
}

export default function MultipleSelectCheckmarks({
  columns,
  selectedColumns,
  setSelectedColumns,
}: MultipleSelectCheckmarksProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
    },
  });

  const handleChange = (event: SelectChangeEvent<typeof selectedColumns>) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="p-4">
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label" className="dark:text-gray-200">Filtreler</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<OutlinedInput label="Columns" className="dark:bg-gray-700 dark:text-gray-200" />}
            renderValue={(selected) =>
              selected.map((value) => columns.find((column) => column.accessor === value)?.Header).join(', ')
            }
            MenuProps={MenuProps}
            className="dark:bg-gray-700 dark:text-gray-200"
          >
            {columns.map((column) => (
              <MenuItem key={column.accessor} value={column.accessor}>
                <Checkbox checked={selectedColumns.indexOf(column.accessor) > -1} />
                <ListItemText primary={column.Header} className="dark:text-gray-200" />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ThemeProvider>
  );
}
