import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import * as Yup from 'yup';
import Select from 'react-select';
import axios from "axios";
import Dropzone from "react-dropzone";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import { ReactCropperElement } from "react-cropper";
import CropperC from "../Components/CropImage/CropImage";
import { onDrop, onCropDone } from "../Components/CropImage/CropImageHelper";

// Icon
import { UploadCloud } from 'lucide-react';

const api = new APIClient();

const AddNew = () => {
    const [selectfiles, setSelectfiles] = useState<any>([]);
    const [categories, setCategories] = useState([]);
    const [kdvList, setKDVList] = useState([]);
    const [barcode, setBarcode] = useState('');
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState("");
    const cropperRef = useRef<ReactCropperElement>(null);

    const location = useLocation();
    const { id } = location.state || {};

    const navigate = useNavigate();

    const calculateProfitRate = (buyingPrice: any, sellingPrice: any) => {
        const buyPrice = parseFloat(buyingPrice);
        const sellPrice = parseFloat(sellingPrice);
        if (!isNaN(buyPrice) && !isNaN(sellPrice) && buyPrice !== 0) {
            return (((sellPrice - buyPrice) / buyPrice) * 100).toFixed(2);
        }
        return "0";
    };

    const calculateTotalWithKDV = (price: any, kdv: any) => {
        const priceNum = parseFloat(price);
        const kdvNum = parseFloat(kdv);
        if (!isNaN(priceNum) && !isNaN(kdvNum)) {
            return (priceNum * (kdvNum / 100)).toFixed(2);
        }
        return "0.00";
    };

    const validationSchema = Yup.object().shape({
        productTitle: Yup.string().required('Ürün ismi zorunludur'),
        category: Yup.string().required('Kategori zorunludur'),
        productDescription: Yup.string().required('Ürün açıklaması zorunludur'),
        barcodeNumber: Yup.string().required('Barkod numarası zorunludur'),
        buyingKDV: Yup.number().required('Alış KDV oranı zorunludur'),
        sellingKDV: Yup.number().required('Satış KDV oranı zorunludur'),
        buyingPrice: Yup.number().required('Alış fiyatı zorunludur'),
        sellingPrice: Yup.number().required('Satış fiyatı zorunludur'),
        stock: Yup.number().required('Stok zorunludur'),
    });

    const validation = useFormic({
        initialValues: {
            productTitle: '',
            category: '',
            productDescription: '',
            productNutritions: '',
            barcodeNumber: '',
            buyingKDV: '',
            sellingKDV: '',
            buyingPrice: '',
            sellingPrice: '',
            stock: '',
            showProduct: true,
            profitRate: '0',
            totalBuyingKDV: '0.00',
            totalSellingKDV: '0.00',
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();

            // Append only the necessary fields to formData
            if (id) {
                Object.keys(values).forEach((key) => {
                    if (!['_id', 'createdAt', 'updatedAt', '__v', 'barcodeNumber'].includes(key)) {
                        formData.append(key, (values as any)[key]);
                    }
                });
            } else {
                Object.keys(values).forEach((key) => {
                    if (!['_id', 'createdAt', 'updatedAt', '__v'].includes(key)) {
                        formData.append(key, (values as any)[key]);
                    }
                });
            }

            // Append files if they exist
            if (selectfiles.length > 0) {
                selectfiles.forEach((file: any) => {
                    formData.append('files', file);
                });
            }

            try {
                const response = id
                    ? await axios.put(`/menu/update/${id}`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    : await axios.post('/menu/create', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                navigate('/menu');
            } catch (error) {
                console.error('Error posting the data', error);
            }
        },
    });

    const getSingleProduct = async (id: string) => {
        try {
            const response: any = await axios.get(`/menu/single/${id}`);
            validation.setValues({
                productTitle: response.productTitle,
                category: response.category,
                productDescription: response.productDescription,
                productNutritions: response.productNutritions,
                barcodeNumber: response.barcodeNumber,
                buyingKDV: response.buyingKDV,
                sellingKDV: response.sellingKDV,
                buyingPrice: response.buyingPrice,
                sellingPrice: response.sellingPrice,
                stock: response.stock,
                showProduct: response.showProduct,
                profitRate: calculateProfitRate(response.buyingPrice, response.sellingPrice),
                totalBuyingKDV: calculateTotalWithKDV(response.buyingPrice, response.buyingKDV),
                totalSellingKDV: calculateTotalWithKDV(response.sellingPrice, response.sellingKDV),
            });

            if (response.productImage) {
                setSelectfiles([{
                    priview: `https://api.macfitcafe.com/upload-file/product/${response.productImage}`,
                    formattedSize: 'N/A', // Adjust size if you have the information
                }]);
            }
        } catch (error) {
            console.error('Error getting the data', error);
        }
    };

    const getCategories = async () => {
        try {
            const response: any = await axios.get(`/categories/all`);
            console.log('Fetched categories:', response.data); // Debug log
            setCategories(response.map((category: any) => ({
                value: category.categoryName,
                label: category.categoryName
            })));
        } catch (error) {
            console.error('Error getting the data', error);
        }
    };

    const wrapperSetCroppedImage = useCallback((image: string) => {
        setCroppedImage(image);
    }, [setCroppedImage]);

    const wrapperSetOpen = useCallback((open: boolean) => {
        setOpen(open);
    }, [setOpen]);

    const handleAcceptfiles = (files: any) => {
        files.map((file: any) => Object.assign(file, {
            priview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        }));
        setSelectfiles((prevFiles: any) => [...prevFiles, ...files]);
    };

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const getBarcodeNumber = async () => {
        const response: any = await axios.get('/menu/barcode');
        setBarcode(response.toString());
        validation.setFieldValue('barcodeNumber', response.toString());
    };

    const getKDVListMutation = useMutation({
        mutationFn: () => api.get('/kdv/list', null),
        onSuccess: (data: any) => {
            setKDVList(data.map((kdv: any) => ({
                value: kdv.kdv,
                label: kdv.kdv
            })));
        },
        onError: (error) => {
            console.error('Error fetching KDV list', error);
        }
    });

    useEffect(() => {
        if (id) {
            getSingleProduct(id);
        }
    }, [id]);

    useEffect(() => {
        getKDVListMutation.mutate();
    }, []);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (!id) {
            getBarcodeNumber();
        }
    }, [id]);

    useEffect(() => {
        if (validation.values.buyingKDV && validation.values.sellingKDV && kdvList.length > 0) {
            const buyingKDVOption: any = kdvList.find((kdv: any) => kdv.value === validation.values.buyingKDV);
            const sellingKDVOption: any = kdvList.find((kdv: any) => kdv.value === validation.values.sellingKDV);

            if (buyingKDVOption) {
                validation.setFieldValue('buyingKDV', buyingKDVOption.value);
            }
            if (sellingKDVOption) {
                validation.setFieldValue('sellingKDV', sellingKDVOption.value);
            }
        }
    }, [validation.values.buyingKDV, validation.values.sellingKDV, kdvList]);

    useEffect(() => {
        const profitRate = calculateProfitRate(validation.values.buyingPrice, validation.values.sellingPrice);
        const totalBuyingKDV = calculateTotalWithKDV(validation.values.buyingPrice, validation.values.buyingKDV);
        const totalSellingKDV = calculateTotalWithKDV(validation.values.sellingPrice, validation.values.sellingKDV);
        validation.setFieldValue('profitRate', profitRate);
        validation.setFieldValue('totalBuyingKDV', totalBuyingKDV);
        validation.setFieldValue('totalSellingKDV', totalSellingKDV);
    }, [validation.values.buyingPrice, validation.values.sellingPrice, validation.values.buyingKDV, validation.values.sellingKDV]);

    // Scroll to top on component mount or id change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    if (getKDVListMutation.isPending) {
        return (
            <div role="status">
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }

    return (
        <React.Fragment>
            <BreadCrumb title='Yeni Ürün Ekle' pageTitle='Menü' />
            <div className="grid grid-cols-1 xl:grid-cols-12 overflow-y-scroll gap-x-5">
                <div className="xl:col-span-9">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Ürün Oluştur</h6>
                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                                    <div className="xl:col-span-6">
                                        <label htmlFor="productTitle" className="inline-block mb-2 text-base font-medium">Ürün İsmi</label>
                                        <input type="text" id="productTitle" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Ürün Adı" required
                                            onChange={validation.handleChange}
                                            value={validation.values.productTitle}
                                        />
                                        {validation.errors.productTitle && validation.touched.productTitle && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.productTitle}</div>
                                        )}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="barcodeNumber" className="inline-block mb-2 text-base font-medium">Ürün Barkodu</label>
                                        <input type="text" id="barcodeNumber" value={validation.values.barcodeNumber} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="TWT-LP-ALU-08" required
                                            readOnly={id ? true : false}
                                            onChange={validation.handleChange}
                                        />
                                        {validation.errors.barcodeNumber && validation.touched.barcodeNumber && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.barcodeNumber}</div>
                                        )}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <div className="flex flex-col">
                                            <label htmlFor="categorySelect" className="inline-block mb-2 text-base font-medium">Kategori</label>
                                            <Select
                                                placeholder="Kategori Seç"
                                                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                options={categories}
                                                onChange={(e: any) => validation.setFieldValue('category', e.value)}
                                                value={categories.find((option: any) => option.value === validation.values.category)}
                                                isSearchable={false}
                                                name="categorySelect"
                                                id="categorySelect"
                                            />
                                            {validation.errors.category && validation.touched.category && (
                                                <div className="text-red-500 text-sm mt-1">{validation.errors.category}</div>
                                            )}
                                            <button className="
                                                mt-2 text-sm text-center bg-blue-500 text-white hover:text-gray-300 dark:text-zink-200 dark:hover:text-zink-300 rounded-md py-2
                                            "
                                                onClick={() => {
                                                    navigate('/add-new-category');
                                                }}
                                            >
                                                Kategori Ekle
                                            </button>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="stock" className="inline-block mb-2 text-base font-medium">Stok</label>
                                        <input
                                            type="number"
                                            id="stock"
                                            readOnly={id ? true : false}
                                            onChange={validation.handleChange}
                                            value={validation.values.stock}
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Stok" required
                                        />
                                        {validation.errors.stock && validation.touched.stock && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.stock}</div>
                                        )}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="buyingKDV" className="inline-block mb-2 text-base font-medium">Alış KDV Oranı</label>
                                        <Select
                                            placeholder="Alış KDV Oranı"
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={kdvList.map((item: any) => ({
                                                value: item.value,
                                                label: `${item.value}%`
                                            }))}
                                            onChange={(e: any) => validation.setFieldValue('buyingKDV', e.value)}
                                            value={kdvList.find((option: any) => option.value === validation.values.buyingKDV)}
                                            isSearchable={false}
                                            name="buyingKDV"
                                            id="buyingKDV"
                                        />
                                        {validation.errors.buyingKDV && validation.touched.buyingKDV && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.buyingKDV}</div>
                                        )}
                                        <span className="absolute right-3 top-3 text-gray-500 dark:text-zinc-200">%</span>
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="sellingKDV" className="inline-block mb-2 text-base font-medium">Satış KDV Oranı</label>
                                        <Select
                                            placeholder="Satış KDV Oranı"
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={kdvList.map((item: any) => ({
                                                value: item.value,
                                                label: `${item.value}%`
                                            }))}
                                            onChange={(e: any) => {
                                                validation.setFieldValue('sellingKDV', e.value);
                                            }}
                                            value={kdvList.find((option: any) => option.value === validation.values.sellingKDV)}
                                            isSearchable={false}
                                            name="sellingKDV"
                                            id="sellingKDV"
                                        />
                                        {validation.errors.sellingKDV && validation.touched.sellingKDV && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.sellingKDV}</div>
                                        )}
                                        <span className="absolute right-3 top-3 text-gray-500 dark:text-zinc-200">%</span>
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="buyingPrice" className="inline-block mb-2 text-base font-medium">Alış Fiyatı</label>
                                        <input type="text" id="buyingPrice" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Alış Fiyatı" required
                                            onChange={validation.handleChange}
                                            value={validation.values.buyingPrice}
                                        />
                                        {validation.errors.buyingPrice && validation.touched.buyingPrice && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.buyingPrice}</div>
                                        )}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="sellingPrice" className="inline-block mb-2 text-base font-medium">Satış Fiyatı</label>
                                        <input type="text" id="sellingPrice"
                                            onChange={validation.handleChange}
                                            value={validation.values.sellingPrice}
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Satış Fiyatı" required
                                        />
                                        {validation.errors.sellingPrice && validation.touched.sellingPrice && (
                                            <div className="text-red-500 text-sm mt-1">{validation.errors.sellingPrice}</div>
                                        )}
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="showProduct" className="inline-block mb-2 text-base font-medium mr-5">Ürün Menüde Gözüksün</label>
                                        <input type="checkbox" id="showProduct"
                                            onChange={validation.handleChange}
                                            checked={validation.values.showProduct}
                                            className="form-checkbox border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500"
                                        />
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="profitRate" className="inline-block mb-2 text-base font-medium">Kâr Oranı (%)</label>
                                        <input type="text" id="profitRate" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Kâr Oranı" readOnly
                                            value={validation.values.profitRate}
                                        />
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="totalBuyingKDV" className="inline-block mb-2 text-base font-medium">Toplam Alış KDV</label>
                                        <input type="text" id="totalBuyingKDV" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Toplam Alış KDV" readOnly
                                            value={validation.values.totalBuyingKDV}
                                        />
                                    </div>
                                    <div className="xl:col-span-6">
                                        <label htmlFor="totalSellingKDV" className="inline-block mb-2 text-base font-medium">Toplam Satış KDV</label>
                                        <input type="text" id="totalSellingKDV" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Toplam Satış KDV" readOnly
                                            value={validation.values.totalSellingKDV}
                                        />
                                    </div>
                                    <div className="lg:col-span-2 xl:col-span-12">
                                        <label htmlFor="genderSelect" className="inline-block mb-2 text-base font-medium">Ürün Resmi</label>
                                        <Dropzone
                                            onDrop={
                                                (files: any) => {
                                                    onDrop({ files, wrapperSetCroppedImage, wrapperSetOpen });
                                                }}
                                            multiple={false}
                                        >
                                            {({ getRootProps, getInputProps }: any) => (
                                                <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dark:bg-zink-700 dark:border-zink-500 dropzone2">
                                                    <input {...getInputProps()} />
                                                    <div className="w-full py-5 text-lg text-center dz-message needsclick" {...getRootProps()} >
                                                        <div className="mb-3">
                                                            <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                                                        </div>
                                                        <h5 className="mb-0 font-normal text-slate-500 dark:text-zink-200 text-15">
                                                            Resimleri buraya sürükleyip bırakın veya
                                                            <Link to="#!">  seçin</Link> </h5>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <CropperC
                                            image={croppedImage}
                                            open={open}
                                            setOpen={setOpen}
                                            onCropDone={() => onCropDone({ cropperRef, handleAcceptfiles, wrapperSetOpen })}
                                            cropperRef={cropperRef}
                                        />
                                        <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                            {selectfiles.map((file: any, index: number) => (
                                                <li className="mt-5" id="dropzone-preview-list2" key={index}>
                                                    <div className="border rounded border-slate-200 dark:border-zink-500">
                                                        <div className="p-2 text-center">
                                                            <div>
                                                                <div className="p-2 mx-auto rounded-md size-30 bg-slate-100 dark:bg-zink-600">
                                                                    <img className="block w-full h-full rounded-md" src={file.priview} alt={file.name} />
                                                                </div>
                                                            </div>
                                                            <div className="pt-3">
                                                                <h5 className="mb-1 text-15" data-dz-name>{file.path}</h5>
                                                                <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{file.formattedSize}</p>
                                                                <strong className="error text-danger" data-dz-errormessage></strong>
                                                            </div>
                                                            <div className="mt-2">
                                                                <button data-dz-remove className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20" onClick={() => {
                                                                    const newImages = [...selectfiles];
                                                                    newImages.splice(index, 1);
                                                                    setSelectfiles(newImages);
                                                                }}>Sil</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="lg:col-span-2 xl:col-span-12">
                                        <div>
                                            <label htmlFor="productDescription" className="inline-block mb-2 text-base font-medium">Ürün Açıklaması</label>
                                            <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" id="productDescription" placeholder="Ürün Açıklaması" rows={5}
                                                onChange={validation.handleChange}
                                                value={validation.values.productDescription}
                                            ></textarea>
                                            {validation.errors.productDescription && validation.touched.productDescription && (
                                                <div className="text-red-500 text-sm mt-1">{validation.errors.productDescription}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="lg:col-span-2 xl:col-span-12">
                                        <div>
                                            <label htmlFor="productNutritions" className="inline-block mb-2 text-base font-medium">Besin Değerleri</label>
                                            <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" id="productNutritions" placeholder="Besin Değerleri" rows={5}
                                                onChange={validation.handleChange}
                                                value={validation.values.productNutritions}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Temizle</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {id ? 'Ürünü Güncelle' : 'Ürün Oluştur'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-3">
                    <div className="card sticky top-[calc(theme('spacing.header')_*_1.3)]">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Ürün Ön Gösterimi</h6>
                            <div className="mt-3">
                                <h5 className="mb-2">
                                    {validation.values.sellingPrice ? `₺${validation.values.sellingPrice}` : '₺0.00'}
                                </h5>
                                <h6 className="mb-1 text-15">
                                    {validation.values.productTitle ? validation.values.productTitle : 'Ürün Adı'}
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">
                                    {validation.values.productDescription ? validation.values.productDescription : 'Ürün Açıklaması'}
                                </p>
                                <p className="text-slate-500 dark:text-zink-200">
                                    {`Kâr Oranı: ${validation.values.profitRate}%`}
                                </p>
                                <p className="text-slate-500 dark:text-zink-200">
                                    {`Toplam Alış KDV: ₺${validation.values.totalBuyingKDV}`}
                                </p>
                                <p className="text-slate-500 dark:text-zink-200">
                                    {`Toplam Satış KDV: ₺${validation.values.totalSellingKDV}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddNew;
