import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const AppVersionCode = () => {
    const location = useLocation();
    const { id } = location.state || {};

    const [versionCodeData, setVersionCodeData] = useState<any>(null);

    const navigate = useNavigate();

    const validation = useFormic({
        initialValues: {
            versionCode: '',
            isForced: false,
        },

        onSubmit: async (values) => {
            const body = {
                versionCode: Number(values.versionCode),
                isForced: values.isForced,
            };

            const updateBody = {
                versionCode: Number(values.versionCode),
                isForced: values.isForced,
                id: versionCodeData?.id
            }

            try {
                if (versionCodeData?.versionCode) {
                    const response = await axios.put(`version-code/update/${versionCodeData?._id}`, updateBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });

                    if (response) {
                        navigate(0);
                    }
                } else {
                    const response = await axios.post('version-code', body, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });

                    if (response) {
                        navigate(0);
                    }
                }
                // Optionally navigate or perform further actions
            } catch (error) {
                console.error('Error posting the data', error);
            }
        },
    });

    const getVersionCodeMutation = useMutation({
        mutationFn: async () =>
            await axios.get('version-code'),
        onSuccess: (data: any) => {
            console.log('data', data);
            setVersionCodeData(data);
            validation.setValues({
                versionCode: data.versionCode,
                isForced: data.isForced,
            });

        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    useEffect(() => {
        getVersionCodeMutation.mutate();
    }, []);

    if (getVersionCodeMutation.isPending) return <div>Loading...</div>;

    return (
        <React.Fragment>
            <BreadCrumb title='Versiyon Kodu Güncelle' pageTitle='Versiyon Kodu Güncelle' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                <div className="col-span-12">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Versiyon Kodu Güncelle</h6>

                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                            }}>
                                <div className="grid grid-cols-12 gap-5 lg:grid-cols-12 xl:grid-cols-12">
                                    <div className="col-span-12">
                                        <label htmlFor="versionCode" className="inline-block mb-2 text-base font-medium">Versiyon Kodu Güncelle</label>
                                        <input
                                            type="number"
                                            id="versionCode"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Versiyon Kodu"
                                            required
                                            onChange={validation.handleChange}
                                            value={validation.values.versionCode}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label htmlFor="isForced" className="inline-block mb-2 text-base font-medium">Zorunlu Güncelleme</label>
                                        <input
                                            type="checkbox"
                                            id="isForced"
                                            className="form-checkbox ml-5 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            onChange={validation.handleChange}
                                            checked={validation.values.isForced}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Sıfırla</button>
                                    <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {versionCodeData?._id ? 'Versiyon Kodu Güncelle' : 'Versiyon Kodu Ekle'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AppVersionCode;
