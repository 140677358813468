import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Swal from "sweetalert2";
// Icons
import { Search, Trash2, Plus } from "lucide-react";
import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import axios from "axios";
import { APIClient, imageUrl } from "helpers/api_helper";
import { useMutation } from "@tanstack/react-query";

const api = new APIClient();

const PaymentMethodsListView = () => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]); // Initialize with an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const safePaymentMethods = Array.isArray(paymentMethods)
    ? paymentMethods
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = safePaymentMethods.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(safePaymentMethods.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-3 py-1 border border-slate-300 ${
            i === currentPage ? "bg-slate-100" : "bg-white"
          }`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;
  
    const items = Array.from(paymentMethods);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    const updatedPaymentMethods = items.map((item: any, index) => ({
      ...item,
      order: index,
    }));
  
    try {
      await axios.post("/payment-methods/update-order", {
        paymentMethods: updatedPaymentMethods,
      });
      setPaymentMethods(updatedPaymentMethods)
    } catch (error) {
      console.error("Error updating payment method order", error);
      Swal.fire("Error", "Could not update order", "error");
    }
  };
  const getPaymentMethods = async () => {
    try {
      const response: any = await axios.get("/payment-methods/list");
      setPaymentMethods(response);
    } catch (error) {
      console.error("Error fetching payment methods", error);
      setPaymentMethods([]);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["paymentMethod"];
    filterDataBySearch(paymentMethods, search, keysToSearch, setPaymentMethods);
  };

  const handlePaymentMethodDelete = (id: string) => {
    Swal.fire({
      title: "Ödeme yöntemini silmek istiyor musunuz?",
      text: "Bu işlemi geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Hayır, iptal et",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handlePaymentMethodMutation.mutate(id);
      }
    });
  };

  const handlePaymentMethodMutation = useMutation({
    mutationFn: (id: string) => {
      return api.delete(`/payment-methods/delete/${id}`, null);
    },
    onSuccess: (data: any) => {
      Swal.fire("Silindi!", "Ödeme yöntemi başarıyla silindi.", "success");
      setPaymentMethods((prev: any) =>
        prev.filter((item: any) => item._id !== data._id)
      );
    },
    onError: (error) => {
      console.log("error", error);
      Swal.fire("Hata!", "Ödeme yöntemi silinirken bir hata oluştu.", "error");
    },
  });

  
  

  

  return (
    <React.Fragment>
      <BreadCrumb
        title="Ödeme Yöntemleri Listesi"
        pageTitle="Ödeme Yöntemleri Listesi"
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>

                  <div className="lg:col-span-2 ltr:lg:text-right rtl:lg:text-left xl:col-span-2 xl:col-start-11">
                    <Link
                      to="/add-payment-method"
                      type="button"
                      className="text-white btn bg-custom-500 w-[180px] border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <Plus className="inline-block size-4" />{" "}
                      <span className="align-middle">Ödeme Yöntemi Ekle</span>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
            <div className="xl:col-span-12">
              <div className="card">
                <div className="card-body">
                  {currentItems && currentItems.length > 0 ? (
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="paymentMethods">
                        {(provided) => (
                          <table className="min-w-full table-auto border-collapse border border-slate-300">
                            <thead>
                              <tr className="bg-slate-100">
                                <th className="border border-slate-300 px-4 py-2 text-left">
                                  Ödeme Yöntemi
                                </th>
                                <th className="border border-slate-300 px-4 py-2 text-left">
                                  Oluşturulma Tarihi
                                </th>
                                <th className="border border-slate-300 px-4 py-2 text-left">
                                  Aksiyon
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {currentItems.map(
                                (method: any, index: number) => (
                                  <Draggable
                                    key={method._id}
                                    draggableId={method._id}
                                    index={index + indexOfFirstItem} // Adjust index for correct reordering
                                  >
                                    {(provided) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="bg-white hover:bg-gray-100"
                                      >
                                        <td className="border border-slate-300 px-4 py-2">
                                          {method.paymentMethod}
                                        </td>
                                        <td className="border border-slate-300 px-4 py-2">
                                          {method.createdAt}
                                        </td>
                                        <td className="border border-slate-300 px-4 py-2 ">
                                          <button
                                            className="text-red-600"
                                            onClick={() =>
                                              handlePaymentMethodDelete(
                                                method._id
                                              )
                                            }
                                          >
                                            <Trash2 />
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </tbody>
                          </table>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <div className="noresult">No payment methods found.</div>
                  )}
                </div>
                <div className="pagination py-2 flex justify-center gap-3">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 border border-slate-300 bg-white hover:bg-slate-100 rounded-md"
                  >
                    Geri
                  </button>
                  <div className="flex">{renderPagination()}</div>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 border border-slate-300 bg-white hover:bg-slate-100 rounded-md"
                  >
                    İleri
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodsListView;
