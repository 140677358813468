import React, { useState, useRef, useEffect } from 'react';

interface Label {
    _id: string;
    name: string;
    color: string;
}

interface CustomDropdownProps {
    labels: Label[];
    selectedLabels: Label[];
    onSelect: (labelId: string) => void;
    onDelete: (labelId: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ labels, selectedLabels, onSelect, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (labelId: string) => {
        onSelect(labelId);
        setIsOpen(false);
    };

    const handleDeleteClick = (e: React.MouseEvent, labelId: string) => {
        e.stopPropagation();
        onDelete(labelId);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <div className="flex items-center">
                {selectedLabels.map((label) => (
                    <span key={label._id} className="inline-flex items-center p-1 text-xs rounded bg-gray-200 text-black mr-2">
                        <span className={`bg-${label.color}-500 p-1 rounded`}>{label.name}</span>
                        <span
                            onClick={(e) => handleDeleteClick(e, label._id)}
                            className="ml-1 w-5 h-5 text-red-500 cursor-pointer flex items-center justify-center"
                        >
                            <i className="fas fa-times"></i>
                        </span>
                    </span>
                ))}
                <button onClick={toggleDropdown} className="p-2 bg-gray-200 rounded">
                    {selectedLabels.length === 0 ? '+ Gruba Ekle' : '+'}
                </button>
            </div>
            {isOpen && (
                <div className="absolute right-0 bottom-full mt-2 bg-white border rounded-xl border-gray-200 shadow-2xl z-10">
                    {labels.map((label) => (
                        <div
                            key={label._id}
                            onClick={() => handleSelect(label._id)}
                            className={`p-2 cursor-pointer hover:bg-${label.color}-200`}
                        >
                            <span className={`inline-block p-1 text-xs rounded bg-${label.color}-500 text-white`}>
                                {label.name}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
