import { APIClient } from './api_helper';

import * as url from './url_helper';

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedUser() !== null;
};

// Register Method
export const postRegister = (data: any) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = (data: any) => api.create(url.POST_LOGIN, data);
