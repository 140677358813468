import { useMutation } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React, { useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { useParams } from 'react-router-dom';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

type Props = {};

const api = new APIClient();

const UserControlDetail = (props: Props) => {
    const [userTransactions, setUserTransactions] = useState<any>([]);
    const [userWalletTransactions, setUserWalletTransactions] = useState<any>([]);
    const [value, setValue] = useState<DateValueType>({
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      });
    const { id } = useParams();

    const [typeOfData, setTypeOfData] = useState('transactions');

    const getUserTransactionsMutation = useMutation({
        mutationFn: () => {
            return api.get(`/wallet/get-user-transactions/${id}?startDate=${
                typeof value?.startDate === "string"
                  ? value?.startDate
                  : value?.startDate?.toISOString().slice(0, 10)
              }&endDate=${
                typeof value?.endDate === "string"
                  ? value?.endDate
                  : value?.endDate?.toISOString().slice(0, 10)
              }`, null);
        },
        onSuccess: (data) => {
            setUserTransactions(data);
        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    useEffect(() => {
        getUserTransactionsMutation.mutate();
    }, [value?.startDate, value?.endDate]);

    useEffect(() => {
        getUserWalletTransactionMutation.mutate();
    }, []);

    const handleValueChange = (newValue: any) => {
        setValue(newValue);
        getUserTransactionsMutation.mutate();
    }

    const getUserWalletTransactionMutation = useMutation(
        {
            mutationFn: () => {
                return api.get(`/wallet-transactions/by-user/${id}`, null);
            },
            onSuccess: (data: any) => {
                setUserWalletTransactions(data)
            },
            onError: (error) => {
                console.log('error', error);
            }
        }
    );

    if (getUserTransactionsMutation.isPending || getUserWalletTransactionMutation.isPending) {
        return (
            <div role="status" className="h-screen flex items-center justify-center">
                <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }

    const formatTransactionData = (transaction: any) => {
        return transaction.boughtItems.map((item: any) => ({
            userEmail: transaction.user.email,
            userName: `${transaction.user.name} ${transaction.user.lastname}`,
            userPhoneNumber: transaction.user.phoneNumber,
            createdAt: new Date(transaction.createdAt).toLocaleString(),
            updatedAt: new Date(transaction.updatedAt).toLocaleString(),
            total:
                transaction.boughtItems
                    .reduce((acc: any, item: any) => acc + Number(item.sellingPrice), 0)
                    .toFixed(2) + '₺',
            productTitle: item.productTitle,
            productDescription: item.productDescription,
            category: item.category,
            barcodeNumber: item.barcodeNumber,
            buyingPrice: item.buyingPrice + '₺',
            sellingPrice: item.sellingPrice + '₺',
            quantity: item.quantity,
            tax: ((Number(item.sellingPrice) * Number(item.sellingKDV)) / 100).toFixed(2) + ' TL'
        }));
    };

    const groupByMonth = (transactions: any) => {
        return transactions.reduce((acc: any, transaction: any) => {
            const month = new Date(transaction.createdAt).toLocaleString('default', {
                month: 'long'
            });
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(transaction);
            return acc;
        }, {});
    };

    const transactionsByMonth = groupByMonth(userTransactions);

    const transactionByMonthForWallet = groupByMonth(userWalletTransactions);

    return (
        <div className="p-6 bg-white min-h-screen">
            <div className="flex justify-between items-center mb-6">
                <div className='w-1/3'>
                    <Datepicker
                    displayFormat={"DD/MM/YYYY"} 
                        value={value}
                        onChange={handleValueChange}
                    />
                </div>
                <div className='w-1/3 flex items-center justify-center'>
                    <div className={`
                        flex items-center
                        
                    `}>
                        <button
                            onClick={() => setTypeOfData('transactions')}
                            className={`btn
                            border-2 border-gray-200
                             ${typeOfData === 'transactions' ? 'btn-primary bg-blue-500 text-white' : 'btn-secondary bg-white text-black'} mr-2`}
                        >
                            Harcamalar
                        </button>
                        <button
                            onClick={() => setTypeOfData('walletTransactions')}
                            className={`btn ${typeOfData === 'walletTransactions' ? 'btn-primary bg-blue-500 text-white' : 'btn-secondary bg-white text-black'}
                            border-2 border-gray-200
                            `}
                        >
                            Yüklemeler
                        </button>
                    </div>
                </div>
            </div>

            {
                typeOfData === 'transactions' && (
                    Object.keys(transactionsByMonth).map((month) => (
                        <div key={month} className="mb-8">
                            <div className="flex items-center justify-center">
                                <div className="py-2 px-4 flex justify-center items-center rounded-tl-md rounded-bl-md">
                                    <h2 className="text-sm font-bold">{month}</h2>
                                </div>
                                <div className="flex-grow h-full flex items-center">
                                    <div className="w-full h-[0.9px] bg-black"></div>
                                </div>
                            </div>
                            {transactionsByMonth[month].map((transaction: any) => (
                                <>
                                    <div key={transaction._id} className="mb-6 p-4 rounded-lg bg-white shadow-sm">
                                        <div className='flex items-center justify-between mt-10'>
                                            <p><span className="font-medium">Fiş Tarihi:</span>{
                                                <span className='ml-2'>
                                                    {transaction.createdAt.slice(0, 10)}
                                                </span>
                                            }</p>
                                            <CsvDownloader
                                                filename={`islem-detay-${transaction._id}`}
                                                extension=".csv"
                                                separator=";"
                                                columns={[
                                                    { id: 'transactionId', displayName: 'Transaction ID' },
                                                    { id: 'userName', displayName: 'Kullanıcı Adı' },
                                                    { id: 'userPhoneNumber', displayName: 'Telefon Numarası' },
                                                    { id: 'createdAt', displayName: 'İşlem Oluşturma Tarihi' },
                                                    { id: 'updatedAt', displayName: 'İşlem Güncelleme Tarihi' },
                                                    { id: 'total', displayName: 'Toplam' },
                                                    { id: 'productTitle', displayName: 'Ürün Başlığı' },
                                                    { id: 'productDescription', displayName: 'Ürün Açıklaması' },
                                                    { id: 'category', displayName: 'Kategori' },
                                                    { id: 'barcodeNumber', displayName: 'Barkod Numarası' },
                                                    { id: 'buyingPrice', displayName: 'Alış Fiyatı' },
                                                    { id: 'sellingPrice', displayName: 'Satış Fiyatı' },
                                                    { id: 'quantity', displayName: 'Miktar' },
                                                    { id: 'tax', displayName: 'Vergi' }
                                                ]}
                                                datas={formatTransactionData(transaction)}
                                            >
                                                <button className="btn btn-primary flex items-center justify-center hover:bg-blue-600 bg-blue-500 h-12 cursor-pointer text-white">
                                                    Excel Olarak İndir
                                                </button>
                                            </CsvDownloader>
                                        </div>
                                        <p className="text-sm text-gray-600 mt-5">
                                            <span className="font-medium">Fiş Notu:</span>
                                            <span className='ml-2'>
                                                {
                                                    transaction.checkNote ? transaction.checkNote : 'Yok'
                                                }
                                            </span>
                                        </p>
                                        <div className="mt-4">
                                            <table className="min-w-full bg-white border overflow-hidden rounded-tr-lg rounded-tl-lg">
                                                <thead className="bg-gray-100 border-b rounded-tr-lg rounded-tl-lg">
                                                    <tr>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Ürün Adı</th>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Miktar</th>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Vergi</th>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Fiyat</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transaction.boughtItems.map((item: any, index: number) => (
                                                        <tr key={index} className="border-b">
                                                            <td className="px-4 py-3 text-sm">{item.productTitle}</td>
                                                            <td className="px-4 py-3 text-sm">{item.quantity}</td>
                                                            <td className="px-4 py-3 text-sm">{
                                                                Number(item.sellingPrice) * item.quantity * Number((item.sellingKDV / 100))
                                                            }</td>
                                                            <td className="px-4 py-3 text-sm">{item.sellingPrice} TL</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='w-[90%] mx-auto h-[0.5px] mt-10 bg-black'></div>
                                </>
                            ))}
                        </div>
                    ))
                )
            }

            {
                typeOfData === 'walletTransactions' && (

                    Object.keys(transactionByMonthForWallet).map((month) => (
                        <div key={month} className="mb-8">
                            <div className="flex items-center justify-center">
                                <div className="py-2 px-4 flex justify-center items-center rounded-tl-md rounded-bl-md">
                                    <h2 className="text-sm font-bold">{month} (Cüzdan İşlemleri)</h2>
                                </div>
                                <div className="flex-grow h-full flex items-center">
                                    <div className="w-full h-[0.9px] bg-black"></div>
                                </div>
                            </div>
                            {transactionByMonthForWallet[month].map((transaction: any) => (
                                <>
                                    <div key={transaction._id} className="mb-6 p-4 rounded-lg bg-white shadow-sm">
                                        <div className='flex items-center justify-between mt-10'>
                                            <p><span className="font-medium">Fiş Notu:</span>{
                                                <span className='ml-2'>
                                                    {transaction.createdAt.slice(0, 10)}
                                                </span>
                                            }</p>
                                            <CsvDownloader
                                                filename={`wallet-transaction-${transaction._id}`}
                                                extension=".csv"
                                                separator=";"
                                                columns={[
                                                    { id: 'transactionId', displayName: 'Transaction ID' },
                                                    { id: 'userName', displayName: 'Kullanıcı Adı' },
                                                    { id: 'userPhoneNumber', displayName: 'Telefon Numarası' },
                                                    { id: 'createdAt', displayName: 'İşlem Oluşturma Tarihi' },
                                                    { id: 'updatedAt', displayName: 'İşlem Güncelleme Tarihi' },
                                                    { id: 'amount', displayName: 'Tutar' },
                                                    { id: 'type', displayName: 'Tür' }
                                                ]}
                                                datas={[{
                                                    transactionId: transaction._id,
                                                    userName: `${transaction.owner.name} ${transaction.owner.lastname}`,
                                                    userPhoneNumber: transaction.owner.phoneNumber,
                                                    createdAt: new Date(transaction.createdAt).toLocaleString(),
                                                    updatedAt: new Date(transaction.updatedAt).toLocaleString(),
                                                    amount: transaction.amount + '₺',
                                                    type: transaction.type
                                                }]}
                                            >
                                                <button className="btn btn-primary flex items-center justify-center hover:bg-blue-600 bg-blue-500 h-12 cursor-pointer text-white">
                                                    Excel Olarak İndir
                                                </button>
                                            </CsvDownloader>
                                        </div>

                                        <div className="mt-4">
                                            <table className="min-w-full bg-white border overflow-hidden rounded-tr-lg rounded-tl-lg">
                                                <thead className="bg-gray-100 border-b rounded-tr-lg rounded-tl-lg">
                                                    <tr>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Tutar</th>
                                                        <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Tür</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-b">
                                                        <td className="px-4 py-3 text-sm">{transaction.amount}₺</td>
                                                        <td className="px-4 py-3 text-sm">{
                                                            transaction.type === 'spent' ? 'Harcama' : 'Yükleme'
                                                        }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='w-[90%] mx-auto h-[0.5px] mt-10 bg-black'></div>
                                </>
                            ))}
                        </div>
                    ))

                )
            }
        </div>
    );
};

export default UserControlDetail;
