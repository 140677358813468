import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getOrders as getOrdersApi,
  addOrders as addOrdersApi,
  updateOrders as updateOrdersApi,
  deleteOrders as deleteOrdersApi,
  getSellers as getSellersApi,
  addSellers as addSellersApi,
  updateSellers as updateSellersApi,
  deleteSellers as deleteSellersApi,
  getProductList as getProductListApi,
  addProductList as addProductListApi,
  updateProductList as updateProductListApi,
  deleteProductList as deleteProductListApi,
  getProductGrid as getProductGridApi,
  addProductGrid as addProductGridApi,
  updateProductGrid as updateProductGridApi,
  deleteProductGrid as deleteProductGridApi,
  getReview as getReviewApi,
  addReview as addReviewApi,
  updateReview as updateReviewApi,
  deleteReview as deleteReviewApi
} from '../../helpers/fakebackend_helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import productImg1 from 'assets/images/product/img-01.png';
import productImg2 from 'assets/images/product/img-02.png';
import productImg3 from 'assets/images/product/img-03.png';
import productImg4 from 'assets/images/product/img-04.png';
import productImg5 from 'assets/images/product/img-05.png';
import productImg6 from 'assets/images/product/img-06.png';
import productImg7 from 'assets/images/product/img-07.png';
import productImg8 from 'assets/images/product/img-08.png';
import productImg9 from 'assets/images/product/img-09.png';
import productImg10 from 'assets/images/product/img-10.png';
import productImg11 from 'assets/images/product/img-11.png';

export const getOrders = createAsyncThunk('ecommerce/getOrders', async () => {
  try {
    const response = getOrdersApi();
    // const response = [
    //   {
    //     id: 1,
    //     orderId: '#TWT5015100365',
    //     orderDate: '08 Jun, 2023',
    //     deliveryDate: '15 Jun, 2023',
    //     customerName: 'Marie Prohaska',
    //     paymentMethod: 'Credit Card',
    //     amount: '$541.32',
    //     deliveryStatus: 'Delivered'
    //   },
    //   {
    //     id: 2,
    //     orderId: '#TWT5015100366',
    //     orderDate: '11 July, 2023',
    //     deliveryDate: '20 July, 2023',
    //     customerName: 'Jaqueline Hammes',
    //     paymentMethod: 'Paypal',
    //     amount: '$1,876.33',
    //     deliveryStatus: 'Shipping'
    //   },
    //   {
    //     id: 3,
    //     orderId: '#TWT5015100367',
    //     orderDate: '21 Aug, 2023',
    //     deliveryDate: '24 Aug, 2023',
    //     customerName: 'Marlene Hirthe',
    //     paymentMethod: 'Visa Card',
    //     amount: '$15,023.99',
    //     deliveryStatus: 'New'
    //   },
    //   {
    //     id: 4,
    //     orderId: '#TWT5015100368',
    //     orderDate: '28 Nov, 2023',
    //     deliveryDate: '02 Dec, 2023',
    //     customerName: 'Reagan Larson',
    //     paymentMethod: 'American Express',
    //     amount: '$783.56',
    //     deliveryStatus: 'Delivered'
    //   },
    //   {
    //     id: 5,
    //     orderId: '#TWT5015100369',
    //     orderDate: '11 Oct, 2023',
    //     deliveryDate: '16 Oct, 2023',
    //     customerName: 'Glennie Langosh',
    //     paymentMethod: 'American Express',
    //     amount: '$986.49',
    //     deliveryStatus: 'Pending'
    //   },
    //   {
    //     id: 6,
    //     orderId: '#TWT5015100370',
    //     orderDate: '16 Feb, 2023',
    //     deliveryDate: '22 Feb, 2023',
    //     customerName: 'Rickie Cremin',
    //     paymentMethod: 'COD',
    //     amount: '$741.32',
    //     deliveryStatus: 'Delivered'
    //   },
    //   {
    //     id: 7,
    //     orderId: '#TWT5015100371',
    //     orderDate: '18 April, 2023',
    //     deliveryDate: '29 April, 2023',
    //     customerName: 'Domenic Tromp',
    //     paymentMethod: 'Credit Card',
    //     amount: '$1,386.49',
    //     deliveryStatus: 'Shipping'
    //   },
    //   {
    //     id: 8,
    //     orderId: '#TWT5015100372',
    //     orderDate: '21 Jan, 2024',
    //     deliveryDate: '24 Jan, 2023',
    //     customerName: 'Akeem Paucek',
    //     paymentMethod: 'PayPal',
    //     amount: '$3,487.21',
    //     deliveryStatus: 'New'
    //   },
    //   {
    //     id: 9,
    //     orderId: '#TWT5015100373',
    //     orderDate: '05 Oct, 2024',
    //     deliveryDate: '11 Oct, 2023',
    //     customerName: 'David Gaby',
    //     paymentMethod: 'COD',
    //     amount: '$5,986.11',
    //     deliveryStatus: 'Return'
    //   },
    //   {
    //     id: 10,
    //     orderId: '#TWT5015100374',
    //     orderDate: '07 Oct, 2024',
    //     deliveryDate: '13 Oct, 2023',
    //     customerName: 'Joel Freeleagus',
    //     paymentMethod: 'American Express',
    //     amount: '$9,451.75',
    //     deliveryStatus: 'Cancelled'
    //   }
    // ];
    return response;
  } catch (error) {
    return error;
  }
});
export const addOrders = createAsyncThunk('ecommerce/addOrders', async (event: any) => {
  try {
    const response = addOrdersApi(event);
    const data = await response;
    toast.success('Order Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Order Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateOrders = createAsyncThunk('ecommerce/updateOrders', async (event: any) => {
  try {
    const response = updateOrdersApi(event);
    const data = await response;
    toast.success('Order updated Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Order updated Failed', { autoClose: 2000 });
    return error;
  }
});
export const deleteOrders = createAsyncThunk('ecommerce/deleteOrders', async (event: any) => {
  try {
    const response = deleteOrdersApi(event);
    toast.success('Order deleted Successfully', { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error('Order deleted Failed', { autoClose: 2000 });
    return error;
  }
});

export const getSellers = createAsyncThunk('ecommerce/getSellers', async () => {
  try {
    const response = getSellersApi();
    return response;
  } catch (error) {
    return error;
  }
});
export const addSellers = createAsyncThunk('ecommerce/addSellers', async (event: any) => {
  try {
    const response = addSellersApi(event);
    const data = await response;
    toast.success('Seller Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Seller Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateSellers = createAsyncThunk('ecommerce/updateSellers', async (event: any) => {
  try {
    const response = updateSellersApi(event);
    const data = await response;
    toast.success('Seller updated Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Seller updated Failed', { autoClose: 2000 });
    return error;
  }
});
export const deleteSellers = createAsyncThunk('ecommerce/deleteSellers', async (event: any) => {
  try {
    const response = deleteSellersApi(event);
    toast.success('Seller deleted Successfully', { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error('Seller deleted Failed', { autoClose: 2000 });
    return error;
  }
});

export const getProductList = createAsyncThunk('ecommerce/getProductList', async () => {
  try {
    const response = getProductListApi();
    // const response = [
    //   {
    //     id: 1,
    //     productCode: '#TAD-232100071',
    //     img: productImg2,
    //     productName: 'Smartest Printed T-shirt',
    //     category: 'Fashion',
    //     price: '$79.99',
    //     stock: 500,
    //     revenue: '$24,365',
    //     status: 'Scheduled'
    //   },
    //   {
    //     id: 2,
    //     productCode: '#TAD-232100072',
    //     img: productImg3,
    //     productName: 'Mesh Ergonomic Black Chair',
    //     category: 'Furniture',
    //     price: '$214.47',
    //     stock: 400,
    //     revenue: '$1,24,365',
    //     status: 'Publish'
    //   },
    //   {
    //     id: 3,
    //     productCode: '#TAD-232100073',
    //     img: productImg5,
    //     productName: 'Fastcolors Typography Men',
    //     category: 'Fashion',
    //     price: '$119.12',
    //     stock: 600,
    //     revenue: '$80,321',
    //     status: 'Inactive'
    //   },
    //   {
    //     id: 4,
    //     productCode: '#TAD-232100074',
    //     img: productImg6,
    //     productName: 'Techel Black Bluetooth Soundbar',
    //     category: 'Electronics',
    //     price: '$452.99',
    //     stock: 300,
    //     revenue: '$49,234',
    //     status: 'Publish'
    //   },
    //   {
    //     id: 5,
    //     productCode: '#TAD-232100075',
    //     img: productImg7,
    //     productName: 'Bovet Fleurier AIFSQ029',
    //     category: 'Home Decor',
    //     price: '$119.99',
    //     stock: 240,
    //     revenue: '$20,784',
    //     status: 'Publish'
    //   },
    //   {
    //     id: 6,
    //     productCode: '#TAD-232100076',
    //     img: productImg8,
    //     productName: 'Iron 1000 W Dry',
    //     category: 'Electronics',
    //     price: '$84.99',
    //     stock: 110,
    //     revenue: '$15,493',
    //     status: 'Scheduled'
    //   },
    //   {
    //     id: 7,
    //     productCode: '#TAD-232100077',
    //     img: productImg9,
    //     productName: 'Roar Twill Blue Baseball Cap',
    //     category: 'Fashion',
    //     price: '$49.99',
    //     stock: 470,
    //     revenue: '$19,321',
    //     status: 'Publish'
    //   },
    //   {
    //     id: 8,
    //     productCode: '#TAD-232100078',
    //     img: productImg10,
    //     productName: 'Crop tops for Women western wear',
    //     category: 'Fashion',
    //     price: '$129.99',
    //     stock: 206,
    //     revenue: '$21,307',
    //     status: 'Inactive'
    //   },
    //   {
    //     id: 9,
    //     productCode: '#TAD-232100079',
    //     img: productImg11,
    //     productName: 'Smartees Printed Men Round Neck White',
    //     category: 'Fashion',
    //     price: '$410.43',
    //     stock: 230,
    //     revenue: '$22,012',
    //     status: 'Scheduled'
    //   },
    //   {
    //     id: 10,
    //     productCode: '#TAD-232100080',
    //     img: productImg8,
    //     productName: 'Aahwan Solid Women Beige Baggy Shorts',
    //     category: 'Fashion',
    //     price: '$64.70',
    //     stock: 150,
    //     revenue: '$13,987',
    //     status: 'Publish'
    //   }
    // ];
    return response;
  } catch (error) {
    return error;
  }
});
export const addProductList = createAsyncThunk('ecommerce/addProductList', async (event: any) => {
  try {
    const response = addProductListApi(event);
    const data = await response;
    toast.success('Data Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Data Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateProductList = createAsyncThunk(
  'ecommerce/updateProductList',
  async (event: any) => {
    try {
      const response = updateProductListApi(event);
      const data = await response;
      toast.success('Data updated Successfully', { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error('Data updated Failed', { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteProductList = createAsyncThunk(
  'ecommerce/deleteProductList',
  async (event: any) => {
    try {
      const response = deleteProductListApi(event);
      toast.success('Data deleted Successfully', { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error('Data deleted Failed', { autoClose: 2000 });
      return error;
    }
  }
);

export const getProductGrid = createAsyncThunk('ecommerce/getProductGrid', async () => {
  try {
    const response = getProductGridApi();
    return response;
  } catch (error) {
    return error;
  }
});
export const addProductGrid = createAsyncThunk('ecommerce/addProductGrid', async (event: any) => {
  try {
    const response = addProductGridApi(event);
    const data = await response;
    toast.success('Data Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Data Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateProductGrid = createAsyncThunk(
  'ecommerce/updateProductGrid',
  async (event: any) => {
    try {
      const response = updateProductGridApi(event);
      const data = await response;
      toast.success('Data updated Successfully', { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error('Data updated Failed', { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteProductGrid = createAsyncThunk(
  'ecommerce/deleteProductGrid',
  async (event: any) => {
    try {
      const response = deleteProductGridApi(event);
      toast.success('Data deleted Successfully', { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error('Data deleted Failed', { autoClose: 2000 });
      return error;
    }
  }
);

export const getReview = createAsyncThunk('ecommerce/getReview', async () => {
  try {
    const response = getReviewApi();
    return response;
  } catch (error) {
    return error;
  }
});
export const addReview = createAsyncThunk('ecommerce/addReview', async (event: any) => {
  try {
    const response = addReviewApi(event);
    const data = await response;
    toast.success('Data Added Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Data Added Failed', { autoClose: 2000 });
    return error;
  }
});
export const updateReview = createAsyncThunk('ecommerce/updateReview', async (event: any) => {
  try {
    const response = updateReviewApi(event);
    const data = await response;
    toast.success('Data updated Successfully', { autoClose: 2000 });
    return data;
  } catch (error) {
    toast.error('Data updated Failed', { autoClose: 2000 });
    return error;
  }
});
export const deleteReview = createAsyncThunk('ecommerce/deleteReview', async (event: any) => {
  try {
    const response = deleteReviewApi(event);
    toast.success('Data deleted Successfully', { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error('Data deleted Failed', { autoClose: 2000 });
    return error;
  }
});
