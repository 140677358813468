import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const days = [
    { label: 'Pazartesi', value: 1 },
    { label: 'Salı', value: 2 },
    { label: 'Çarşamba', value: 3 },
    { label: 'Perşembe', value: 4 },
    { label: 'Cuma', value: 5 },
    { label: 'Cumartesi', value: 6 },
    { label: 'Pazar', value: 7 },
];

const SelectableChips = ({ selectedDays, setSelectedDays }: any) => {
    const handleToggle = (value: any) => () => {
        const currentIndex = selectedDays.indexOf(value);
        const newSelectedDays = [...selectedDays];

        if (currentIndex === -1) {
            newSelectedDays.push(value);
        } else {
            newSelectedDays.splice(currentIndex, 1);
        }

        setSelectedDays(newSelectedDays);
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {days.map((day) => (
                <ListItem key={day.value}>
                    <Chip
                        label={day.label}
                        color={selectedDays.includes(day.value) ? 'primary' : 'default'}
                        onClick={handleToggle(day.value)}
                    />
                </ListItem>
            ))}
        </Paper>
    );
};

export default SelectableChips;
