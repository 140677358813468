import React, { useEffect, useState, useRef } from "react";
import {
  Search,
  Eye,
  Trash2,
  SortAsc,
  SortDesc,
  Printer,
  Undo2,
  Loader2,
} from "lucide-react";
import { ToastContainer } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import Swal from "sweetalert2";
import { Tooltip } from "react-tooltip";

import { APIClient } from "helpers/api_helper";
import BreadCrumb from "Common/BreadCrumb";
import Modal from "Common/Components/Modal";

const api = new APIClient();

interface TableItem {
  _id: string;
  productTitle: string;
  productDescription: string;
  category: string;
  barcodeNumber: string;
  buyingKDV: string;
  sellingKDV: string;
  buyingPrice: string;
  sellingPrice: string;
  productImage: string | null;
  productNutritions: string;
  quantity: number;
  stock: number;
  showProduct: boolean;
  profitRate: string;
  totalBuyingKDV: string;
  totalSellingKDV: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Transaction {
  _id: string;
  tableItems: TableItem[];
  tableTotal: string;
  tableId: string;
  checkNote: string;
  paymentMethod: string;
  discounted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Product {
  barcodeNumber: string;
  productTitle: string;
  category: string;
  quantity: number;
  sellingPrice: string;
  sellingKDV: string;
}

interface ModalDetail {
  data?: {
    boughtItems?: Product[];
    tableItems?: Product[];
  };
}

const Receipts = () => {
  const effectRan = useRef(false);
  const [usersTransactions, setUsersTransactions] = useState<any>({});
  const [transactions, setTransactions] = useState<any[]>([]);
  const [statistics, setStatistics] = useState<any>({});
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [search, setSearch] = useState<string>("");
  const [modalDetail, setModalDetail] = useState<any>({
    show: false,
    data: {},
  });
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortDirectionTransactions, setSortDirectionTransactions] = useState<
    "asc" | "desc"
  >("asc");
  const [showMobileUsers, setShowMobileUsers] = useState(true);

  const showMobileTransactions = () => {
    setShowMobileUsers(true);
  };

  const showSystemTransactions = () => {
    setShowMobileUsers(false);
  };

  const getDailyStaticsMutation = useMutation({
    mutationFn: () => {
      return api.get(
        `/wallet/daily-statistics?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      );
    },
    onSuccess: (res: any) => {
      setStatistics(res);
      console.log(res);

      if (res?.allTime?.topSpenders) {
        res.allTime.topSpenders.forEach((data: any) => {
          if (data?.user?._id) {
            getUserTransactionsMutation.mutate(data.user._id);
          }
        });
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const getDailyStaticsTransactionsMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await api.get(
          `/transactions/daily-sales-report?startDate=${typeof value?.startDate === "string"
            ? value?.startDate
            : value?.startDate?.toISOString().slice(0, 10)
          }&endDate=${typeof value?.endDate === "string"
            ? value?.endDate
            : value?.endDate?.toISOString().slice(0, 10)
          }`,
          null
        );
        return response;
      } catch (error) {
        throw new Error("Error fetching daily sales report");
      }
    },
    onSuccess: (res: any) => {
      setTransactions(res);
      console.log("daily-sales-report?", res);
    },
    onError: (error: any) => {
      console.log("error", error);
    },
  });

  useEffect(() => {
    if (!effectRan.current) {
      getDailyStaticsMutation.mutate();
      getDailyStaticsTransactionsMutation.mutate();
      effectRan.current = true;
    }
  }, []);

  const getUserTransactionsMutation = useMutation({
    mutationFn: (id) => {
      return api.get(
        `/wallet/daily-user-transactions?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      );
    },
    onSuccess: (data) => {
      console.log("setUsersTransactions", data);
      setUsersTransactions(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const passiveDeleteUserTransactionMutation = useMutation({
    mutationFn: ({
      id,
      passiveDelete,
    }: {
      id: string;
      passiveDelete: boolean;
    }) => {
      return api.put(`/wallet/passive-delete-user-transaction/${id}`, {
        passiveDelete,
      });
    },
    onSuccess: (data: any) => {
      const newUsersTransactions = usersTransactions.map((transaction: any) => {
        if (transaction._id === data._id) {
          return {
            ...transaction,
            passiveDelete: data.passiveDelete,
          };
        }
        return transaction;
      });

      const filteredData = newUsersTransactions.sort((a: any, b: any) => {
        return a.passiveDelete ? 1 : -1;
      });

      setUsersTransactions(filteredData);
      Swal.fire({
        title: "Başarılı!",
        icon: "success",
      });
    },
    onError: (error) => {
      Swal.fire({
        title: "Hata!",
        text: "Bir hata oluştu. Lütfen tekrar deneyin.",
        icon: "error",
      });
    },
  });

  const passiveDeleteTransactionMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return api.delete(`/transactions/delete/${id}`, null);
    },
    onSuccess: (data: any) => {
      Swal.fire({
        title: "Başarılı!",
        icon: "success",
      });
    },
    onError: (error) => {
      Swal.fire({
        title: "Hata!",
        text: "Bir hata oluştu. Lütfen tekrar deneyin.",
        icon: "error",
      });
    },
  });

  const filterSearchData = (e: any) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    if (searchValue.trim() === "") {
      setUsersTransactions([]);
      getDailyStaticsMutation.mutate();
    } else {
      setUsersTransactions((prev: any) => {
        return prev.filter((transaction: any) => {
          return (
            transaction.user.name
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            transaction.user.lastname
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        });
      });
    }
  };

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
    setUsersTransactions([]);
    setTransactions([]);
    getDailyStaticsTransactionsMutation.mutate();
    getDailyStaticsMutation.mutate();
  };

  const handleDetailBtn = (transaction: any) => {
    setModalDetail({
      show: true,
      data: transaction,
    });
  };

  const toggle = () => {
    setModalDetail((prev: any) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  const printModalContent = (transaction: any) => {
    setModalDetail({
      show: true,
      data: transaction,
    });
    setTimeout(() => {
      window.print();
    }, 500);
  };

  const handleDelete = (transaction: any, passiveDelete: boolean) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "İstediğiniz zaman geri alabilirsiniz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.isConfirmed) {
        passiveDeleteUserTransactionMutation.mutate({
          id: transaction._id,
          passiveDelete,
        });
      }
    });
  };

  const handleDeleteTransaction = (transaction: any) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "İstediğiniz zaman geri alabilirsiniz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.isConfirmed) {
        passiveDeleteTransactionMutation.mutate({
          id: transaction._id,
        });
        setTransactions((prevTransactions: Transaction[]) =>
          prevTransactions.filter((t: Transaction) => t._id !== transaction._id)
        );
      }
    });
  };

  const handleSort = (key: string) => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));

    const sortedData = [...usersTransactions].sort((a: any, b: any) => {
      if (sortDirection === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setUsersTransactions(sortedData);
  };

  const handleSortTransactions = (key: string) => {
    setSortDirectionTransactions((prev) => (prev === "asc" ? "desc" : "asc"));
    const sortedData = [...transactions].sort((a: any, b: any) => {
      if (sortDirectionTransactions === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setTransactions(sortedData);
  };

  if (getUserTransactionsMutation.isPending && !statistics) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <Loader2 className="size-16 ltr:mr-2 rtl:ml-2 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="content">
        <React.Fragment>
          <BreadCrumb title="Fişler" pageTitle="Fişler" />
          <ToastContainer closeButton={false} limit={1} />
          <div className="card">
            <div className="card-body">
              <div className="grid grid-cols-1 gap-5 2xl:grid-cols-12">
                <div className="2xl:col-span-5">
                  <h5 className="mb-1">
                    {statistics?.daily?.totalSales}{" "}
                    <small className="font-normal text-slate-500 dark:text-zink-200">
                      TL
                    </small>
                  </h5>
                  <p className="text-slate-500 dark:text-zink-200">
                    Toplam Satış
                  </p>
                </div>
                <div className="2xl:col-span-5 2xl:col-start-8">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent">
                            Toplam Depozit
                          </td>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent font-semibold">
                            {statistics?.daily?.totalDeposits}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent">
                            Toplam Fiş
                          </td>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent font-semibold">
                            {statistics?.daily?.totalReceipts}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent">
                            Kullanıcılar Tarafından Toplam Harcanan
                          </td>
                          <td className="px-3.5 first:pl-0 last:pr-0 py-2 border-y border-transparent font-semibold">
                            {statistics?.daily?.totalSpentByUsers}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow-md" id="employeeTable">
            <div className="flex justify-between items-center space-x-4">
              <div className="lg:col-span-3 xl:col-span-3 flex-1">
                <div className="relative">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Fiş Ara..."
                    autoComplete="off"
                    value={search}
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
              </div>

              <div className="lg:col-span-2 xl:col-span-2 flex-1 flex justify-center">
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  value={value}
                  onChange={handleValueChange}
                />
              </div>

              <div className="lg:col-span-3 xl:col-span-2 flex-1 flex justify-end space-x-2">
                <button
                  onClick={showMobileTransactions}
                  className={`px-4 py-2 font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${showMobileUsers
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                    }`}
                >
                  Mobil Kullanıcılar
                </button>
                <button
                  onClick={showSystemTransactions}
                  className={`px-4 py-2 font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${!showMobileUsers
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                    }`}
                >
                  Sistem Satışları
                </button>
              </div>
            </div>
          </div>
          <Tooltip id="view" />
          <Tooltip id="print" />
          <Tooltip id="delete" />
          <Tooltip id="undo" />
          {showMobileUsers ? (
            <div className="mt-5 p-4 bg-[#f1f2f9] w-full">
              <div className="mb-4 text-xl font-bold text-gray-700">
                Mobil kullanıcılar
              </div>
              <div className="overflow-x-auto min-w-full">
                <table className="min-w-full bg-white border rounded-lg">
                  <thead className="bg-gray-100 border-b">
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Ad
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Soyad
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Toplam Ücret
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Ödeme Türü
                      </th>
                      <th
                        className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                        onClick={() => handleSort("createdAt")}
                      >
                        Tarih
                        {sortDirection === "asc" ? <SortAsc /> : <SortDesc />}
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Aksiyon
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTransactions.length > 0 ? (
                      usersTransactions.map((transaction: any) => (
                        <tr
                          key={transaction._id}
                          className={
                            transaction.passiveDelete
                              ? "bg-gray-100 line-through "
                              : "border-b"
                          }
                        >
                          <td className="px-4 py-3 text-sm">
                            {transaction.user
                              ? transaction.user.name?.charAt(0).toUpperCase() +
                              transaction.user.name.slice(1)
                              : "Kullanıcı Silindi"}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {transaction.user
                              ? transaction.user.lastname
                                ?.charAt(0)
                                .toUpperCase() +
                              transaction.user.lastname.slice(1)
                              : "Kullanıcı Silindi"}
                          </td>

                          <td className="px-4 py-3 text-sm">
                            {transaction.tableTotal}
                          </td>
                          <td className="table-cell phone-number">
                            {transaction.paymentMethod}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {new Date(
                              transaction.createdAt
                            ).toLocaleDateString()}{" "}
                            <br />{" "}
                            {new Date(
                              transaction.createdAt
                            ).toLocaleTimeString()}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            <div className="flex space-x-2">
                              <button
                                data-tooltip-id="view"
                                data-tooltip-content={"Görüntüle"}
                                className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                onClick={() => handleDetailBtn(transaction)}
                              >
                                <Eye className="w-4 h-4" />
                              </button>
                              <button
                                data-tooltip-id="print"
                                data-tooltip-content={"Yazdır"}
                                className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                onClick={() => printModalContent(transaction)}
                              >
                                <Printer className="w-4 h-4" />
                              </button>
                              {transaction.passiveDelete ? (
                                <button
                                  data-tooltip-id="undo"
                                  data-tooltip-content={"Geri Al"}
                                  className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                  onClick={() =>
                                    handleDelete(transaction, false)
                                  }
                                >
                                  <Undo2 className="w-4 h-4" />
                                </button>
                              ) : (
                                <button
                                  data-tooltip-id="delete"
                                  data-tooltip-content={"Sil"}
                                  className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
                                  onClick={() =>
                                    handleDelete(transaction, true)
                                  }
                                >
                                  <Trash2 className="w-4 h-4" />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : getDailyStaticsMutation.isPending ? (
                      <tr>
                        <td colSpan={6} className="text-center">
                          <Loader2 className="size-16 animate-spin w-full" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Veri bulunamadı.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="mt-5 p-4 bg-[#f1f2f9] w-full">
              <div className="mb-4 text-xl font-bold text-gray-700">
                Sistem Satışları
              </div>
              <div className="overflow-x-auto min-w-full">
                <table className="min-w-full bg-white border rounded-lg">
                  <thead className="bg-gray-100 border-b">
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Fiş
                      </th>

                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Toplam Ücret
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Ödeme Türü
                      </th>
                      <th
                        className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                        onClick={() => handleSortTransactions("createdAt")}
                      >
                        Tarih{" "}
                        {sortDirectionTransactions === "asc" ? (
                          <SortAsc />
                        ) : (
                          <SortDesc />
                        )}
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        Aksiyon
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.length > 0 ? (
                      transactions
                        .filter(
                          (transaction: any) =>
                            transaction.paymentMethod !== "Bakiye"
                        )
                        .map((transaction: any) => (
                          <tr
                            key={transaction._id}
                            className="bg-white border-b "
                          >
                            <td className="px-4 py-3 text-sm">Kasa</td>
                            <td className="px-4 py-3 text-sm">
                              {transaction.tableTotal}
                            </td>
                            <td className="table-cell phone-number">
                              {transaction.paymentMethod}
                            </td>
                            <td className="px-4 py-3 text-sm">
                              {new Date(
                                transaction.createdAt
                              ).toLocaleDateString()}
                              <br />
                              {new Date(
                                transaction.createdAt
                              ).toLocaleTimeString()}
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <div className="flex space-x-2">
                                <button
                                  data-tooltip-id="view"
                                  data-tooltip-content={"Görüntüle"}
                                  className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                  onClick={() => handleDetailBtn(transaction)}
                                >
                                  <Eye className="w-4 h-4" />
                                </button>
                                <button
                                  data-tooltip-id="print"
                                  data-tooltip-content={"Yazdır"}
                                  className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                                  onClick={() => printModalContent(transaction)}
                                >
                                  <Printer className="w-4 h-4" />
                                </button>

                                <button
                                  data-tooltip-id="delete"
                                  data-tooltip-content={"Sil"}
                                  className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
                                  onClick={() =>
                                    handleDeleteTransaction(transaction)
                                  }
                                >
                                  <Trash2 className="w-4 h-4" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : getDailyStaticsMutation.isPending ? (
                      <tr>
                        <td colSpan={6} className="text-center">
                          <Loader2 className="size-16 animate-spin w-full" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Veri bulunamadı.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Modal
            show={modalDetail.show}
            onHide={toggle}
            id="defaultModal"
            modal-center="true"
            className="fixed flex flex-col modal transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
            dialogClassName="w-screen md:w-[50vw] bg-white shadow rounded-md dark:bg-zink-600"
          >
            <Modal.Header
              className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
              closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
            >
              <Modal.Title className="text-16">Fiş Bilgisi</Modal.Title>
            </Modal.Header>

            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
              <div className="!pt-0 card-body">
                <div className="my-5">
                  <p className="mb-2 text-sm uppercase text-slate-500 dark:text-zink-200">
                    Ödeme Bilgileri
                  </p>
                  <p className="mb-1 text-slate-500 dark:text-zink-200">
                    Ödeme Methodu: {modalDetail?.data?.paymentMethod}
                  </p>
                  <p className="mb-1 text-slate-500 dark:text-zink-200">
                    Müşteri: {modalDetail?.data?.user?.name}{" "}
                    {modalDetail?.data?.user?.lastname}
                  </p>
                  <p className="mb-1 text-slate-500 dark:text-zink-200">
                    Tarih:{" "}
                    {new Date(
                      modalDetail?.data?.createdAt
                    ).toLocaleDateString()}{" "}
                    {new Date(
                      modalDetail?.data?.createdAt
                    ).toLocaleTimeString()}
                  </p>
                  <p className="mb-0 text-slate-500 dark:text-zink-200">
                    Toplam Ücret: <b>{modalDetail?.data?.tableTotal} TL</b>
                  </p>
                </div>
                <div>
                  <div className="mt-4 overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                      <thead className="ltr:text-left rtl:text-right">
                        <tr>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Barkod
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Ürün İsmi
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Adet
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Fiyat
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            KDV
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(
                          modalDetail?.data?.boughtItems ??
                          modalDetail?.data?.tableItems ??
                          []
                        ).length > 0 ? (
                          (
                            modalDetail?.data?.boughtItems ??
                            modalDetail?.data?.tableItems ??
                            []
                          ).map((product: Product, index: number) => (
                            <tr key={index}>
                              <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                                {product.barcodeNumber}
                              </td>
                              <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                                <h6 className="mb-1">{product.productTitle}</h6>
                                <p className="text-slate-500 dark:text-zink-200">
                                  {product.category}
                                </p>
                              </td>
                              <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                                {product.quantity}
                              </td>
                              <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                                {product.sellingPrice}
                              </td>
                              <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                                {product.sellingKDV}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              className="text-center px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500"
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Receipts;
