import {
  AppWindowIcon,
  Bell,
  Building,
  Calculator,
  CreditCard,
  FileText,
  Gift,
  Image,
  LogOut,
  MessageSquare,
  MonitorDot,
  Palette,
  Percent,
  Terminal,
  User,
} from "lucide-react";
import { BarChart } from 'lucide-react';
const menuData: any = [
  {
    label: 'Ana Menü',
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Anasayfa",
    link: "/#",
    icon: <MonitorDot />,
  },
  {
    id: "terminal",
    label: "Terminal",
    link: "/terminal",
    icon: <Terminal />,
  },
  {
    id: "menu",
    label: "Menü",
    icon: <MessageSquare />,
    link: "/menu",
    subItems: [
      {
        id: "addnewproduct",
        label: "Yeni Ürün Ekle",
        link: "/add-new-product",
        parentId: "menu",
      },
      {
        id: "menu-list",
        label: "Menü Listesi",
        link: "/menu",
        parentId: "menu",
      },
      {
        id: "addnewcategory",
        label: "Yeni Kategori Ekle",
        link: "/add-new-category",
        parentId: "menu",
      },
      {
        id: "listcategory",
        label: "Kategori Listesi",
        link: "/categories-list",
        parentId: "menu",
      },
    ],
  },
  // {
  //   id: "cash-register",
  //   label: "Kasa",
  //   link: "/cash-register",
  //   icon: <Calculator />,
  //   subItems: [
  //     {
  //       id: "cashregister",
  //       label: "Kasa İşlemleri",
  //       link: "/cash-register",
  //       parentId: "cash-register",
  //     },
  //     {
  //       id: "cashregisterlist",
  //       label: "Kasaları Görüntüle",
  //       link: "/cash-register-list",
  //       parentId: "cash-register-list",
  //     },
  //   ],
  // },
  {
    id: "users",
    label: "Kullanıcılar",
    link: "/users",
    icon: <User />,
  },
  {
    label: 'Uygulama',
    isTitle: true,
  },
  {
    id: "notification",
    label: "Bildirim Gönder",
    link: "/send-notification",
    icon: <Bell />,
    subItems: [
      {
        id: "creategroupnotifications",
        label: "Özel Bildirim Gönder",
        link: "/create-group-notification",
        parentId: "notification",
      },
      {
        id: "normalnotifications",
        label: "Genel Bildirim Gönder",
        link: "/send-notification",
        parentId: "notification",
      },
    ],
  },
  {
    id: "gift",
    label: "Hediye Ürün",
    link: "/gift-product",
    icon: <Gift />,
  },
  {
    id: "banner",
    label: "Banner Yönetimi",
    link: "/banner-manage",
    icon: <Image />,
  },

  {
    id: "app-color",
    label: "Uygulama Rengi",
    link: "/app-color",
    icon: <Palette />,
  },

  {
    id: "app-version-code",
    label: "Uygulama Versiyon Kodu",
    link: "/app-version-code",
    icon: <AppWindowIcon />,
  },

  {
    id: "app-logo",
    label: "Uygulama Logo",
    link: "/app-logo",
    icon: <Palette />,
  },

  {
    label: 'Mali İşlemler',
    isTitle: true,
  },

  {
    id: "kdv",
    label: "KDV",
    link: "/orders",
    icon: <Percent />,
    subItems: [
      {
        id: "addkdv",
        label: "KDV Ekle",
        link: "/add-new-kdv",
        parentId: "kdv",
      },
      {
        id: "listkdv",
        label: "KDV Listesi",
        link: "/kdv-list",
        parentId: "kdv",
      },
    ],
  },
  {
    id: "payment-methods",
    label: "Ödeme Türleri",
    link: "/payment-method-list",
    icon: <CreditCard />,
    subItems: [
      {
        id: "addpaymentmethod",
        label: "Ödeme Yöntemi Ekle",
        link: "/add-payment-method",
        parentId: "payment-methods",
      },
      {
        id: "listpaymentmethods",
        label: "Ödeme Yöntemleri Listesi",
        link: "/payment-method-list",
        parentId: "payment-methods",
      },
    ],
  },

  {
    label: 'Raporlar',
    isTitle: true,
  },

  {
    id: "xraports",
    label: "XRaporu",
    link: "/xraports",
    icon: <BarChart />,
  },
  {
    id: "cash-register",
    label: "Kasa",
    link: "/cash-register",
    icon: <Calculator />,
    subItems: [
      {
        id: "cashregister",
        label: "Kasa İşlemleri",
        link: "/cash-register",
        parentId: "cash-register",
      },
      {
        id: "cashregisterlist",
        label: "Kasaları Görüntüle",
        link: "/cash-register-list",
        parentId: "cash-register-list",
      },
    ],
  },
  {
    id: "receipts",
    label: "Fişler",
    link: "/receipts",
    icon: <FileText />,
  },
  {
    label: 'Kullanıcı Yönetimi',
    isTitle: true,
  },

  {
    id: "users",
    label: "Kullanıcılar",
    link: "/users",
    icon: <User />,
  },

  {
    label: 'ENVANTER VE ŞUBE YÖNETİMİ',
    isTitle: true,
  },

  {
    id: "markets",
    label: "Şubeler",
    link: "/markets-list",
    icon: <Building />,
    subItems: [
      {
        id: "addpaymentmethod",
        label: "Şube Ekle",
        link: "/add-market",
        parentId: "markets",
      },
      {
        id: "listpaymentmethods",
        label: "Şube Listesi",
        link: "/markets-list",
        parentId: "markets",
      },
    ],
  },



  {
    id: "product-count",
    label: "Sayım",
    link: "/product-count",
    icon: <Calculator />,
    subItems: [
      {
        id: "productcountlist",
        label: "Sayım Oluştur",
        link: "/make-product-count",
        parentId: "product-count",
      },
      {
        id: "makeproductcount",
        label: "Sayım Listesi",
        link: "/product-count",
        parentId: "product-count",
      },
    ],
  },

  {
    label: 'Çıkış',
    isTitle: true,
  },

  {
    id: "Çıkış Yap",
    label: "Çıkış Yap",
    link: "/logout",
    icon: <LogOut />,
  },
];
export { menuData };
