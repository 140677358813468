import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Swal from 'sweetalert2';
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import Layout from 'Layout';
import NonAuthLayout from "Layout/NonLayout";
import AuthProtected from './AuthProtected';

const RouteIndex = () => {
  useEffect(() => {
    const checkInternetConnection = () => {
      if (!navigator.onLine) {
        Swal.fire({
          title: 'İnternet Bağlantısı Yok!',
          text: "Lütfen internet bağlantınızı kontrol edin.",
          icon: 'error',
          confirmButtonText: 'Tamam'
        })
      }
    };

    const interval = setInterval(checkInternetConnection, 5000); // Check every 5 seconds

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, []);
  return (
    <React.Fragment>
      <Routes>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected>
                {
                  !route.isTerminal ?
                    <Layout>
                      <route.component />
                    </Layout>
                    :
                    <route.component />
                }
              </AuthProtected>
            }
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <NonAuthLayout>
                <route.component />
              </NonAuthLayout>
            } />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default RouteIndex;
