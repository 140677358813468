import React from "react";

interface ButtonPros {
    text: string,
    onClick: () => void
}

const BasicButtons = ({ text, onClick }: ButtonPros) => {
    return (
        <React.Fragment>
            <button type="button" onClick={onClick} className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                {text}
            </button>

        </React.Fragment>
    );
}

export default BasicButtons;