import React, { useState, useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { APIClient } from "helpers/api_helper";
import { useMutation } from "@tanstack/react-query";
import ColumnFilter from "./SelectFilter";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import PieChart from "pages/Components/ChartComponents/PieChart";
import { SortAsc, SortDesc, ArrowDownUp } from "lucide-react";

const api = new APIClient();

const Analytics = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dailyStatistics, setDailyStatistics] = useState({} as any);
  const [dailyReport, setDailyReport] = useState([]);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([
    "productTitle",
    "quantity",
    "buyingPrice",
    "averageSellingPrice",
    "unitProfit",
    "totalCost",
    "totalSellingPrice",
    "totalProfit",
  ]);
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [totalAmount, setTotalAmount] = useState(0);

  const getUserDailyStatisticsMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet-transactions/type-add-and-payment-method?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      // Verideki sayıları topla
      const total = Object.values(data).reduce(
        (acc: number, curr: number) => acc + curr,
        0
      );
      console.log(value?.startDate);
      console.log(value?.endDate);

      
      // Toplamı state'e ata
      setTotalAmount(total);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };
  const handleSort = (key: string) => {
    setSortColumn(key);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );

    const sortedReport = [...dailyReport].sort((a: any, b: any) => {
      let comparison = 0;

      if (key === "balance") {
        comparison = (a.wallet?.balance || 0) - (b.wallet?.balance || 0);
      } else {
        const aValue = typeof a[key] === "string" ? parseFloat(a[key]) : a[key];
        const bValue = typeof b[key] === "string" ? parseFloat(b[key]) : b[key];

        // NaN durumuna karşı önlem al
        if (isNaN(aValue) || isNaN(bValue)) {
          comparison = a[key] > b[key] ? 1 : -1; // String olarak sıralama
        } else {
          comparison = aValue - bValue; // Number olarak sıralama
        }
      }

      return sortDirection === "asc" ? comparison : -comparison;
    });

    setDailyReport(sortedReport);
  };

  const columns = [
    { accessor: "productTitle", Header: "Ürün Adı" },
    { accessor: "quantity", Header: "Miktar" },
    { accessor: "buyingPrice", Header: "Alış Fiyatı" },
    { accessor: "averageSellingPrice", Header: "Ort. Satış Fiyatı" },
    { accessor: "unitProfit", Header: "Birim Kâr" },
    { accessor: "totalCost", Header: "Toplam Maliyet" },
    { accessor: "totalSellingPrice", Header: "Toplam Satış Fiyatı" },
    { accessor: "totalProfit", Header: "Toplam Kâr" },
    { accessor: "totalPurchaseKDV", Header: "Toplam Alış KDV" },
    { accessor: "totalSalesKDV", Header: "Toplam Satış KDV" },
  ];

  const getCategoriesMutation = useMutation({
    mutationFn: () => api.get(`/categories/all`, null),
    onSuccess: (data: any) => {
      setCategories(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getDailyStatisticsMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet/daily-statistics?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      console.log(data);

      setDailyStatistics(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getDailySalesReportMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet/daily-sales-report?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data: any) => {
      setDailyReport(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getDailyStatisticsMutation.mutate();
    getDailySalesReportMutation.mutate();
    getCategoriesMutation.mutate();
    getUserDailyStatisticsMutation.mutate();
  }, [value?.startDate, value?.endDate]);

  let filteredProducts = dailyReport
    .map((product: any) => {
      const totalPurchaseKDV =
        Number(product.buyingPrice) *
        product.quantity *
        (product.buyingKDV / 100);
      const totalSalesKDV =
        Number(product.sellingPrice) *
        product.quantity *
        (product.sellingKDV / 100);
      const kdvDifference = totalSalesKDV - totalPurchaseKDV;

      return {
        ...product,
        totalPurchaseKDV: totalPurchaseKDV.toFixed(2),
        totalSalesKDV: totalSalesKDV.toFixed(2),
        kdvDifference: kdvDifference.toFixed(2),
      };
    })
    .filter((product) =>
      product.productTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );

  if (
    getDailySalesReportMutation.isPending ||
    getDailyStatisticsMutation.isPending
  ) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  function calculateTotalNetProfit(dailyReport: any) {
    return dailyReport
      .reduce((acc: number, product: any) => {
        const netProfit = product.totalSellingPrice - product.totalCost;
        return acc + netProfit;
      }, 0)
      .toFixed(2);
  }

  const paymentMethods = dailyStatistics.daily?.paymentMethodTotals || {};
  const allTimePaymentMethods =
    dailyStatistics.allTime?.paymentMethodTotals || {};

  const topSpenders = (dailyStatistics.daily?.topSpenders || [])
    .map((spender: any) => {
      if (!spender.user) {
        return { ...spender, user: { name: "Kullanıcı", lastname: "Silindi" } };
      }
      return spender;
    })
    .filter(
      (spender: any) =>
        spender?.user?.name &&
        spender?.user?.lastname &&
        spender?.user?.name !== "NaN" &&
        spender?.user?.lastname !== "NaN"
    );

  const uniqueSpenders = Array.from(
    new Map(
      topSpenders.map((spender: any) => [
        spender.user.email || `${spender.user.name} ${spender.user.lastname}`,
        spender,
      ])
    ).values()
  );
  
  
  return (
    <React.Fragment>
      <BreadCrumb title="Anasayfa" pageTitle="Anasayfa" />
      <div className="my-5 flex space-x-4 w-1/3">
        <Datepicker
          displayFormat={"DD/MM/YYYY"}
          value={value}
          onChange={handleValueChange}
        />
      </div>
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam Satış</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {Number(dailyStatistics.daily?.totalSales).toFixed(2) || 0} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        {Object.keys(paymentMethods).map((method) => (
          <div className="col-span-12 lg:col-span-3" key={method}>
            <div className="card h-40">
              <div className="card-body">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-light">
                    {method === "Bakiye" ? "Harcanan Bakiye" : method}
                  </h2>
                </div>
                <div className="mt-5">
                  <span className="text-xl font-bold">
                    {paymentMethods[method] || 0} ₺
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam Alış KDV'si</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {dailyStatistics.daily?.totalPurchaseVat?.toFixed(2) || 0} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam Satış KDV'si</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {dailyStatistics.daily?.totalSalesVat?.toFixed(2) || 0} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">
                  Toplam Satış KDV - Toplam Alış KDV'si Fark
                </h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {(
                    dailyStatistics.daily?.totalSalesVat?.toFixed(2) -
                    dailyStatistics.daily?.totalPurchaseVat?.toFixed(2)
                  ).toFixed(2) || 0}{" "}
                  ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam Fiş Sayısı</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {dailyStatistics.daily?.totalReceipts || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Fiş Ortalaması</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {(
                    (dailyStatistics.daily?.totalSales || 0) /
                    (dailyStatistics.daily?.totalReceipts || 1)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam İndirim</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {Number(dailyStatistics.daily?.totalDiscounts).toFixed(2) ||
                    0}{" "}
                  ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Yüklenen Bakiye</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {totalAmount.toFixed(2) || 0} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">
                  Kullanıcıların Toplam Kalan Bakiyesi
                </h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {Number(dailyStatistics.daily?.totalRemainingBalance).toFixed(
                    2
                  ) || 0}{" "}
                  ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Toplam Net Kar</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {calculateTotalNetProfit(dailyReport)} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="card h-40">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-light">Net Kar (Satış KDV Düşülmüş)</h2>
              </div>
              <div className="mt-5">
                <span className="text-xl font-bold">
                  {calculateTotalNetProfit(dailyReport) - dailyStatistics?.daily?.totalSalesVat} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="max-w-full mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-12 justify-between items-center">
              <div className="col-span-4">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                  Ürün Satış Raporu
                </h2>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  placeholder="Ürün Adı / Barkod"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100"
                />
              </div>
              <div className="col-span-4">
                <ColumnFilter
                  columns={columns}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
              <thead>
                <tr>
                  {columns
                    .filter((column) =>
                      selectedColumns.includes(column.accessor)
                    )
                    .map((column) => (
                      <th
                        key={column.accessor}
                        className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100 cursor-pointer"
                        onClick={() => handleSort(column.accessor)}
                      >
                        <div className="flex items-center">
                          {column.Header}
                          {sortColumn === column.accessor ? (
                            sortDirection === "asc" ? (
                              <SortDesc className="ml-2" />
                            ) : (
                              <SortAsc className="ml-2" />
                            )
                          ) : (
                            <ArrowDownUp className="ml-2" />
                          )}
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr
                    key={index}
                    className="text-center bg-white dark:bg-gray-800"
                  >
                    {columns
                      .filter((column) =>
                        selectedColumns.includes(column.accessor)
                      )
                      .map((column) => (
                        <td
                          key={column.accessor}
                          className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100"
                        >
                          {column.accessor === "buyingPrice" ||
                            column.accessor === "averageSellingPrice" ||
                            column.accessor === "unitProfit" ||
                            column.accessor === "totalCost" ||
                            column.accessor === "totalSellingPrice" ||
                            column.accessor === "totalProfit" ||
                            column.accessor === "totalPurchaseKDV" ||
                            column.accessor === "totalSalesKDV" ||
                            column.accessor === "kdvDifference"
                            ? Number(product[column.accessor]).toFixed(2) + " ₺"
                            : product[column.accessor]}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="max-w-full mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                En Çok Harcama Yapanlar
              </h2>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100">
                    Kullanıcı
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100">
                    Harcama
                  </th>
                </tr>
              </thead>
              <tbody>
                {uniqueSpenders.map((spender: any, index) => (
                  <tr
                    key={index}
                    className="text-center bg-white dark:bg-gray-800"
                  >
                    <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100">
                      {spender.user.name.charAt(0).toUpperCase() +
                        spender.user.name.slice(1).toLowerCase()}{" "}
                      {spender.user.lastname.charAt(0).toUpperCase() +
                        spender.user.lastname.slice(1).toLowerCase()}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100">
                      {spender?.totalSpent.toFixed(2) || 0} ₺
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="max-w-full mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                Satılan Ürünler Kategori Bazında Grafiği
              </h2>
            </div>
          </div>
          <div className="overflow-x-auto flex justify-center">
            {filteredProducts.length === 0 || categories.length === 0 ? (
              <p className="text-gray-900 dark:text-gray-100">Grafik yok</p>
            ) : (
              <PieChart data={filteredProducts} categories={categories} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
