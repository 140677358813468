import React from "react";

// ProductCardProps tipi
export interface ProductCardProps {
  productTitle: string;
  category: string;
  quantity: number;
  buyingPrice: string;
  averageSellingPrice: number;
  unitProfit: number;
  totalCost: number;
  totalSellingPrice: number;
  totalProfit: number;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productTitle,
  category,
  quantity,
  buyingPrice,
  averageSellingPrice,
  unitProfit,
  totalCost,
  totalSellingPrice,
  totalProfit,
}) => {
  return (
    <div className="bg-white shadow border-topbar-dark border rounded-lg w-full">
      <div className="px-4 py-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {productTitle}
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl className="flex flex-wrap">
          <div className="w-full sm:w-1/2 p-3 bg-gray-100">
            <dt className="text-sm font-medium text-gray-500">Alış Fiyatı:</dt>
            <dd className="mt-1 text-sm text-gray-900">{buyingPrice} TL</dd>
          </div>
          <div className="w-full sm:w-1/2 p-3 bg-white">
            <dt className="text-sm font-medium text-gray-500">Satış Fiyatı:</dt>
            <dd className="mt-1 text-sm text-gray-900">{averageSellingPrice.toFixed(2)} TL</dd>
          </div>
          <div className="w-full sm:w-1/2 p-3 bg-gray-100">
            <dt className="text-sm font-medium text-gray-500">Birim Kar:</dt>
            <dd className="mt-1 text-sm text-gray-900">{unitProfit.toFixed(2)} TL</dd>
          </div>
          <div className="w-full sm:w-1/2 p-3 bg-white">
            <dt className="text-sm font-medium text-gray-500">Miktar:</dt>
            <dd className="mt-1 text-sm text-gray-900">{quantity}</dd>
          </div>
          <div className="w-full sm:w-1/2 p-3 bg-gray-100">
            <dt className="text-sm font-medium text-gray-500">Toplam Maliyet:</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalCost.toFixed(2)} TL</dd>
          </div>
          <div className="w-full sm:w-1/2 p-3 bg-white">
            <dt className="text-sm font-medium text-gray-500">Toplam Satış Fiyatı:</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalSellingPrice.toFixed(2)} TL</dd>
          </div>
        </dl>
        <div className="px-4 py-5">
          <p className="text-lg leading-6 font-medium text-gray-900">
            Toplam Kar: {(totalSellingPrice - totalCost).toFixed(2)} TL
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
