import React from 'react'

type Props = {
    totalSalon: number
}

const AdisyonHeader = (props: Props) => {
    return (
        <div
            className='flex items-center justify-start px-4 h-16  text-white bg-white  '
        >
            <span
                className='text-lg text-black font-bold mt-1 r hover:text-gray-800'
            >
                Adisyonlar
            </span>
        </div>
    )
}

export default AdisyonHeader