import React, { useState } from "react";
import { useFormik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface FormValues {
    firstName: string;
    lastName: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    profilePicture: File | null;
}

const url = 'https://api.macfitcafe.com';
const local = 'http://localhost:3003';

const EditProfile = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        firstName: Yup.string().required("İsim gerekli"),
        lastName: Yup.string().required("Soyisim gerekli"),
        currentPassword: Yup.string(),
        newPassword: Yup.string()
            .min(8, "Şifre en az 8 karakter olmalıdır")
            .test(
                'newPasswordTest',
                'Yeni şifre gerekli',
                function (value) {
                    const { currentPassword } = this.parent;
                    if (currentPassword && !value) {
                        return false;
                    }
                    return true;
                }
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), undefined], 'Şifreler eşleşmelidir')
            .test(
                'confirmPasswordTest',
                'Yeni şifreyi onaylayın',
                function (value) {
                    const { currentPassword, newPassword } = this.parent;
                    if (currentPassword && newPassword && !value) {
                        return false;
                    }
                    return true;
                }
            ),
        profilePicture: Yup.mixed().nullable()
    });

    const getUser: any = localStorage.getItem("authUser") ?? {};
    const user = JSON.parse(getUser).user;

    const formik = useFormik<FormValues>({
        initialValues: {
            firstName: user.name || "",
            lastName: user.lastname || "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            profilePicture: null,
        },
        validationSchema,
        onSubmit: async (values) => {
            const token = localStorage.getItem("authUser");

            if (!token) {
                return;
            }

            const userId = JSON.parse(token).user._id;

            const formData = new FormData();
            formData.append("userId", userId);
            formData.append("name", values.firstName);
            formData.append("lastname", values.lastName);

            if (values.newPassword && values.currentPassword) {
                formData.append("password", values.newPassword);
                formData.append("currentPassword", values.currentPassword);
            }

            if (values.profilePicture) {
                formData.append("profilePicture", values.profilePicture);
            }

            setLoading(true);
            try {
                const response: any = await axios.put(`/user/update-admin-password`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response) {
                    toast.success("Profil başarıyla güncellendi");
                    localStorage.setItem("authUser", JSON.stringify({ ...JSON.parse(token), user: { ...user, name: values.firstName, lastname: values.lastName, profilePicture: response?.profilePicture } }));
                    navigate('/dashboard');
                }
            } catch (error) {
                console.log(error);
                
                toast.error("Profil güncellenemedi");
            } finally {
                setLoading(false);
            }
        },
    });

    const renderError = (field: keyof FormikErrors<FormValues> & keyof FormikTouched<FormValues>) => {
        return formik.touched[field] && formik.errors[field] ? (
            <div style={{ color: "#ff4d4f", fontSize: "12px", marginTop: "4px" }}>{formik.errors[field]}</div>
        ) : null;
    };

    return (
        <div style={{ padding: "24px", backgroundColor: "#f1f2f9", width: '100%', minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ToastContainer />
            <div style={{ backgroundColor: "white", padding: "32px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", width: "100%" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px", textAlign: 'center', color: '#333' }}>Profil Güncelle</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="profilePicture" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Profil Resmi</label>
                        {user.profilePicture && (
                            <div style={{ marginBottom: "8px" }}>
                                <img src={`${url}/upload-file/profilePictures/${user.profilePicture}`} alt="Profile" style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%" }} />
                            </div>
                        )}
                        <input
                            type="file"
                            id="profilePicture"
                            name="profilePicture"
                            onChange={(event) => {
                                formik.setFieldValue("profilePicture", event.currentTarget.files ? event.currentTarget.files[0] : null);
                            }}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.profilePicture && formik.errors.profilePicture ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('profilePicture')}
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="firstName" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>İsim</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.firstName && formik.errors.firstName ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('firstName')}
                    </div>

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="lastName" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Soyisim</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.lastName && formik.errors.lastName ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('lastName')}
                    </div>

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="currentPassword" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Güncel Şifre</label>
                        <input
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.currentPassword && formik.errors.currentPassword ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('currentPassword')}
                    </div>

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="newPassword" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Yeni Şifre</label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.newPassword && formik.errors.newPassword ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('newPassword')}
                    </div>

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="confirmPassword" style={{ display: "block", marginBottom: "8px", fontWeight: "500", color: '#555' }}>Yeni Şifreyi Onayla</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: formik.touched.confirmPassword && formik.errors.confirmPassword ? "1px solid #ff4d4f" : "1px solid #ccc",
                                borderRadius: "4px",
                                outline: "none",
                                transition: "border-color 0.3s",
                            }}
                        />
                        {renderError('confirmPassword')}
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "#007bff",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                fontSize: "16px",
                                transition: "background-color 0.3s",
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
                            disabled={loading}
                        >
                            {loading ? "Güncelleniyor..." : "Profili Güncelle"}
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate("/dashboard")}
                            style={{
                                backgroundColor: "#6c757d",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                fontSize: "16px",
                                transition: "background-color 0.3s",
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#5a6268")}
                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#6c757d")}
                        >
                            İptal
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;
