import React from 'react'

import Move from 'assets/images/move.png'

type Props = {
    item: {
        img: string,
        text: string,
        onClick: () => void
    }
}

const LeftMenuItem = (props: Props) => {
    return (
        <div
            onClick={() => {
                props.item.onClick()
            }}
            className='flex flex-col pt-4 transition duration-300 ease-in-out items-center justify-center  cursor-pointer hover:border-gray-800 hover:bg-gray-100'
        >
            <img src={props.item.img} alt=""
                className='w-4 h-4'
            />

            <span
                className='text-sm text-black mt-1  hover:text-gray-800'
            >
                {
                    props.item.text
                }
            </span>

        </div>
    )
}

export default LeftMenuItem