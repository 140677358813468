import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js/auto';

interface Data {
  productTitle: string;
  category: string;
  quantity: number;
  buyingPrice: string;
  averageSellingPrice: number;
  unitProfit: number;
  totalCost: number;
  totalSellingPrice: number;
  totalProfit: number;
}

interface Category {
  _id: string;
  categoryName: string;
  categoryImage: string;
  showOnMenu: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  categoryColor: string;
}

interface PieChartProps {
  data: Data[];
  categories: Category[];
}

const PieChart: React.FC<PieChartProps> = ({ data, categories }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<'pie', number[], string> | null>(null);

  const categoryTotals: { [key: string]: number } = {};
  data.forEach((item) => {
    if (categoryTotals[item.category]) {
      categoryTotals[item.category] += item.totalSellingPrice;
    } else {
      categoryTotals[item.category] = item.totalSellingPrice;
    }
  });

  const labels: string[] = Object.keys(categoryTotals);
  const totalSellingPrices: number[] = Object.values(categoryTotals);

  const backgroundColors: string[] = labels.map(label => {
    const category = categories.find(cat => cat.categoryName === label);
    return category ? category.categoryColor : '#000000';
  });


  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext('2d');
      if (myChartRef) {
        const total = totalSellingPrices.reduce((acc, value) => acc + value, 0);

        const config: ChartConfiguration<'pie', number[], string> = {
          type: 'pie',
          data: {
            labels,
            datasets: [{
              label: 'Total Selling Price by Category',
              data: totalSellingPrices,
              backgroundColor: backgroundColors,
            }],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.label || '';
                    const value = typeof context.raw === 'number' ? context.raw : 0;
                    const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
                    return `${label}: ${value} TL (${percentage}%)`;
                  },
                },
              },
            },
          },
        };

        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart<'pie', number[], string>(myChartRef, config);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, categories, labels, totalSellingPrices, backgroundColors]);

  return (
    <div className='flex'>
      <div className='w-[450px] h-[350px] m-3'>
        <canvas ref={chartRef} />
      </div>
      <div className='m-3'>
        <ul>
          {labels.map((label, index) => (
            <li key={label} className='flex items-center mb-2'>
              <span
                className='w-4 h-4 inline-block mr-2'
                style={{ backgroundColor: backgroundColors[index] }}
              ></span>
              <span>{label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PieChart;
