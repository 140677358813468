import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import InputMask from "react-input-mask";
import { Trash2, FileEdit, SortAsc, SortDesc, Eye } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getUserList as onGetUserList } from "slices/thunk";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { APIClient } from "helpers/api_helper";
import Swal from "sweetalert2";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { Tooltip } from "react-tooltip";

const api = new APIClient();

const UserListView = () => {
  const dispatch = useDispatch<any>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [user, setUser] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [activeTab, setActiveTab] = useState<"add" | "spent" | "subtract">(
    "add"
  );
  const [userAddTransactions, setUserAddTransactions] = useState<any>([]);
  const [userSubtractTransactions, setUserSubtractTransactions] = useState<any>(
    []
  );
  const [userSpentTransactions, setUserSpentTransactions] = useState<any>([]);
  const [selectId, setSelectId] = useState<string>("");
  const selectDataList = createSelector(
    (state: any) => state.Users,
    (user) => ({
      userList: user.userList,
    })
  );
  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  const { userList } = useSelector(selectDataList);

  useEffect(() => {
    dispatch(onGetUserList());
  }, [dispatch]);
  useEffect(() => {
    getPaymentMethodsMutation.mutate();
    const sortedUsers = [...userList].sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    const filterAdminUser = sortedUsers.filter(
      (data: any) => data.email !== "admin@cafeapp.com"
    );
    setUser(filterAdminUser);
  }, [userList]);

  const onClickDelete = (cell: any) => {
    if (cell._id) {
      setEventData(cell);
      setUserToDelete(cell);
      setShowDeleteModal(true);
    }
  };

  const deleteUserMutation = useMutation({
    mutationFn: (id: any) => api.delete(`/user/delete/${id}`, null),
    onSuccess: (data) => {
      toast.success("Kullanıcı silindi");
      dispatch(onGetUserList());
      setShowDeleteModal(false);
    },
    onError: (error) => {
      toast.error("Bir hata oluştu");
      setShowDeleteModal(false);
    },
  });

  const handleDelete = () => {
    if (userToDelete) {
      deleteUserMutation.mutate(userToDelete._id);
    }
  };
  const handleClickUserDetail = (id: string) => {
    setSelectId(id);
    setShow2(true);
  };
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };
  const userAddTransactionsMutation = useMutation({
    mutationFn: (id: any) =>
      api.get(
        `/wallet-transactions/${id}/add-transactions?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      setUserAddTransactions(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  const userSubtractTransactionsMutation = useMutation({
    mutationFn: (id: any) =>
      api.get(
        `/wallet-transactions/${id}/subtract-transactions?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      setUserSubtractTransactions(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  const userSpentTransactionsMutation = useMutation({
    mutationFn: (id: any) =>
      api.get(
        `/wallet/${id}/daily-user-transactions?startDate=${typeof value?.startDate === "string"
          ? value?.startDate
          : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${typeof value?.endDate === "string"
          ? value?.endDate
          : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      setUserSpentTransactions(data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  useEffect(() => {
    dispatch(onGetUserList());
  }, [dispatch]);

  useEffect(() => {
    if (selectId) {
      userAddTransactionsMutation.mutate(selectId);
      userSpentTransactionsMutation.mutate(selectId);
      userSubtractTransactionsMutation.mutate(selectId);
    }
  }, [value?.startDate, value?.endDate, selectId]);

  useEffect(() => {
    getPaymentMethodsMutation.mutate();
    const sortedUsers = [...userList].sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    const filterAdminUser = sortedUsers.filter(
      (data: any) => data.email !== "admin@cafeapp.com"
    );
    setUser(filterAdminUser);
  }, [userList]);
  const addWalletTotMoneyMutation = useMutation({
    mutationFn: (data: any) =>
      api.put("/wallet/add-money", {
        owner: data.owner,
        amount: data.amount,
      }),
    onSuccess: (data) => {
      toast.success("Para başarıyla yüklendi");
      dispatch(onGetUserList());
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const createWalletTransactionMutation = useMutation({
    mutationFn: (data: any) =>
      api.create("/wallet-transactions/create", {
        owner: data.id,
        amount: data.balance,
        type: data.type,
        paymentMethod: data.paymentMethod,
      }),
    onSuccess: (data) => {
      console.log("data", data);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  const getPaymentMethodsMutation = useMutation({
    mutationFn: () => axios.get("/payment-methods"),
    onSuccess: (data: any) => {
      setPaymentMethods(data);
    },
    onError: (error) => { },
  });
  const substractMoneyMutation = useMutation({
    mutationFn: (data: any) =>
      api.put("/wallet/subtract-money", {
        owner: data.owner,
        amount: data.amount,
      }),
    onSuccess: (data) => {
      toast.success("Para başarıyla çıkarıldı.");
      dispatch(onGetUserList());
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (eventData && eventData.name) || "",
      lastname: (eventData && eventData.lastname) || "",
      phoneNumber: (eventData && eventData.phoneNumber) || "",
      email: (eventData && eventData.email) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen İsim Giriniz"),
      lastname: Yup.string().required("Lütfen Soyisim Giriniz"),
      phoneNumber: Yup.string().required("Lütfen Telefon Numarası Giriniz"),
      email: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (isEdit) {
        const response = await axios.put(
          `/user/update/${eventData._id}`,
          values
        );
        if (response) {
          setIsEdit(false);
          setShow(false);
          dispatch(onGetUserList());
        }
      } else {
        const newUser = {
          name: values.name,
          lastname: values.lastname,
          phoneNumber: values.phoneNumber,
          email: values.email,
        };
        try {
          const response = await axios.post("/user/create-user", newUser);

          if (response) {
            toast.success("Kullanıcı başarıyla kaydedildi");
            dispatch(onGetUserList());
            setShow(false);
            resetForm();
          }
        } catch (error) {
          toast.error("Kullanıcı zaten var");
        }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleSort = (key: string) => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );

    const sortedUsers = [...user].sort((a, b) => {
      let comparison = 0;

      if (key === "balance") {
        console.log("akey", a["wallet"].balance);
        console.log("bkey", b["wallet"].balance);
        comparison = a["wallet"].balance - b["wallet"].balance;
      } else {
        comparison = a[key] > b[key] ? 1 : -1;
      }

      return sortDirection === "asc" ? comparison : -comparison;
    });

    setUser(sortedUsers);
  };

  const handleBalance = (title: any, balance: any, onPress: any) => {
    if (balance === undefined || isNaN(balance) || balance === 0) {
      return Swal.fire("Lütfen bir miktar giriniz", "", "warning");
    }
    Swal.fire({
      title,
      showDenyButton: true,
      confirmButtonText: "Kaydet",
      denyButtonText: `İptal`,
    }).then((result) => {
      if (result.isConfirmed) {
        onPress();
      } else if (result.isDenied) {
        Swal.fire("İşlem İptal Edildi", "", "info");
      }
    });
  };

  const handleAddMoney = (user: any) => {
    const newUserBalance = Number(user.balance);
    const selectedPaymentMethod = user.selectedPaymentMethod;

    if (!selectedPaymentMethod) {
      return Swal.fire("Lütfen bir ödeme yöntemi seçiniz", "", "warning");
    }

    handleBalance(
      `${newUserBalance} TL yüklenecek. Emin misiniz?`,
      newUserBalance,
      () => {
        addWalletTotMoneyMutation.mutate({
          owner: user._id,
          amount: newUserBalance,
        });
        createWalletTransactionMutation.mutate({
          id: user._id,
          balance: newUserBalance,
          type: "add",
          paymentMethod: selectedPaymentMethod,
        });
      }
    );
  };

  const handleDiscard = (user: any) => {
    const newUserBalance = Number(user.balance);

    if (user.wallet.balance < newUserBalance) {
      return Swal.fire("Yeterli bakiye yok !", "", "error");
    } else {
      handleBalance(
        `${newUserBalance} TL çıkarılacak. Emin misiniz?`,
        newUserBalance,
        () => {
          substractMoneyMutation.mutate({
            owner: user._id,
            amount: newUserBalance,
          });
          createWalletTransactionMutation.mutate({
            id: user._id,
            balance: newUserBalance,
            type: "subtract",
          });
        }
      );
    }
  };
  const [openRowIndex, setOpenRowIndex] = useState(null);

  const toggleDropdown = (index: any) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };
  const renderTransactionTable = () => {
    const transactions =
      activeTab === "add"
        ? userAddTransactions
        : activeTab === "spent"
          ? userSpentTransactions
          : userSubtractTransactions;
    return transactions.length === 0 ? (
      <p className="text-center text-gray-500">Bu tarih aralığında işlem yok</p>
    ) : activeTab === "spent" ? (
      <div className="container mx-auto mt-5">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left">Tarih</th>
              <th className="px-4 py-2 text-left">Tutar</th>
              <th className="px-4 py-2 text-left">Türü</th>
              <th className="px-4 py-2 text-left">İşlem</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <tr className="bg-white border-b hover:bg-gray-100">
                  <td className="px-4 py-2">
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td className="px-4 py-2">{item.tableTotal} TL</td>
                  <td className="px-4 py-2">Harcama</td>
                  <td className="px-4 py-2">
                    <button
                      className="text-blue-500 hover:underline"
                      onClick={() => toggleDropdown(index)}
                    >
                      Detaylar
                    </button>
                  </td>
                </tr>
                {openRowIndex === index && (
                  <tr className="bg-gray-50">
                    <td colSpan={4} className="px-4 py-2">
                      <div className="p-4 bg-white shadow-lg rounded-lg">
                        <h3 className="font-semibold text-lg mb-4 text-gray-700">
                          Satın Alınan Ürünler
                        </h3>
                        <table className="min-w-full table-auto bg-gray-50">
                          <thead className="bg-gray-200">
                            <tr>
                              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Ürün
                              </th>
                              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Adet
                              </th>
                              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Fiyat
                              </th>
                              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                                Toplam Fiyat
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.boughtItems.map((product: any) => (
                              <tr
                                key={product._id}
                                className="border-b border-gray-300"
                              >
                                <td className="px-4 py-2 text-gray-800">
                                  {product.productTitle}
                                </td>
                                <td className="px-4 py-2 text-gray-800">
                                  {product.quantity}
                                </td>
                                <td className="px-4 py-2 text-gray-800">
                                  {product.sellingPrice} TL
                                </td>
                                <td className="px-4 py-2 text-gray-800">
                                  {product.sellingPrice * product.quantity} TL
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="flex justify-center">
        <table className="min-w-full table-auto bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2">Tarih</th>
              <th className="px-4 py-2">Tutar</th>
              <th className="px-4 py-2">Türü</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction: any) => (
              <tr key={transaction._id} className="text-center border-t">
                <td className="px-4 py-2">
                  {moment(transaction.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td className="px-4 py-2">{transaction.amount} TL</td>
                <td className="px-4 py-2">
                  {transaction.type === "add" ? "Yükleme" : "Çıkarma"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <React.Fragment>
      <BreadCrumb title="Kullanıcılar" pageTitle="Kullanıcılar" />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />

      <div className="min-h-screen p-8 bg-[#f1f2f9] w-full">
        <div className="mb-8">
          <h1 className="text-2xl font-bold mb-4">Kullanıcı Listesi</h1>
          <input
            type="text"
            placeholder="Kullanıcı Ara..."
            value={search}
            onChange={(e) => {
              const searchValue = e.target.value.toLowerCase();
              setSearch(searchValue);

              if (searchValue === "") {
                setUser(userList);
              } else {
                setUser(
                  userList.filter((user: any) => {
                    const [firstNameInput, lastNameInput] =
                      searchValue.split(" ");

                    const firstNameMatches = user.name
                      .toLowerCase()
                      .includes(firstNameInput);
                    const lastNameMatches = lastNameInput
                      ? user.lastname.toLowerCase().includes(lastNameInput)
                      : true; // Handle case where there is no last name input

                    const justLastNameMatches = user.lastname
                      .toLowerCase()
                      .includes(searchValue);
                    const phoneMatches = user?.phoneNumber?.includes(
                      e.target.value
                    );
                    const emailMatches = user?.email?.includes(e.target.value);

                    return (
                      (firstNameMatches && lastNameMatches) ||
                      phoneMatches ||
                      emailMatches ||
                      justLastNameMatches ||
                      (user.name.toLowerCase().includes(lastNameInput) &&
                        user.lastname.toLowerCase().includes(firstNameInput))
                    );
                  })
                );
              }
            }}
            className="w-full h-12 rounded-lg border-2 border-gray-300 p-4"
          />
        </div>
        <div className="w-full h-auto items-center my-10  flex justify-end">
          <div
            className="btn btn-primary bg-blue-500 "
            onClick={() => {
              setShow(true);
            }}
          >
            <span className="text-white">Kullanıcı Oluştur</span>
          </div>
        </div>
        <div className="overflow-x-auto min-w-full">
          <Tooltip id="view" />
          <Tooltip id="edit" />
          <Tooltip id="delete" />
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Ad
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Soyad
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Email
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Telefon Numarası
                </th>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                  onClick={() => handleSort("createdAt")}
                >
                  Katılma Tarihi
                  {sortDirection === "asc" ? <SortDesc /> : <SortAsc />}
                </th>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-600 cursor-pointer"
                  onClick={() => handleSort("balance")}
                >
                  Bakiye
                  {sortDirection === "asc" ? <SortDesc /> : <SortAsc />}
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Bakiye İşlemi
                </th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  Aksiyon
                </th>
              </tr>
            </thead>
            <tbody>
              {user.map((user: any, index: number) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-3 text-sm capitalize">{user.name}</td>
                  <td className="px-4 py-3 text-sm capitalize">
                    {user.lastname}
                  </td>
                  <td className="px-4 py-3 text-sm">{user.email}</td>
                  <td className="px-4 py-3 text-sm whitespace-nowrap">
                    {user.phoneNumber}
                  </td>

                  <td className="px-4 py-3 text-sm">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-3 text-sm text-blue-500 cursor-pointer">
                    {user.wallet.balance} ₺
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <div className="flex items-center">
                      <input
                        type="number"
                        placeholder="Tutar Gir"
                        value={user.balance || ""}
                        onChange={(e) => {
                          const newBalance = Number(e.target.value);
                          setUser((prevUsers: any) =>
                            prevUsers.map((u: any) =>
                              u._id === user._id
                                ? { ...u, balance: newBalance }
                                : u
                            )
                          );
                        }}
                        className="w-24 h-10 rounded-lg border-2 border-gray-300 p-2 mr-2"
                      />
                      <style>
                        {` 
                  ::placeholder { 
                    color: crimson;
                    font-size: 14px;
                  }`}
                      </style>
                      <select
                        required
                        value={user.selectedPaymentMethod || ""}
                        onChange={(e) => {
                          const selectedMethod = e.target.value;
                          setUser((prevUsers: any) =>
                            prevUsers.map((u: any) =>
                              u._id === user._id
                                ? {
                                  ...u,
                                  selectedPaymentMethod: selectedMethod,
                                }
                                : u
                            )
                          );
                        }}
                        className="shadow appearance-none border rounded w-44 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="" disabled>
                          Ödeme Yöntemi
                        </option>
                        {paymentMethods.map((method: any, index: number) => (
                          <option key={index} value={method.paymentMethod}>
                            {method.paymentMethod}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => handleAddMoney(user)}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                      >
                        Yükle
                      </button>
                      <button
                        onClick={() => handleDiscard(user)}
                        className="bg-red-500 text-white px-4 py-2 rounded-lg"
                      >
                        Çıkar
                      </button>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <div className="flex space-x-2">
                      <button
                        data-tooltip-id="view"
                        data-tooltip-content={`Kullanıcı Detayı`}
                        className="flex items-center justify-center w-8 h-8 p-0 text-green-600 bg-green-100 rounded-full hover:bg-green-200"
                        onClick={() => handleClickUserDetail(user._id)}
                      >
                        <Eye className="w-4 h-4" />
                      </button>
                      <button
                        data-tooltip-id="edit"
                        data-tooltip-content={`Düzenle`}
                        className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200"
                        onClick={() => handleUpdateDataClick(user)}
                      >
                        <FileEdit className="w-4 h-4" />
                      </button>
                      <button
                        data-tooltip-id="delete"
                        data-tooltip-content={`Sil`}
                        className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
                        onClick={() => {
                          if (user.wallet.balance > 0) {
                            toast.error(
                              "Bu kullanıcıya ait işlemler mevcut olduğu için silinemez."
                            );
                          } else {
                            onClickDelete(user);
                          }
                        }}
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Kullanıcı Güncelle" : "Kullanıcı Oluştur"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                Ad
              </label>
              <input
                type="text"
                id="name"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter name"
                name="name"
                onChange={validation.handleChange}
                value={validation.values.name}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="lastname"
                className="inline-block mb-2 text-base font-medium"
              >
                Soyad
              </label>
              <input
                type="text"
                id="lastname"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Soyad"
                name="lastname"
                onChange={validation.handleChange}
                value={validation.values.lastname}
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="phoneNumber"
                className="inline-block mb-2 text-base font-medium"
              >
                Telefon Numarası
              </label>
              <InputMask
                mask="+90 (999) 999-9999"
                id="phoneNumber"
                name="phoneNumber"
                value={validation.values.phoneNumber}
                onChange={validation.handleChange}
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="email"
                className="inline-block mb-2 text-base font-medium"
              >
                Email
              </label>
              <input
                type="text"
                id="email"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Email"
                name="email"
                onChange={validation.handleChange}
                value={validation.values.email}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Kullanıcı Güncelle" : "Kullanıcı Ekle"}
              </button>
            </div>
            <style>
              {`
                            .phone-number {
  white-space: nowrap;  
  padding: 8px;        
  display: inline-block; 
}

.table-header, .table-cell {
  padding: 20px 20px;  
  text-align: center;    
}
                            `}
            </style>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[50rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">Kullanıcı Detayı</Modal.Title>
        </Modal.Header>
        <Modal.Body className="h-[35rem] p-3 overflow-y-auto">
          <>
            <div className="my-2 flex justify-center gap-5 w-full">
              <div className="w-1/3">
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  value={value}
                  onChange={handleValueChange}
                />
              </div>
              <button
                onClick={() => setActiveTab("add")}
                className={`px-4 py-2 rounded-lg ${activeTab === "add"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
                  }`}
              >
                Yüklenen Bakiyeler
              </button>
              <button
                onClick={() => setActiveTab("subtract")}
                className={`px-4 py-2 rounded-lg ${activeTab === "subtract"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
                  }`}
              >
                Çıkarılan Bakiye
              </button>
              <button
                onClick={() => setActiveTab("spent")}
                className={`px-4 py-2 rounded-lg ${activeTab === "spent"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
                  }`}
              >
                Harcamalar
              </button>
            </div>
            <hr className="my-6" />
            {renderTransactionTable()}
          </>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UserListView;
