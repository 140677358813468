import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


function App() {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer/>
      <RouteIndex />
    </QueryClientProvider>
  );
}

export default App;
