import { useMutation } from '@tanstack/react-query';
import { APIClient } from 'helpers/api_helper';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const api = new APIClient();

const ProductCount = () => {
    const navigate = useNavigate();
    const [counts, setCounts] = React.useState<any>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(20); 

    const getAllCountsMutation = useMutation(
        {
            mutationFn: () => api.get('/product-count/all', null),
            onSuccess: (data) => {
                console.log('data', data)
                setCounts(data)
            },
        }  
    ) 
    

    useEffect(() => {
        getAllCountsMutation.mutate();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = counts.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(counts.length / itemsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">Sayımlar</h1>
                <button
                    onClick={() => navigate('/make-product-count')}
                    className="bg-blue-500 hover:bg-blue-300 cursor-pointer text-white px-4 py-2 rounded"
                >
                    + Yeni Sayım Oluştur
                </button>
            </div>

            <input
                type="text"
                placeholder="Ara"
                className="mb-4 p-2 border rounded w-full"
            />

            <div className="mb-4">
                <label className="mr-2">Gösterilecek öğe sayısı:</label>
                <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    className="p-2 border rounded"
                >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>

            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="border px-4 py-2">Ad</th>
                        <th className="border px-4 py-2">Tarih</th>
                        <th className="border px-4 py-2">Sayım Tarihi</th>
                        <th className="border px-4 py-2">Depo</th>
                        <th className="border px-4 py-2">Durum</th>
                        <th className="border px-4 py-2">İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((item: any, index: any) => (
                        <tr key={index} className="hover:bg-gray-100">
                            <td className="border px-4 py-2">{item.name}</td>
                            <td className="border px-4 py-2">{item.date}</td>
                            <td className="border px-4 py-2">{item.countDate}</td>
                            <td className="border px-4 py-2">{item.warehouse}</td>
                            <td className="border px-4 py-2 text-center">
                                {item.seal ? (
                                    <span className="text-green-500">&#10003;</span>
                                ) : (
                                    <span className="text-red-500">&#10005;</span>
                                )}
                            </td>
                            <td className="flex items-center justify-center">
                                <button
                                    onClick={() => navigate(`/make-product-count/detail/${item._id}`)}
                                    className="bg-blue-500 hover:bg-blue-300 cursor-pointer text-white px-4 py-2 rounded"
                                >
                                    Detay
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`mx-1 px-4 py-2 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ProductCount;
