import React, { useCallback, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { useFormik as useFormic } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ReactCropperElement } from "react-cropper";
import CropperC from "../Components/CropImage/CropImage";
import { onDrop, onCropDone } from "../Components/CropImage/CropImageHelper";

import axios from "axios";

// Icon
import { Pencil, UploadCloud } from "lucide-react";

import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

const AddNewCategory = () => {
  const [selectfiles, setSelectfiles] = useState<any>([]);
  const [selectedColor, setSelectedColor] = useState<string>("#ffffff");
  const [open, setOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
    console.log("Seçilen Renk Kodu:", event.target.value);
  };
  const location = useLocation();

  const { id } = location.state || {};

  console.log("id", id);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const validation = useFormic({
    initialValues: {
      categoryName: "",
      categoryColor: "",
      showOnMenu: false,
    },

    onSubmit: async (values) => {
      if (!id) {
        const formData = new FormData();

        if (selectfiles.length === 0) {
          console.log("selam");
          toast("Lütfen bir resim ekleyin. Kategori resimsiz eklenemez.", {
            type: "error",
            theme: "colored",
            position: "top-right",
          });
        }

        selectfiles.forEach((file: any) => {
          formData.append("files", file);
        });

        // Append other form fields
        Object.keys(values).forEach((key) => {
          formData.append(key, (values as any)[key]);
        });
        try {
          const response = await axios.post(
            "/categories/create",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("Success:", response.data);

          navigate("/categories-list");
        } catch (error) {
          console.error("Error posting the data", error);
        }
      } else {
        const formData = new FormData();
        selectfiles.forEach((file: any) => {
          formData.append("files", file);
        });
        console.log("values", values);
        Object.keys(values).forEach((key) => {
          if (
            key !== "_id" &&
            key !== "createdAt" &&
            key !== "updatedAt" &&
            key !== "__v"
          ) {
            formData.append(key, (values as any)[key]);
          }
        });

        try {
          const response = await axios.put(`/menu/update/${id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          console.log("Success:", response);
          navigate("/categories-list");
        } catch (error) {
          console.error("Error updating the data", error);
        }
      }
    },
  });

  const handleAcceptfiles = (files: any) => {
    files.map((file: any) => Object.assign(file, {
      priview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }));
    setSelectfiles((prev: any) => [...prev, ...files]);
  };

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const wrapperSetCroppedImage = useCallback((image: string) => {
    setCroppedImage(image);
  }, [setCroppedImage]);

  const wrapperSetOpen = useCallback((open: boolean) => {
    setOpen(open);
  }, [setOpen]);

  return (
    <React.Fragment>
      <BreadCrumb title="Add New" pageTitle="Products" />
      <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
        <div className="col-span-12">
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">Kategori Ekle</h6>
              <form
                onSubmit={(event: any) => {
                  event.preventDefault();
                  validation.handleSubmit();
                }}
                className="grid grid-cols-12 gap-5 sm:grid-col-4 lg:grid-cols-12 xl:grid-cols-12"
              >
                <div className="col-span-12">
                  <label
                    htmlFor="categoryName"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Kategori İsmi
                  </label>
                  <input
                    type="text"
                    id="categoryName"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Kategori İsmi"
                    required
                    onChange={validation.handleChange}
                    value={validation.values.categoryName}
                  />
                </div>

                <div className="col-span-12">
                  <label
                    htmlFor="categoryColor"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Kategori Rengi
                  </label>
                  <div className="flex items-center">
                    <input
                      type="color"
                      id="categoryColor"
                      className="h-10 w-10 rounded-full border border-gray-300 mr-2"
                      value={validation.values.categoryColor}
                      onChange={(event) => {
                        validation.handleChange(event);
                        handleColorChange(event);
                      }}
                    />

                    <p className="text-gray-700">
                      Seçilen Renk: {selectedColor}
                    </p>
                  </div>
                </div>

                <div className="col-span-12">
                  <label
                    htmlFor="genderSelect"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Kategori Resmi
                  </label>

                  <Dropzone
                    onDrop={
                      (files: any) => {
                        onDrop({ files, wrapperSetCroppedImage, wrapperSetOpen });
                      }}
                    multiple={false}>
                    {({ getRootProps, getInputProps }: any) => (
                      <div
                        className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dark:bg-zink-700 dark:border-zink-500 dropzone2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="w-full py-5 text-lg text-center dz-message needsclick">
                          <div className="mb-3">
                            <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                          </div>
                          <h5 className="mb-0 font-normal text-slate-500 dark:text-zink-200 text-15">
                            Resim eklemek için tıklayın veya sürükleyip bırakın
                          </h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <CropperC
                    image={croppedImage}
                    open={open}
                    setOpen={setOpen}
                    onCropDone={() => onCropDone({ cropperRef, handleAcceptfiles, wrapperSetOpen })}
                    cropperRef={cropperRef}
                  />
                  <ul
                    className="flex flex-wrap mb-0 gap-x-5"
                    id="dropzone-preview2"
                  >
                    {(selectfiles || []).map((file: any, index: number) => (
                      <li
                        className="mt-5"
                        id="dropzone-preview-list2"
                        key={index}
                      >
                        <div className="border rounded border-slate-200 dark:border-zink-500">
                          <div className="p-2 text-center">
                            <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                              <img
                                className="block w-full h-full rounded-md"
                                src={file.priview}
                                alt={file.name}
                              />
                            </div>
                            <div className="pt-3">
                              <h5 className="mb-1 text-15" data-dz-name>
                                {file.name}
                              </h5>
                              <p
                                className="mb-0 text-slate-500 dark:text-zink-200"
                                data-dz-size
                              >
                                {file.formattedSize}
                              </p>
                              <strong
                                className="error text-danger"
                                data-dz-errormessage
                              ></strong>
                            </div>
                            <div className="mt-2">
                              <button
                                data-dz-remove
                                className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                onClick={() => {
                                  const newImages = [...selectfiles];
                                  newImages.splice(index, 1);
                                  setSelectfiles(newImages);
                                }}
                              >
                                Sil
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-span-12  flex items-center">
                  <input
                    type="checkbox"
                    id="showOnMenu"
                    className="mr-2 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Menüde Gözüksün"
                    checked={validation.values.showOnMenu}
                    onChange={validation.handleChange}
                  />
                  <label
                    htmlFor="showOnMenu"
                    className="inline-block text-base font-medium"
                  >
                    Menüde Gözüksün
                  </label>
                </div>

                <div className="col-span-12 flex justify-end gap-2 mt-4">
                  <button
                    type="reset"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                  >
                    Sıfırla
                  </button>
                  <button
                    type="submit"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    {id ? "Kategori Güncelle" : "Kategori Ekle"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewCategory;
