import React, { useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import { MoreHorizontal, Eye, FileEdit, Trash2, Search, Plus } from 'lucide-react';
import TableContainer from "Common/TableContainer";
import DeleteModal from "Common/DeleteModal";
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getProductList as onGetProductList, deleteProductList as onDeleteProductList } from 'slices/thunk';
import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import { APIClient, imageUrl } from "helpers/api_helper";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Swal from "sweetalert2";
import { Tooltip } from 'react-tooltip'

import Copy from 'assets/images/copy-green.png'

const api = new APIClient();

const Menu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Ecommerce,
        (state) => ({
            dataList: state.productList
        })
    );

    const { dataList } = useSelector(selectDataList);

    const [data, setData] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    // Get Data
    useEffect(() => {
        dispatch(onGetProductList());
    }, [dispatch]);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    const deleteProductMutation = useMutation({
        mutationFn: (id) => api.delete(`/menu/delete/${id}`, null),
        onSuccess: () => {
            dispatch(onGetProductList());
        },
        onError: (error: any) => {
            console.log('error', error);
        }
    });

    // Delete Data
    const onClickDelete = (cell: any) => {
        if (cell._id) {
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Bu işlemi geri alamazsınız!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, sil!',
                cancelButtonText: 'İptal'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteProductMutation.mutate(cell._id);
                }
            });
        }
    };

    const handleDelete = () => {
        if (eventData) {
            dispatch(onDeleteProductList(eventData._id));
            setDeleteModal(false);
            navigate(0);
        }
    };

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['productTitle', 'productDescription', 'category', 'barcodeNumber'];
        filterDataBySearch(dataList, search, keysToSearch, setData);
    };

    // Duplicate Product
    const duplicateProduct = async (product: any) => {
        try {
            // const response = await axios.get('/menu/barcode');
            // const newBarcode = response.toString();
            const formData = new FormData();

            Object.keys(product).forEach((key) => {
                if (!['_id', 'createdAt', 'updatedAt', '__v', 'barcodeNumber', 'quantity'].includes(key)) {
                    formData.append(key, product[key]);
                }
            });

            const jsonData = {} as any

            Object.keys(product).forEach((key) => {
                //create json data for the product
                if (!['_id', 'createdAt', 'updatedAt', '__v', 'barcodeNumber', 'quantity'].includes(key)) {
                    jsonData[key] = product[key];
                }
            })

            // formData.append('barcodeNumber', newBarcode);

            const a = await axios.post(`/menu/duplicate`, {
                ...jsonData,
                productImage: product.productImage,
                // barcodeNumber: newBarcode,
            });
            console.log(a);

            dispatch(onGetProductList());
        } catch (error) {
            console.error('Error duplicating the product', error);
        }
    };

    const columns = useMemo(() => [
        {
            header: "Barkod Numarası",
            accessorKey: "barcodeNumber",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <Link to="#" className="transition-all duration-150 ease-linear product_code text-custom-500 hover:text-custom-600">{cell.getValue()}</Link>
            ),
        },
        {
            header: "Ürün Adı",
            accessorKey: "productTitle",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Link to="#" className="flex items-center gap-2">
                    <img src={imageUrl + `/upload-file/product/${cell.row.original.productImage}`} alt="Product images" className="h-6" />
                    <h6 className="product_name">{
                        cell.getValue().length > 15
                            ? cell.getValue().substring(0, 15) + '...'
                            : cell.getValue()
                    }</h6>
                </Link>
            ),
        },
        {
            header: "Kategori",
            accessorKey: "category",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <span className="category px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{cell.getValue()}</span>
            ),
        },
        {
            header: "Stok",
            accessorKey: "stock",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <span className="category px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{cell.getValue()}</span>
            ),
        },
        {
            header: "Alış KDV",
            accessorKey: "buyingKDV",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <span className="category px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{cell.getValue()} %</span>
            ),
        },
        {
            header: "Satış KDV",
            accessorKey: "sellingKDV",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <span className="category px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{cell.getValue()} %</span>
            ),
        },
        {
            header: "Fiyat",
            accessorKey: "sellingPrice",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <span className="price">₺{cell.getValue()}</span>
            )
        },

        {
            header: "Açıklama",
            accessorKey: "productDescription",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => {
                return (
                    <h6 className="product_name">{
                        cell.getValue().length > 20
                            ? cell.getValue().substring(0, 20) + '...'
                            : cell.getValue()
                    }</h6>
                )
            }
        },
        {
            header: "Aksiyon",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className="flex space-x-2">
                    <Tooltip id="edit-tooltip" place="top" />
                    <Tooltip id="delete-tooltip" place="top" />
                    <Tooltip id="duplicate-tooltip" place="top" />

                    <button
                        data-tooltip-id="edit-tooltip"
                        data-tooltip-content={"Düzenle"}
                        className="flex items-center justify-center w-8 h-8 p-0 text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200 edit-tooltip"
                        onClick={() => navigate(`/add-new-product?${cell.row.original._id}`, {
                            state: {
                                from: "menu",
                                id: cell.row.original._id,
                            }
                        })}
                    >
                        <FileEdit className="w-4 h-4" />
                    </button>

                    <button
                        data-tooltip-id="delete-tooltip"
                        data-tooltip-content={"Sil"}
                        className="flex items-center justify-center w-8 h-8 p-0 text-red-600 bg-red-100 rounded-full hover:bg-red-200"
                        onClick={() => {
                            const data = cell.row.original;
                            onClickDelete(data);
                        }}
                    >
                        <Trash2 className="w-4 h-4" />
                    </button>
                    <button
                        data-tooltip-id="duplicate-tooltip"
                        data-tooltip-content={"Kopyala"}
                        className="flex items-center justify-center w-8 h-8 p-0 text-green-600 bg-green-100 rounded-full hover:bg-green-200"
                        onClick={() => {
                            const data = cell.row.original;
                            duplicateProduct(data);
                        }}
                    >
                        <img
                            alt="copy"
                            src={Copy}
                            className="w-4 h-4 object-fit"
                        />
                    </button>
                </div>
            ),
        }
    ], [navigate, onClickDelete]);

    return (
        <React.Fragment>
            <BreadCrumb title='Menü Ürünleri' pageTitle='Menü Ürünleri' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="card" id="productListTable">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-12">
                        <div className="xl:col-span-3">
                            <div className="relative">
                                <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                            </div>
                        </div>

                        <div className="lg:col-span-2 ltr:lg:text-right rtl:lg:text-left xl:col-span-2 xl:col-start-11">
                            <Link to="/add-new-product" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><Plus className="inline-block size-4" /> <span className="align-middle">Ürün Ekle</span></Link>
                        </div>
                    </div>
                </div>
                <div className="!pt-1 card-body">
                    {data && data.length > 0 ?
                        <TableContainer
                            isPagination={true}
                            columns={(columns || [])}
                            data={(data || [])}
                            customPageSize={7}
                            divclassName="overflow-x-auto"
                            tableclassName="w-full whitespace-nowrap"
                            theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                            thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                            PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
                        />
                        :
                        (<div className="noresult">
                            <div className="py-6 text-center">
                                <Search className="size-6 mx-auto mb-3 text-sky-500 fill-sky-100 dark:fill-sky-500/20" />
                                <h5 className="mt-2 mb-1">Sonuç Bulunamadı</h5>
                            </div>
                        </div>)}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Menu;
