// dashboard
import Ecommerce from 'pages/Dashboards/Ecommerce';

import UserProfile from 'pages/Authentication/UserProfile';
import Login from 'pages/Authentication/Login';
import Logout from 'pages/Authentication/LogOut';
import Register from 'pages/Authentication/Register';
import Menu from 'pages/Menu';
import AddNew from 'pages/Menu/AddProduct';
import UserListView from 'pages/Users';
import Orders from 'pages/Orders';
import GiftProduct from 'pages/GiftProduct';
import CategoryListView from 'pages/Categories';
import AddNewCategory from 'pages/Categories/AddNewCategory';
import AddNewKdv from 'pages/Kdv/AddNewKdv';
import KDVListView from 'pages/Kdv';
import PaymentMethodsListView from 'pages/PaymentMethods';
import AddPaymentMethod from 'pages/PaymentMethods/AddPaymentMethod';
import MarketsListView from 'pages/Markets';
import AddMarket from 'pages/Markets/AddMarket';
import TerminalHome from 'pages/Terminal';
import TerminalTableEdit from 'pages/Terminal/components/TerminalTableEdit';
import BannerManage from 'pages/Banner';
import AppColor from 'pages/AppColor';
import UserControl from 'pages/UserControl';
import AppLogo from 'pages/AppLogo';
import SendNotification from 'pages/Notifications';
import UserControlDetail from 'pages/UserControl/UserControlDetail';
import ProductCount from 'pages/ProductCount';
import MakeProductCount from 'pages/ProductCount/MakeProductCount';
import MakeProductCountDetail from 'pages/ProductCount/MakeProductCountDetail';
import CreateGroupNotifications from 'pages/Notifications/CreateGroupNotifications';
import GroupNotificationsList from 'pages/Notifications/GroupNotificationsList';
import AccountDeletionPolicy from 'pages/Users/AccountDeletionPolicy';
import ReceiptsList from 'pages/Receipts';
import XRaports from 'pages/XRaports';
import CashRegister from 'pages/CashRegister';
import ChangePassword from 'pages/ChangePassword';
import CashRegisterList from 'pages/CashRegister/CashRegisterList';
import EditProfile from 'pages/EditProfile';
import ForgotPassword from 'pages/ForgotPassword';
import Contact from 'pages/Contact';
import AppVersionCode from 'pages/AppVersionCode';

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
  isTerminal?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: '/', component: Ecommerce },
  { path: '/dashboard', component: Ecommerce },
  { path: '/menu', component: Menu },
  { path: '/add-new-product', component: AddNew },
  { path: '/users', component: UserListView },
  { path: '/orders', component: Orders },
  { path: '/gift-product', component: GiftProduct },
  { path: '/gift-product', component: GiftProduct },
  { path: '/categories-list', component: CategoryListView },
  { path: '/add-new-category', component: AddNewCategory },
  { path: '/add-new-kdv', component: AddNewKdv },
  { path: '/kdv-list', component: KDVListView },
  { path: '/payment-method-list', component: PaymentMethodsListView },
  { path: '/add-payment-method', component: AddPaymentMethod },
  { path: '/markets-list', component: MarketsListView },
  { path: '/add-market', component: AddMarket },
  { path: '/terminal', component: TerminalHome, isTerminal: true },
  { path: '/terminal-table-edit/:id', component: TerminalTableEdit, isTerminal: true },
  { path: '/banner-manage', component: BannerManage },
  { path: '/app-color', component: AppColor },
  { path: '/user-control', component: UserControl },
  { path: '/app-logo', component: AppLogo },
  { path: '/send-notification', component: SendNotification },
  { path: '/user-control/detail/:id', component: UserControlDetail },
  { path: '/product-count', component: ProductCount },
  { path: '/make-product-count', component: MakeProductCount },
  { path: '/make-product-count/detail/:id', component: MakeProductCountDetail },
  { path: '/create-group-notification', component: CreateGroupNotifications },
  { path: '/group-notification-list', component: GroupNotificationsList },
  { path: '/receipts', component: ReceiptsList },
  { path: '/xraports', component: XRaports },
  { path: '/cash-register', component: CashRegister },
  { path: '/cash-register-list', component: CashRegisterList },

  // profile
  { path: '/user-profile', component: UserProfile },
  { path: '/change-password', component: ChangePassword },
  { path: '/update-profile', component: EditProfile },
  { path: '/app-version-code', component: AppVersionCode },


];

const publicRoutes = [
  // authentication
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/forgot-password/:token', component: ForgotPassword },
  { path: '/user-account-deletion-policy', component: AccountDeletionPolicy },
  { path: '/iletisim', component: Contact }
];

export { authProtectedRoutes, publicRoutes };
