import { useMutation } from "@tanstack/react-query";
import BreadCrumb from "Common/BreadCrumb";
import { APIClient } from "helpers/api_helper";
import ProductCard, {
  ProductCardProps,
} from "pages/Components/XRaportsComponents/ProductCard";
import React, { useEffect, useState } from "react";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

const api = new APIClient();

function mergeAndSum(obj1: any, obj2: any): any {
  const merged: any = {};

  for (const [key, value] of Object.entries(obj1)) {
    if (merged.hasOwnProperty(key)) {
      merged[key] += value;
    } else {
      merged[key] = value;
    }
  }

  for (const [key, value] of Object.entries(obj2)) {
    if (merged.hasOwnProperty(key)) {
      merged[key] += value;
    } else {
      merged[key] = value;
    }
  }

  return merged;
}

const sortByQuantity = (reports: ProductCardProps[]) => {
  return [...reports].sort((a, b) => b.quantity - a.quantity);
};

const sortByUnitProfit = (reports: ProductCardProps[]) => {
  return [...reports].sort((a, b) => b.unitProfit - a.unitProfit);
};

const sortByTotalProfit = (reports: ProductCardProps[]) => {
  return [...reports].sort((a, b) => b.totalProfit - a.totalProfit);
};

const XRaports = () => {
  const [dailyStatistics, setDailyStatistics] = useState<any>({});
  const [dailyReport, setDailyReport] = useState<ProductCardProps[]>([]);
  const [sortedReport, setSortedReport] = useState<ProductCardProps[]>([]);
  const [sortCriteria, setSortCriteria] = useState("");
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [userDailyStatistics, setUserDailyStatistics] = useState<any>({});
  const [mergedObj, setMergedObj] = useState<any>({});
  
  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleSortChange = (criteria: string) => {
    setSortCriteria(criteria);
    let sortedList = [];
    switch (criteria) {
      case "quantity":
        sortedList = sortByQuantity(dailyReport);
        break;
      case "unitProfit":
        sortedList = sortByUnitProfit(dailyReport);
        break;
      case "totalProfit":
        sortedList = sortByTotalProfit(dailyReport);
        break;
      default:
        sortedList = dailyReport;
    }
    setSortedReport(sortedList);
  };

  const getDailyStatisticsMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet/daily-statistics?startDate=${
          typeof value?.startDate === "string"
            ? value?.startDate
            : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${
          typeof value?.endDate === "string"
            ? value?.endDate
            : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      console.log(data);
      setDailyStatistics(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getDailySalesReportMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet/daily-sales-report?startDate=${
          typeof value?.startDate === "string"
            ? value?.startDate
            : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${
          typeof value?.endDate === "string"
            ? value?.endDate
            : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data: any) => {
      console.log(data);
      setDailyReport(data);
      setSortedReport(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getUserDailyStatisticsMutation = useMutation({
    mutationFn: () =>
      api.get(
        `/wallet-transactions/type-add-and-payment-method?startDate=${
          typeof value?.startDate === "string"
            ? value?.startDate
            : value?.startDate?.toISOString().slice(0, 10)
        }&endDate=${
          typeof value?.endDate === "string"
            ? value?.endDate
            : value?.endDate?.toISOString().slice(0, 10)
        }`,
        null
      ),
    onSuccess: (data) => {
      console.log(data);
      setUserDailyStatistics(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getDailyStatisticsMutation.mutate();
    getDailySalesReportMutation.mutate();
    getUserDailyStatisticsMutation.mutate();
    setSortCriteria("");
  }, [value?.startDate, value?.endDate]);

  useEffect(() => {
    const paymentMethodList = dailyStatistics?.daily?.paymentMethodTotals || {};
    const result = mergeAndSum(paymentMethodList, userDailyStatistics);
    setMergedObj(result);
    console.log("Merged Object", mergedObj);
  }, [userDailyStatistics, dailyStatistics]);

  const totalAmount = Object.values(userDailyStatistics).reduce(
    (acc: number, amount: any) => acc + (amount as number),
    0
  );
  const totalAmount2 = Object.values(mergedObj).reduce(
    (acc: number, amount: any) => acc + (amount as number),
    0
  );
  return (
    <React.Fragment>
      <BreadCrumb title="X-Raporu" pageTitle="XRaports" />
      <div className="my-5 flex w-1/3">
        <Datepicker displayFormat={"DD/MM/YYYY"} value={value} onChange={handleValueChange} />
      </div>
      
      <div className="card overflow-auto">
        <div className="card-body">
          <h6 className="mb-6 text-3xl">Satış Raporu</h6>
          <ul className="max-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Toplam Satış
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalSales?.toFixed(2)} TL
                </div>
              </div>
            </li>
            {Object.entries(dailyStatistics?.daily?.paymentMethodTotals || {}).map(([method, amount], index) => (
              <li key={method} className="py-2">
                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                  <div className="flex-1 min-w-0">
                    <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                      {method}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    {Number(amount).toFixed(2)} TL
                  </div>
                </div>
              </li>
            ))}
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    İndirim
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalDiscounts?.toFixed(2)} TL
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Fiş Sayısı
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalReceipts}
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Fiş Ortalaması
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalReceipts > 0
                    ? (
                        dailyStatistics.daily.totalSales /
                        dailyStatistics.daily.totalReceipts
                      ).toFixed(2) + " TL"
                    : "0 TL"}
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Net Kâr (Kdv düşürüldü)
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily
                    ? `${(
                        dailyStatistics.daily.totalSales -
                        dailyStatistics.daily.totalSalesVat
                      ).toFixed(2)} TL`
                    : "0 TL"}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="card overflow-auto">
        <div className="card-body">
          <h6 className="mb-6 text-3xl">Kullanıcı Raporu</h6>
          <ul className="max-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Kullanıcılara Toplam Yüklenen Bakiye
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {totalAmount.toFixed(2)} TL
                </div>
              </div>
            </li>
            {Object.keys(userDailyStatistics).length > 0 && (
              <li className="py-2">
                <div className="flex flex-col space-y-2">
                  {Object.entries(userDailyStatistics).map(
                    ([method, amount], index) => (
                      <div
                        key={method}
                        className={`flex items-center space-x-4 rtl:space-x-reverse ${
                          index < Object.entries(userDailyStatistics).length - 1
                            ? "border-b border-gray-200 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        <div className="flex-1 min-w-0">
                          <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                            <b>{method}</b> ile yüklenilen bakiye
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                          {Number(amount).toFixed(2)} TL
                        </div>
                      </div>
                    )
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="card overflow-auto">
        <div className="card-body">
          <h6 className="mb-6 text-3xl">Genel Rapor</h6>
          <ul className="max-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Toplam Değer
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {totalAmount2.toFixed(2)} TL
                </div>
              </div>
            </li>
            {Object.keys(mergedObj).length > 0 && (
              <li className="py-2">
                <div className="flex flex-col space-y-2">
                  {Object.entries(mergedObj).map(
                    ([method, amount], index) => (
                      <div
                        key={method}
                        className={`flex items-center space-x-4 rtl:space-x-reverse ${
                          index < Object.entries(mergedObj).length - 1
                            ? "border-b border-gray-200 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        <div className="flex-1 min-w-0">
                          <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                            Toplam <b>{method}</b> değeri
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                          {Number(amount).toFixed(2)} TL
                        </div>
                      </div>
                    )
                  )}
                </div>
              </li>
            )}
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    İndirim
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalDiscounts?.toFixed(2)} TL
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Fiş Sayısı
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalReceipts}
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Fiş Ortalaması
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily?.totalReceipts > 0
                    ? (
                        dailyStatistics.daily.totalSales /
                        dailyStatistics.daily.totalReceipts
                      ).toFixed(2) + " TL"
                    : "0 TL"}
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-1 min-w-0">
                  <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                    Net Kâr (Kdv düşürüldü)
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  {dailyStatistics?.daily
                    ? `${(
                        dailyStatistics.daily.totalSales -
                        dailyStatistics.daily.totalSalesVat
                      ).toFixed(2)} TL`
                    : "0 TL"}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="my-5 flex justify-end">
        <select
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
          value={sortCriteria}
          onChange={(e) => handleSortChange(e.target.value)}
        >
          <option value="">Sırala</option>
          <option value="quantity">En Çok Satan</option>
          <option value="unitProfit">En Çok Birim Kar Getiren</option>
          <option value="totalProfit">En Çok Toplam Kar Getiren</option>
        </select>
      </div>
      <div className="bg-white rounded-lg w-full p-4">
        <h6 className="mb-6 text-3xl text-center mb-5">Ürün Satış Raporu</h6>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {sortedReport.map((reportItem, index) => (
            <ProductCard key={index} {...reportItem} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default XRaports;
