const base64ToFile = (base64: any, filename: any) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};


export const onDrop = ({ files, wrapperSetCroppedImage, wrapperSetOpen }: any) => {
    wrapperSetOpen(true);
    if (files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = () => {
            wrapperSetCroppedImage(reader.result as string);
        };
    }
}

export const onCropDone = ({ cropperRef, handleAcceptfiles, wrapperSetOpen }: any) => {
    const cropper = cropperRef.current?.cropper;
    const rand = Math.floor(Math.random() * 1000);

    const croppedCanvas = cropper?.getCroppedCanvas();
    if (croppedCanvas) {
        const dataURL = croppedCanvas.toDataURL();
        const file = base64ToFile(dataURL, `cropped${rand}.png`);
        handleAcceptfiles([file]);
    }
    wrapperSetOpen(false)
}
